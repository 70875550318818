.react-dropdown-tree-select
  .dropdown
    .dropdown-content
      overflow-y: auto
      min-width: 200px
      max-width: 500px
      max-height: 500px
      margin-top: -4px
      border-radius: 30px
      padding: 1rem 2rem
      z-index: 100

      .infinite-scroll-component
        overflow-x: hidden !important

      .node.tree.disabled
        .toggle
          cursor: pointer
          font-size: 20px
          width: 100%
          display: inline-block

        label
          margin-left: calc(-100% + 10px)
          z-index: -1
          position: relative

          input
            display: none

        .node-label
          cursor: pointer
          color: black
          white-space: normal

      .node.leaf.disabled
        input
          display: none

    .dropdown-trigger
      border-radius: 30px
      background-color: white
      border: 1px solid #D3D3D3
      box-sizing: border-box
      padding: 0 1rem 0 0

      .tag-item
        margin: 4px

        span
          background: linear-gradient(180deg, rgba(33, 130, 185, 0.5) 0%, rgba(255, 255, 255, 0) 100%), rgba(1, 112, 175, 0.8)
          padding: 5px 0 5px 24px
          border: none
          border-radius: 30px
          font-size: 15px
          line-height: 24px
          text-transform: uppercase
          color: #FFFFFF
          text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2)
          display: flex
          align-items: center

          button
            color: #FFFFFF
            padding-left: 10px

            &:hover
              color: blue

      .search
        width: 265px
        border-bottom: none
        font-size: 15px
        line-height: 24px
        color: #727272
        margin-left: 1rem

      &.arrow.bottom::after
        content: url("../../images/ic-dropdown.png")

      &.arrow.top::after
        content: url("../../images/ic-dropdown.png")
        transform: rotate(180deg)
        display: inline-block

.rearrange-content
  .dropdown
    .dropdown-content
      overflow-y: auto
      min-width: 200px
      max-width: 500px
      max-height: 200px
      margin-top: -4px
      border-radius: 30px
      padding: 1rem 2rem
      z-index: 100

@media screen and (max-width: 30rem)
  .react-dropdown-tree-select
    .search
      width: 13.5rem !important
