p
  font-weight: bold
  color: black

.component-container
  display: flex
  justify-content: center
  text-align: center
  font-weight: bold

.battery
  display: inline-block
  margin: 0 10px

.battery-low
  background-image: url(../../images/battery-progress-red.svg)
  width: 230px
  height: 370px
  background-size: 250px
  background-repeat: no-repeat
  align-self: center

.battery-medium
  background-image: url(../../images/battery-progress-yellow.svg)
  width: 230px
  height: 370px
  background-size: 250px
  background-repeat: no-repeat
  align-self: center

.battery-high
  background-image: url(../../images/battery-progress-green.svg)
  width: 230px
  height: 370px
  background-size: 250px
  background-repeat: no-repeat
  align-self: center


