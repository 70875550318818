._logotype_qalan
  background-image: url("../../images/landing/qalan_logo.svg")
  width: 100%
  height: 52px
  background-size: contain
  background-repeat: no-repeat
  background-position: center

._logotype_qalan_uzb
  background-image: url("../../images/landing/qalan_logo_uzb.svg")
  width: 100%
  height: 52px
  background-size: contain
  background-repeat: no-repeat
  background-position: center

.binom
  background-image: url("../../images/landing/binom-logo.jpg")
  width: 100px
  height: 100px
  background-size: contain
  background-repeat: no-repeat
  background-position: center

.zaman
  background-image: url("../../images/landing/zaman-logo.jpg")
  width: 100px
  height: 100px
  background-size: contain
  background-repeat: no-repeat
  background-position: center

.phystech
  background-image: url("../../images/landing/phystech-logo.png")
  width: 100px
  height: 100px
  background-size: contain
  background-repeat: no-repeat
  background-position: center

.orken
  background-image: url("../../images/landing/orken-logo.png")
  width: 100px
  height: 100px
  background-size: contain
  background-repeat: no-repeat
  background-position: center

.zhaniya
  background-image: url("../../images/landing/zhaniya-logo.png")
  width: 100px
  height: 100px
  background-size: contain
  background-repeat: no-repeat
  background-position: center

.shoqan
  background-image: url("../../images/landing/shoqan-logo.png")
  width: 100px
  height: 100px
  background-size: contain
  background-repeat: no-repeat
  background-position: center

.urker
  background-image: url("../../images/landing/urker-logo.png")
  width: 100px
  height: 100px
  background-size: contain
  background-repeat: no-repeat
  background-position: center

.senim
  background-image: url("../../images/landing/senim-logo.png")
  width: 100px
  height: 100px
  background-size: contain
  background-repeat: no-repeat
  background-position: center

.jos
  background-image: url("../../images/landing/jos-logo.png")
  width: 100px
  height: 100px
  background-size: contain
  background-repeat: no-repeat
  background-position: center

.alem
  background-image: url("../../images/landing/alem-logo.png")
  width: 100px
  height: 100px
  background-size: contain
  background-repeat: no-repeat
  background-position: center

.ubes
  background-image: url("../../images/landing/ubes-logo.png")
  width: 100px
  height: 100px
  background-size: contain
  background-repeat: no-repeat
  background-position: center

._icon
  margin: 10px
  width: 60px
  height: 60px

._calendar-icon
  background-image: url("../../images/landing/calendar-img.png")
  background-size: 100% 100%
  width: 60px
  height: 60px

._ring-icon
  background-image: url("../../images/landing/ring-img.png")
  background-size: 100% 100%
  width: 60px
  height: 60px

._like-icon
  background-image: url("../../images/landing/like-img.png")
  background-size: 100% 100%
  width: 60px
  height: 60px

._question-icon
  background-image: url("../../images/landing/question-img.png")
  background-size: 100% 100%
  width: 60px
  height: 60px

._success-icon
  background-image: url("../../images/landing/success-icon.png")
  background-size: 100% 100%
  width: 36px
  height: 36px

._our-guys
  background-image: url("../../images/landing/man-hug-pupil.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  width: 764px
  height: 498px
  position: absolute
  bottom: 0
  right: 0

._for-school-reviews
  background-image: url("../../images/landing/pupil-review.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  width: 740px
  height: 439px

._error-icon
  background-image: url("../../images/landing/error-icon.svg")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  width: 24px
  height: 24px

.wrapper
  position: relative
  min-height: 100%
  overflow: hidden

._container
  max-width: 1600px
  margin-left: auto
  margin-right: auto
  padding-left: 20px
  padding-right: 20px
  box-sizing: content-box

._header-container
  display: flex
  justify-content: space-between
  min-height: 40px
  align-items: center
  padding: 20px 20px

._header-logo
  width: 165px

._menu-list
  display: flex

.menu_item:not(:last-child)
  margin-right: 40px

.menu_link
  font-family: "Montserrat", sans-serif
  font-weight: 400
  font-size: 20px
  color: #FFFFFF

.menu_link:hover
  color: #FFE500

.sign_button
  border: none
  outline: none
  box-shadow: none
  font-size: 16px
  font-weight: 700
  font-family: "Montserrat", sans-serif
  color: #0075FF
  background-color: #FFFFFF
  padding: 16px 40px
  border-radius: 20px
  margin-left: 12px

.sign_button:hover
  cursor: pointer
  color: #0075FF
  background-color: #E1EFFF

.sign_button:active
  color: #0075FF
  background-color: #BFD9F8

._main
  background: #0075FF

._main-container
  margin-top: 25px
  display: flex
  justify-content: space-between
  align-items: center

._main-pupil
  background-image: url("../../images/landing/main-pupil.png")
  width: 731px
  height: 767px
  background-size: contain
  background-repeat: no-repeat
  background-position: center

._main-iframe
  width: 710px
  height: 400px
  border-radius: 40px

._main_title
  max-width: 830px

._main_title-h3
  font-weight: 700
  font-size: 68px
  color: #FFFFFF
  margin-bottom: 36px
  line-height: 84px

.math-tr
  color: #FFE500

._main_title-p
  margin: 0
  font-weight: 600
  font-size: 28px
  line-height: 34px
  color: #FFFFFF

._main_title-buttons
  margin-top: 36px

.get-advice
  color: #2B2C34
  font-size: 24px
  font-weight: 600
  border-radius: 20px
  background-color: #FFD500
  padding: 25.5px 74px
  margin-right: 20px
  text-align: center

.get-advice:hover
  color: #2B2C34
  background-color: #FFE666

.get-advice:active
  color: #2B2C34
  background-color: #FFC700

.get-advice-for
  display: inline-block
  color: #FFFFFF
  font-size: 22px
  font-weight: 700
  border-radius: 20px
  line-height: 27.26px
  background-color: #0075FF
  padding: 18.5px 67px

.get-advice-for:hover
  color: #FFFFFF
  background-color: #4098FF

.get-advice-for:active
  color: #FFFFFF
  background-color: #005ECC

.title_sign-button
  background: none
  outline: none
  box-shadow: none
  color: #FFFFFF
  font-size: 24px
  font-weight: 600
  border-radius: 20px
  border: 2px #FFFFFF solid
  padding: 23.5px 48.5px

.title_sign-button:hover
  cursor: pointer
  background-color: #FFFFFF33

.title_sign-button:active
  background-color: #FFFFFF80

._statistics
  background-color: #F1F2F5
  padding-top:  80px
  padding-bottom: 80px

._statistics-container
  display: flex
  justify-content: center
  flex-wrap: wrap

._statistic-block
  display: flex
  flex-direction: column
  width: 350px
  margin: 0 20px

._statistic-h3
  font-size: 48px
  font-weight: bold
  color: #21283E
  margin: 0 auto

._statisctic-p
  font-size: 18px
  font-weight: 400
  color: #4F525D
  margin: 10px auto
  text-align: center

._pupil_process
  padding-top:  40px
  padding-bottom: 20px

._pupil_process-container
  display: flex
  flex-direction: column
  justify-content: center

._pupil_process-title
  font-size: 36px
  font-weight: 700
  color: #0075FF
  margin: 0 auto 24px auto

._pupil_process-title-h3
  font-size: 36px
  font-weight: 700
  color: #0075FF
  margin: 0 auto 24px auto

._pupil_process-title-p
  max-width: 882px
  margin: 0 auto 40px auto
  font-size: 16px
  font-weight: 400
  line-height: 22.4px
  text-align: center

._pupil_process-blocks
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-column-gap: 20px
  grid-row-gap: 20px

._pupil_process-mobile-blocks
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-column-gap: 20px
  grid-row-gap: 20px

._pupil_process-block
  display: flex
  padding: 16px

._block_title
  width: 100%
  padding: 0

._block-h3
  color: #0075FF
  font-size: 28px
  font-weight: 700
  line-height: 24px
  margin-bottom: 12px

._block-p
  margin: 0 0 28px 0
  color: #3A4651
  font-size: 20px
  font-weight: 400
  line-height: 24px

._freetry
  padding-top: 40px
  padding-bottom: 30px

._freetry-bonus
  background-color: #F1F3F4

._freetry_container
  position: relative
  margin: 0 auto
  background: #0075FF
  border-radius: 40px
  padding: 60px

._freetry_container::before
  content: ""
  display: block
  position: absolute
  top: 0
  bottom: 0
  right: 0
  left: 0
  background: url("../../images/landing/space-img.png")
  mix-blend-mode: plus-lighter
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  border-radius: 40px

._freetry_h3
  margin-top: 0
  width: 634px
  font-size: 48px
  line-height: 120%
  font-weight: 800
  color: #FFFFFF

._freetry_h3_span
  color: #FFE500

._freetry_p
  width: 634px
  font-size: 24px
  line-height: 160%
  font-weight: 600
  color: #FFFFFF

._freetry_button-1
  font-size: 24px
  font-weight: 700
  background-color: #FFFFFF
  color: #062D7C
  border-radius: 64px
  padding: 28px 198.5px

._freetry_button-2
  margin-top: 24px
  display: none
  font-size: 18px
  font-weight: 700
  background-color: #FFFFFF
  color: #062D7C
  border-radius: 64px
  padding: 20px 0

._img-human
  position: absolute
  bottom: 0
  right: 150px
  width: 537px
  height: 651px

._img-human-2
  position: absolute
  transform: translateX(-50%)
  left: 50%
  top: -150px
  width: 554px
  height: 831px

._human-img
  background: url("../../images/landing/human-img.png")
  background-size: 100% 100%
  width: 537px
  height: 651px

._advantages
  display: flex
  padding-top: 60px

._advantages_container
  display: flex
  margin-left: auto
  margin-right: auto
  flex-direction: column
  align-items: center

._advantages-blocks
  position: relative
  display: flex
  flex-direction: row
  gap: 20px
  width: 100%

._advantages-block
  display: flex
  flex-direction: column
  gap: 20px
  width: calc((100% - 40px)/3)
  justify-content: center

._advantages-block-item
  padding: 20px

._advantages-h3
  font-size: 36px
  font-weight: 700
  color: #384048

._advantages-block-h3
  margin: 8px 0
  font-size: 20px
  font-weight: 700
  color: #384048

._advantages-block-p
  margin: 0
  font-size: 16px
  font-weight: 400
  line-height: 24px
  color: #708090

._adh3
  margin-bottom: 40px

._qalan-logo-footer
  width: 165px
  height: 52px
  margin: 0
  background: url("../../images/landing/qalan-logo-footer.svg")
  background-size: contain
  background-repeat: no-repeat
  background-position: center

._qalan-logo-footer-uzb
  width: 165px
  height: 52px
  margin: 0
  background: url("../../images/landing/qalan-logo-footer-uzb.svg")
  background-size: contain
  background-repeat: no-repeat
  background-position: center

._people-feedbacks-img-one
  width: 680px
  height: 397px
  background: url("../../images/landing/feedbacks-img-one.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center

._people-feedbacks-img-two
  width: 343px
  height: 431px
  background: url("../../images/landing/feedbacks-img-two.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  display: none

._instagram-logo
  width: 36px
  height: 36px
  margin: 0 0 23px 0
  background: url("../../images/landing/instagram-logo.svg")
  background-size: contain
  background-repeat: no-repeat
  background-position: center

._main-playmarket-dark
  width: 179px
  height: 50px
  margin: 0 8px 0 0
  background: url("../../images/landing/playmarket-button-dark.svg")
  background-size: contain
  background-repeat: no-repeat
  background-position: center

._main-appstore-dark
  width: 158px
  height: 50px
  background: url("../../images/landing/appstore-button-dark.svg")
  background-size: contain
  background-repeat: no-repeat
  background-position: center

._phone-img
  background: url("../../images/landing/phone-img.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center

._app-parent
  background: url("../../images/landing/app-for-parent-img.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  width: 189px
  height: 288px

._app-pupil
  background: url("../../images/landing/app-for-pupil-img.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  position: absolute
  top: -41px
  right: calc(50% - 88px)
  width: 176px
  height: 288px

._qalan-info-mentor-img
  background: url("../../images/landing/mentor-img.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  width: 218px
  height: 348px

._mentor-support-img
  background: url("../../images/landing/mentor-support-img.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  position: absolute
  bottom: 0
  left: calc(50% - 193.5px)
  width: 387px
  height: 390px

._app-download-arrow
  background: url("../../images/landing/app-download-icon.svg")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  width: 48px
  height: 64px

._olympiad-cnt-laptop
  background: url("../../images/landing/laptop-img.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  position: absolute
  bottom: -15px
  right: 60px
  width: 501px
  height: 456px
  z-index: 9999

._app-presentation-img
  background: url("../../images/landing/app-download-img.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  position: absolute
  bottom: -60px
  right: 60px
  width: 674.67px
  height: 552.57px

._app-presentation-two-img
  display: none
  background: url("../../images/landing/app-download-two-img.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  position: absolute
  right: calc(50% - 214.83px)
  bottom: -17px
  width: 429.66px
  height: 368.34px

._footer
  background-color: #F1F3F4
  padding: 60px 0

._modal-header
  border-bottom: #E4E8EC 1px solid
  align-items: center
  display: flex
  justify-content: space-between

._modal-title
  margin: 0
  padding: 19px
  font-size: 20px
  font-weight: 700

._modal-boby
  padding: 24px

._modal-switch
  border-radius: 16px
  background-color: #E4E8EC
  display: flex
  justify-content: space-between
  width: 100%
  padding: 2px
  margin-bottom: 20px

._modal-switch-login
  border: none
  outline: none
  box-shadow: none
  padding: 12px 0
  font-size: 18px
  font-weight: 400
  color: #8090A0
  background-color: #E4E8EC
  border-radius: 16px
  width: 50%

._modal-switch-login:disabled
  background-color: #0075FF
  color: #FFFFFF

._modal-switch-register
  border: none
  outline: none
  box-shadow: none
  padding: 10px 0
  font-size: 18px
  font-weight: 400
  color: #8090A0
  background-color: #E4E8EC
  border-radius: 16px
  width: 50%

._modal-switch-register:disabled
  background-color: #0075FF
  color: #FFFFFF

._modal-form-phone
  width: 100%
  display: flex
  justify-content: center

._modal-form-select
  width: 30%
  position: relative
  display: inline-block

._modal-form-select::after
  content: ''
  position: absolute
  top: 50%
  right: 24px
  transform: translateY(-50%)
  border: 5px solid transparent
  border-top-color: #000
  pointer-events: none

._change-country
  appearance: none
  -webkit-appearance: none
  -moz-appearance: none
  padding: 20px 0 20px 24px
  width: 100%
  height: 64px
  border-radius: 100px 0 0 100px
  font-size: 18px
  font-weight: 500
  color: #384048
  outline: none
  border: 0.5px #E4E8EC solid

._change-country:focus
  border-radius: 0

._form-phone-input
  font-weight: 500
  font-size: 18px
  color: #384048
  padding-left: 24px
  width: 65%
  border-radius: 0 100px 100px 0
  outline: none
  border: 0.5px #E4E8EC solid

._modal-input_container
  width: 100%
  display: flex
  justify-content: center

._form-input
  font-weight: 500
  font-size: 18px
  color: #384048
  padding: 20px 64px 20px 24px
  width: 100%
  border-radius: 16px
  outline: none
  border: 1px #E4E8EC solid

._input-phone-number-login .flag-dropdown
  background: #FFFFFF
  width: 70px
  border: 1px #E4E8EC solid
  border-radius: 16px 0 0 16px

._input-phone-number-login .flag-dropdown .selected-flag
  width: 70px
  display: flex
  justify-content: center
  padding: 0
  background: none

._modal-login-form-forget-password
  margin-top: 16px
  width: 100%
  display: flex
  justify-content: flex-end

._forget-password
  font-size: 14px
  font-weight: 500
  color: #062D7C

._modal-login-form-button
  margin-top: 16px
  width: 100%
  display: flex
  justify-content: flex-end

._login-button
  border: none
  outline: none
  box-shadow: none
  width: 100%
  border-radius: 16px
  padding: 20px 0
  font-weight: 400
  font-size: 18px
  background-color: #0075FF
  color: #FFFFFF

._login-button:hover
  cursor: pointer
  background-color: #4098FF

._login-button:active
  background-color: #005ECC

._login-button:disabled
  background-color: #8b9396

._modal-register-h3
  font-size: 16px
  font-weight: 400
  line-height: 14px
  margin: 20px 0 4px 24px

._modal-register-radio-pupil, ._modal-register-cb-teacher
  margin-top: 16px
  display: flex
  align-items: center

._modal-register-radio
  width: 24px
  height: 24px

._modal-register-radio-h3
  margin-left: 4px
  font-size: 14px
  font-weight: 500
  color: #384048

._leads-form
  background-color: #062D7C
  margin-top: 60px

._leads-form_container
  display: flex
  flex-direction: column
  align-items: center

._leads-form-h3
  margin: 60px 0 0 0
  font-size: 24px
  font-weight: 700
  line-height: 29px
  color: #FFFFFF

._leads-form-form
  max-width: 634px
  width: 100%
  display: flex
  flex-direction: column
  margin-top: 28px

._leads-form-name
  all: unset
  background: #FFFFFF
  width: calc(100% - 48px)
  padding: 20px 24px
  margin-bottom: 16px
  border-radius: 16px
  font-weight: 400
  font-size: 18px
  line-height: 24px
  color: #384048
  z-index: 500

._leads-form-number
  all: unset
  margin-top: 16px
  background: #FFFFFF
  width: calc(100% - 48px)
  padding: 20px 24px
  border-radius: 20px
  font-weight: 500
  font-size: 18px
  line-height: 24px
  color: #384048
  z-index: 500

._metor-support-content
  width: 100%
  position: relative
  background-color: #FFD500
  height: 347px
  border-radius: 24px 24px 0 0

._leads-form-number::-webkit-outer-spin-button, ._leads-form-number::-webkit-inner-spin-button
  -webkit-appearance: none
  margin: 0

._leads-form-button
  border: none
  outline: none
  box-shadow: none
  margin-top: 28px
  background: #FFD500
  color: #2B2C34
  width: 100%
  padding: 20px 24px
  border-radius: 20px
  font-weight: 700
  font-size: 18px
  line-height: 24px
  z-index: 5000

._leads-form-button:disabled
  opacity: 50%

._leads-form-button:hover:not(:focus-visible)
  background-color: #FFEA80
  cursor: pointer

._leads-form-button:active:not(:focus-visible)
  background-color: #CCAA00

._leads-form-button:focus-visible
  padding: 16px 20px
  border: 4px solid #FFFFFF80

._menu-left-cnt
  display: flex

._select_language-new
  width: 80px
  height: 52px
  font-weight: 500
  font-size: 16px
  color: #FFFFFF

._select_language-new .is-selected
  color: #FFFFFF

._leads-form-accepted
  width: 100%

._leads-form-accepted-p
  color: #FFFFFF
  font-size: 16px
  font-weight: 500
  line-height: 20px

._leads-form-accepted-p span
  color: #FFD500

._leads-form-accepted
  margin-top: 12px

._pupil_process-block-mobile
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: center
  max-width: 480px
  padding: 0 48px
  background: #EBF4FF
  border-radius: 24px
  box-shadow: 0 8px 20px 0 #010E551F

._mobile-process-h3
  margin: 48px 0 0 0
  color: #0075FF
  font-size: 28px
  font-weight: 600
  text-align: center

._mobile-process-p
  color: #384048
  margin: 12px 0 0 0
  font-size: 18px
  font-weight: 400
  text-align: center

._mobile-diagnostic-img
  margin: 0 auto

._client-list
  background-color: #F1F3F4
  padding: 0

._client-list_container
  max-width: 100%
  background-color: #F1F3F4
  padding: 40px 0

._client-list-h3
  text-align: center
  margin: 0 auto 20px auto
  font-size: 28px
  font-weight: 700
  line-height: 34.13px
  color: #0075FF

._client-list_content
  width: 300px

._client-list-logo
  margin: 0 auto

._client-list-p
  text-align: center
  color: #000000
  font-weight: 400
  font-size: 20px
  line-height: 28px
  margin: 10px 0 0 0

._for-school_container
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  padding: 60px 0 0 15px

._for-school-blocks
  width: 100%
  display: flex
  margin-bottom: 60px
  align-items: center
  justify-content: center

._for-school-blocks-two
  width: 100%
  display: flex
  flex-direction: row-reverse
  margin-bottom: 60px
  align-items: center
  justify-content: center

._for-school-h3
  max-width: 519px
  color: #1A83FF
  line-height: 52px
  font-weight: 700
  font-size: 42px
  margin: 0 0 20px 0

._for-school-h4
  max-width: 100%
  color: #2B2C34
  line-height: 52px
  font-weight: 700
  font-size: 42px
  margin: 0 0 48px 0

._for-school-list
  width: 50%
  margin-left: 40px

._for-school-lt
  display: flex
  margin-bottom: 24px

._for-school-p
  margin: 0
  max-width: 480px
  font-size: 20px
  line-height: 28px
  font-weight: 400

._checked
  margin-right: 12px
  width: 60px

._checked-two
  margin-right: 12px
  width: 36px

._for-school-video
  width: 50%
  padding: 0 40px
  display: flex
  justify-content: space-between

._for-school-comment
  width: 50%
  padding-right: 80px

._for-school-video iframe
  border-radius: 21px
  width: 206.25px
  height: 319.5px

._for-school-video-p
  color: #000000
  margin: 8px 0 0 0
  font-size: 14px
  line-height: 24px
  font-weight: 500
  text-align: center

._for-school-after
  margin-top: 40px

._footer-left-p
  margin: 20px 0
  font-size: 16px
  font-weight: 400
  line-height: 20px
  color: #384048

._app-mobile-footer
  display: flex

._footer-up
  display: flex

._footer-right
  display: flex
  width: 66%

._footer-left
  width: 33%

._footer-right-left
  width: 50%

._footer-right-right
  width: 50%

._footer-right-right-h3
  font-weight: 600
  font-size: 20px
  line-height: 20px
  margin-bottom: 20px
  color: #000000

._footer-right-right-links
  display: flex
  flex-direction: column

._footer-right-right-a
  color: #0075FF
  font-weight: 500
  font-size: 16px
  line-height: 20px
  margin-bottom: 12px

._footer-right-left-h3
  font-weight: 600
  font-size: 20px
  line-height: 20px
  margin-bottom: 20px
  color: #000000

._footer-right-left-p
  font-weight: 500
  font-size: 16px
  line-height: 20px
  margin: 0 0 12px 0
  color: #384048

._just-line
  margin: 40px 0
  width: 100%
  height: 2px
  background-color: #CCD4DE

._footer-down
  display: flex
  justify-content: center

._footer-down a
  color: #384048
  font-size: 16px
  line-height: 20px
  font-weight: 400
  margin: 0 20px

._error-message-cnt
  padding: 12px
  display: flex
  margin-top: 37px
  width: calc(100% - 24px)
  border-radius: 12px
  border: 1px solid #F44336
  background-color: #FDD9D7

  &.hidden
    display: none
    visibility: hidden

  p
    margin: 0 0 0 10px
    color: #F44336
    font-weight: 600
    font-size: 14px
    line-height: 24px

._olympiad-cnt
  padding: 0 15px
  margin: 60px 0

._olympiad-cnt_container
  position: relative
  background: #FFD500
  background-repeat: no-repeat
  background-position: center
  border-radius: 40px
  padding: 60px

._space-img
  border-radius: 40px
  position: absolute
  top: 0
  bottom: 0
  right: 0
  left: 0
  background: url("../../images/landing/space-img.png")
  mix-blend-mode: plus-lighter
  background-size: cover
  background-position: center
  background-repeat: no-repeat
  z-index: 1000

._olympiad-cnt-text
  display: flex
  flex-direction: column
  max-width: 761px
  z-index: 500

._olympiad-cnt-h3
  margin-bottom: 12px
  color: #2B2C34
  font-weight: 700
  font-size: 42px
  line-height: 52px
  z-index: 500

._olympiad-cnt-p
  margin: 0 0 50.5px 0
  color: #2B2C34
  font-weight: 500
  font-size: 24px
  line-height: 33px
  z-index: 100

._olympiad-cnt-a
  width: max-content
  color: #FFFFFF
  font-size: 22px
  font-weight: 700
  line-height: 27px
  padding: 18.5px 61px
  border-radius: 16px
  background-color: #1A83FF
  z-index: 10000

._olympiad-cnt-a:hover
  color: #FFFFFF
  background-color: #4098FF

._olympiad-cnt-a:active
  color: #FFFFFF
  background-color: #005ECC

._qalan-info_container
  margin-bottom: 60px
  display: flex
  justify-content: center

._qalan-info-cnt
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  grid-template-rows: auto auto auto
  grid-column-gap: 40px
  grid-row-gap: 40px

._app-for-parent
  grid-column: span 2

._app-for-pupil
  grid-row: span 2

._lot-of-task
  grid-row: span 2

._mentor-thirty
  grid-column: span 2

._app-for-parent
  background-color: #F1F3F4
  border-radius: 24px
  padding: 48px
  display: flex

._mentor-thirty
  background-color: #F1F3F4
  border-radius: 24px
  padding: 48px
  display: flex
  align-items: center

._app-for-pupil
  background-color: #F1F3F4
  position: relative
  border-radius: 24px
  padding: 48px

  ._app-for-pupil-text
    margin-top: 220px

._lot-of-task
  background-color: #F1F3F4
  border-radius: 24px

  ._lot-of-video-text
    margin: 20px 0
    padding: 0 48px

._random-info
  border-radius: 24px
  background-color: #1A83FF
  padding: 32.5px 70px 32.5px 70px
  display: flex
  justify-content: center
  flex-direction: column
  align-items: center
  height: 129px

._random-info-second
  display: none
  border-radius: 24px
  background-color: #1A83FF
  padding: 32.5px 70px

._random-info-h3
  color: #FFFFFF
  font-size: 48px
  line-height: 67px
  font-weight: 700
  text-align: center
  margin: 0
  opacity: 1
  transition: opacity 0.5s ease-in-out

._random-info-p
  color: #FFFFFF
  font-size: 20px
  line-height: 28px
  font-weight: 600
  text-align: center
  margin: 0
  opacity: 1
  transition: opacity 0.5s ease-in-out

._app-for-parent-text
  margin-left: 40px
  max-width: 555px

._app-for-parent-h3
  color: #1A83FF
  font-size: 34px
  font-weight: 700
  line-height: 42px
  margin: 0 0 16px 0

._app-for-parent-p
  color: #2B2C34
  font-size: 20px
  font-weight: 500
  line-height: 28px
  margin: 0 0 16px 0

.fade-in
  opacity: 1

.fade-out
  opacity: 0

._app-download-cnt
  display: flex
  align-items: center

._app-download-p
  font-weight: 700
  font-size: 24px
  line-height: 29px
  color: #0075FF
  margin: 0 8px 0 0

._our-mission_container
  display: flex
  align-items: center
  position: relative

._qalan-info-h3
  color: #1A83FF
  font-weight: 700
  font-size: 42px
  line-height: 52px
  margin: 0 0 20px 0

._our-mission-text
  max-width: 744px
  margin-top: 115px
  margin-bottom: 149px

._our-mission-h3
  margin: 0 0 20px 0
  line-height: 33px
  font-size: 24px
  font-weight: 600
  color: #1A83FF

._our-mission-p
  margin: 0
  line-height: 42px
  font-size: 34px
  font-weight: 700
  color: #2B2C34

._for-school
  background-color: #F1F3F4

._people-feedbacks
  margin: 60px 0

._people-feedbacks_container
  display: flex
  align-items: center
  justify-content: space-between

._people-feedbacks-text
  max-width: 680px

._people-feedbacks-h3
  max-width: 680px
  color: #1A83FF
  font-size: 42px
  font-weight: 700
  line-height: 52px
  margin: 0 0 20px 0

._people-feedbacks-p
  color: #2B2C34
  font-size: 20px
  font-weight: 500
  line-height: 28px
  margin: 0 0 20px 0

._info-cards_container
  display: flex
  flex-direction: column
  justify-content: center

._info-cards-h3
  margin: 40px 0 30px 0
  width: 1400px
  font-size: 54px
  font-weight: 700
  line-height: 66.85px
  color: #1A83FF

._info-cards_cnt
  display: grid
  justify-content: center
  grid-template-columns: 440px 440px 440px
  gap: 40px

._pupil-level
  padding: 48px

._pupil-level-title
  display: flex
  align-items: center
  margin-bottom: 20px

._pupil-level-h3
  font-size: 34px
  font-weight: 700
  line-height: 42px
  color: #1A83FF
  margin: 0 0 20px 0

._pupil-level-h4
  margin: 0

._pupil-level-p
  font-size: 20px
  font-weight: 500
  line-height: 28px
  color: #2B2C34
  margin: 0 0 20px 0

._pupil-level-cnt
  display: flex

._pupil-level-link
  max-width: 286px
  margin-left: 9px

._pupil-level-link-h3
  font-size: 16px
  font-weight: 700
  line-height: 19px
  color: #1A83FF
  margin: 0 0 2px 0

._pupil-level-link-a
  font-size: 12px
  font-weight: 400
  line-height: 14px
  color: #1A83FF
  margin: 0

._play-video-icon
  width: 49px
  height: 49px
  cursor: pointer
  background: url("../../images/landing/player-button-icon.svg")
  background-size: contain
  background-repeat: no-repeat
  background-position: center

._chat-background
  width: 440px
  height: 328px
  background: url("../../images/landing/chat-background-small.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  position: absolute
  left: 0
  bottom: -10px

._chat-gif
  margin: 0 auto
  width: 404px
  height: 314px
  background: url("../../images/landing/chat-gif.gif")
  background-size: contain
  background-repeat: no-repeat
  background-position: center

._diagnostic-math
  overflow: hidden
  position: relative
  padding: 48px
  background-color: #F1F3F4
  border-radius: 24px

._diagnostic-math-cnt
  display: flex
  justify-content: space-between
  margin-bottom: 12px

._diagnostic-math-h3
  font-size: 34px
  font-weight: 700
  line-height: 42px
  color: #1A83FF
  margin: 0

._diagnostic-math-h4
  max-width: 294px
  font-size: 32px
  font-weight: 700
  line-height: 38px
  color: #1A83FF
  margin: 0

._diagnostic-math-p
  font-size: 20px
  font-weight: 500
  line-height: 28px
  color: #2B2C34
  margin: 0

._task-list-img
  width: 344px
  height: 260px
  background: url("../../images/landing/task-list-img.svg")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  margin: 71px auto 0 auto

._personal-studys-img
  width: 315px
  height: 354px
  background: url("../../images/landing/personal-study-img.svg")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  position: absolute
  left: calc(50% - 157.5px)
  bottom: -30px

._video-layers-img
  width: 344px
  height: 540px
  background: url("../../images/landing/viedo-layers-img.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  position: absolute
  left: calc(50% - 177px)
  bottom: -210px

._streak-task-img
  width: 508px
  height: 433px
  background: url("../../images/landing/streak-task-img.svg")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  position: absolute
  left: calc(50% - 254px)
  bottom: -70px

._cashback-notification-img
  width: 316px
  height: 683px
  background: url("../../images/landing/cashback-notification-img.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  position: absolute
  left: calc(50% - 158px)
  bottom: -320px

._video-studys
  margin-bottom: 296px

._app-presentation
  padding: 120px 15px 60px 15px

._app-presentation_container
  position: relative
  display: flex
  flex-direction: column
  border-radius: 40px
  background-color: #1A83FF
  padding: 60px

._app-presentation_container::before
  content: ""
  display: block
  position: absolute
  top: 0
  bottom: 0
  right: 0
  left: 0
  background: url("../../images/landing/space-img.png")
  mix-blend-mode: plus-lighter
  background-size: cover
  background-position: center
  background-repeat: no-repeat

._app-presentation-h3
  max-width: 676px
  font-size: 42px
  font-weight: 700
  line-height: 52px
  color: #FFFFFF
  margin-bottom: 24px

  span
    color: #FFD500

._app-presentation-p
  max-width: 582px
  font-size: 18px
  font-weight: 400
  line-height: 21px
  color: #FFFFFF
  margin: 0 0 44px 0

._app-presentation-a
  width: max-content
  background-color: #FFD500
  border-radius: 20px
  font-size: 18px
  font-weight: 600
  line-height: 24px
  color: #2B2C34
  padding: 20px 135px
  z-index: 10000

._app-presentation-a:disabled
  opacity: 50%

._app-presentation-a:hover:not(:focus-visible)
  background-color: #FFEA80
  color: #2B2C34
  cursor: pointer

._app-presentation-a:active:not(:focus-visible)
  background-color: #CCAA00
  color: #2B2C34

._app-presentation-a:focus-visible
  padding: 16px 20px
  border: 4px solid #FFFFFF80
  color: #2B2C34

._our-client
  padding: 40px 0 28px 0
  background-color: #F1F3F4

._our-client-h3
  margin: 0
  font-size: 34px
  font-weight: 700
  color: #2B2C34
  line-height: 42px

._modal-overlay
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: rgba(0, 0, 0, 0.8)
  z-index: 10000
  overflow-y: hidden
  padding: 0 15px 0 15px

._modal-view
  position: absolute
  -webkit-overflow-scrolling: touch
  width: 90%
  top: 50%
  transform: translate(-50%, -50%)
  left: 50%
  right: auto
  bottom: auto
  border: 1px solid #ccc
  background: #fff
  overflow-y: auto
  outline: none
  max-width: 520px
  height: auto
  min-height: 200px
  max-height: 100%
  border-radius: 28px
  z-index: 10000

._phone-number-a
  text-decoration: none
  color: #FFFFFF

._phone-number-a:hover
  color: #FFFFFF

._input-phone-number-login
  z-index: 5001

@keyframes scroll
  0%
    transform: translateX(0)
  100%
    transform: translateX(calc(-300px * 9))

.slider-main-page
  background: #F1F3F4
  height: 166px
  margin: auto
  overflow: hidden
  position: relative
  width: 100%
  border-radius: 0 0 20px 20px

  &::before,
  &::after
    content: ""
    height: 100px
    position: absolute
    width: 200px
    z-index: 2

  &::after
    right: 0
    top: 0
    transform: rotateZ(180deg)
  &::before
    left: 0
    top: 0

.slide-track
  animation: scroll 20s linear infinite
  display: flex
  width: calc(300px * 27)

.slide
  height: 100px
  width: 300px

@media (max-width: 1600px)
  ._main-pupil
    width: 573px
    height: 601px

  ._img-human
    right: 50px

  ._freetry_container
    padding-left: 20px
    padding-right: 20px
    border-radius: 0
    margin: 0

  ._freetry_container::before
    border-radius: 0

  ._main_title-p
    font-size: 28px
    line-height: 34px

  ._main-iframe
    width: 650px
    height: 340px

@media (max-width: 1475px)
  #_freetry-bonus
    padding-top: 0
    padding-bottom: 0

  ._app-presentation
    padding: 60px 0 0 0

  ._app-presentation-h3
    font-size: 32px
    line-height: 39px

  ._app-presentation-p
    font-size: 20px
    line-height: 32px

  ._app-presentation_container
    border-radius: 0
    padding: 31px 40px 506px 40px

  ._app-presentation-img
    right: calc(50% - 337.33px)
    bottom: -40px

  ._info-cards-h3
    width: 664px
    font-size: 32px
    line-height: 44.8px

  ._pupil-level
    padding: 28px

  ._diagnostic-math
    padding: 28px

  ._cashback-notification-img
    bottom: -380px

  ._chat-background
    width: 664px
    height: 338px
    background: url("../../images/landing/chat-background-large.png")
    bottom: -20px

  ._info-cards_cnt
    grid-template-columns: 664px

  ._for-school-h3
    max-width: 100%
    font-size: 32px
    line-height: 39px

  ._for-school-h4
    margin-bottom: 12px
    max-width: 100%
    font-size: 32px
    line-height: 39px
    color: #2B2C34

  ._for-school_container
    padding: 40px 0 0 20px

  ._qalan-info-h3
    margin-top: 40px
    font-size: 32px
    line-height: 39px

  ._olympiad-cnt
    padding: 0
    margin: 0

  ._olympiad-cnt_container
    border-radius: 0
    padding: 33px 16px

  ._space-img
    border-radius: 0

  ._olympiad-cnt-text
    margin-bottom: 305px

  ._olympiad-cnt-laptop
    width: 402px
    height: 366px

  ._olympiad-cnt-h3
    line-height: 39px
    font-size: 32px

  ._olympiad-cnt-p
    line-height: 28px
    font-size: 20px

  ._footer-up
    flex-direction: column
    align-items: center

  ._footer-left
    width: 100%
    display: flex
    flex-direction: column
    align-items: center

  ._footer-right
    margin-top: 40px
    width: 100%

  ._for-school-p
    font-size: 16px

  ._for-school-list
    width: 100%
    margin-left: 0
    margin-bottom: 40px

  ._for-school-blocks
    flex-direction: column-reverse

  ._for-school-blocks-two
    flex-direction: column-reverse

  ._for-school-video div:not(:last-child)
    margin-right: 20px

  ._for-school-video
    overflow-x: scroll
    scrollbar-width: none
    width: 100%
    padding: 0
    justify-content: center
    z-index: 1000

  ._for-school-comment
    width: 100%
    padding: 0
    display: flex
    justify-content: center

  ._for-school-video::-webkit-scrollbar
    width: 0
    height: 0

  ._main-playmarket
    margin-bottom: 20px

  ._main-appstore
    margin-bottom: 20px

  ._img-human
    right: calc(50% - 268.5px)

  ._freetry_container
    display: flex
    flex-direction: column
    justify-content: center

  ._freetry_h3
    width: 590px
    z-index: 4
    margin: 0 0 24px 0

  ._freetry_p
    margin: 0

  ._freetry_container
    padding: 40px 20px 640px 20px

  ._freetry_button-1
    display: none

  ._freetry_button-2
    display: block

  ._human-img-opacity
    display: block

  ._pupil_process-block-mobile
    padding: 0 12px

  .sign_button
    font-size: 14px
    padding: 15.5px 24px

  ._main-pupil
    width: 520px
    height: 546px

@media (max-width: 1390px)
  ._main-container
    flex-direction: column
    justify-content: center
    align-items: center

  ._main_title-h3
    font-size: 48px
    line-height: 59px

  ._main_title-img
    width: 490px
    height: 521px

  ._main_title-buttons
    display: flex
    justify-content: center

  ._main-container
    margin-top: 90px

  .get-advice
    padding: 19px 119.5px
    font-size: 18px

  .title_sign-button
    padding: 19px 119.5px
    font-size: 18px

  ._main-pupil
    width: 573px
    height: 601px

  ._qalan-info-cnt
    grid-template-columns: 1fr 1fr
    grid-column-gap: 32px
    grid-row-gap: 32px

  ._app-for-parent
    grid-column: span 2

  ._app-for-pupil
    grid-row: span 2

  ._lot-of-task
    grid-row: span 2

  ._mentor-thirty
    grid-column: span 2

  ._random-info-second
    display: block
    grid-column: span 2

  ._random-info
    display: none

  ._app-for-parent-h3
    font-size: 28px
    line-height: 34px

  ._app-for-parent-p
    font-size: 18px
    line-height: 25px

  ._app-download-p
    font-size: 20px
    line-height: 24px

  ._app-for-parent ._app-for-parent-text
    margin-left: 20px

  ._mentor-thirty ._app-for-parent-text
    margin-left: 20px

  ._app-for-parent
    padding: 28px

  ._app-for-pupil
    padding: 28px

  ._mentor-thirty
    padding: 28px

  ._lot-of-task ._lot-of-video-text
    padding: 0 28px

  ._app-for-pupil
    padding: 28px

  ._app-for-parent ._app-for-parent-text
    max-width: 550px

  ._mentor-thirty ._app-for-parent-text
    max-width: 550px

  ._task-video-img
    width: 316px
    height: 234px

  ._people-feedbacks-h3
    font-size: 32px
    line-height: 39px

  ._people-feedbacks-p
    font-size: 20px
    line-height: 28px

  ._people-feedbacks_container
    justify-content: start
    align-items: start
    flex-direction: column

  ._people-feedbacks-img-one
    margin: 0 auto

  ._people-feedbacks-text
    margin-bottom: 40px

  ._our-mission_container
    flex-direction: column
    justify-content: center

  ._our-mission-text
    margin-top: 0
    margin-bottom: 0
    max-width: 100%

  ._our-guys
    margin-top: 40px
    position: relative
    width: 620px
    height: 405px

  ._our-mission-h3
    font-size: 16px
    line-height: 22px

  ._our-mission-p
    font-size: 24px
    line-height: 33px

@media (max-width: 1100px)
  ._pupil_process-title
    font-size: 32px

  ._pupil_process-title-h3
    font-size: 32px

  ._pupil_process-blocks
    grid-template-columns: repeat(1, 1fr)

  ._pupil_process-mobile-blocks
    grid-template-columns: repeat(2, 1fr)

  ._block-h3
    font-size: 20px

  ._block-p
    font-size: 18px
    margin: 0

  ._phone-img
    width: 100%
    height: 478px

  ._advantages-block
    width: calc((100% - 20px)/2)

  ._advantages-block:nth-child(2)
    display: none
    visibility: hidden
    opacity: 0

  ._advantages-block-h3
    font-size: 16px

  ._footer
    padding: 40px 0

@media (max-width: 785px)
  ._freetry_container
    padding: 40px 20px 435px 20px

  ._img-human
    right: calc(50% - 180px)
    width: 360px
    height: 436px

  ._human-img
    width: 360px
    height: 436px

  ._container
    padding-left: 15px
    padding-right: 15px

  ._header-container
    padding: 15px 15px

  ._for-school_container
    padding: 40px 0 0 15px

  ._client-list_container
    padding: 40px 0

  ._qalan-info-cnt
    grid-template-columns: 1fr
    grid-column-gap: 12px
    grid-row-gap: 12px

  ._app-for-parent
    grid-column: span 1

  ._app-for-pupil
    grid-row: span 1

  ._lot-of-task
    grid-row: span 1

  ._mentor-thirty
    grid-column: span 1

  ._random-info-second
    grid-column: span 1
    padding: 32.5px 40px 32.5px 40px
    display: flex
    justify-content: center
    flex-direction: column
    align-items: center
    height: 129px

  ._app-for-parent-h3
    font-size: 20px
    line-height: 24px

  ._app-for-parent-p
    font-size: 16px
    line-height: 22px

  ._app-download-p
    font-size: 16px
    line-height: 19px

  ._app-for-parent
    flex-direction: column
    align-items: center
    justify-content: center

  ._mentor-thirty
    flex-direction: column
    align-items: center
    justify-content: center

  ._app-for-parent ._app-for-parent-text
    max-width: 100%
    margin-left: 0

  ._mentor-thirty ._app-for-parent-text
    max-width: 100%
    margin-left: 0

  ._app-for-parent
    padding: 28px

  ._app-for-pupil
    padding: 28px

  ._mentor-thirty
    padding: 28px

  ._lot-of-task ._lot-of-video-text
    padding: 0 28px

  ._app-for-pupil
    padding: 28px

  ._task-video-img
    width: 343px
    height: 255px

  ._for-school-reviews
    display: none

  ._for-school-reviews-mobile
    display: block

  ._for-school-video
    justify-content: start

  ._main-playmarket
    margin-bottom: 0

  ._main-appstore
    margin-bottom: 0

  ._main-iframe
    width: 500px
    height: 280px

  ._main_title-h3
    line-height: 44px
    font-size: 36px

  ._main_title-p
    font-size: 16px
    line-height: 19px

  ._main_title-img
    width: 370px
    height: 370px

  ._main_title-buttons
    flex-direction: column
    align-items: center

  ._main-container
    margin-top: 60px

  .get-advice
    padding: 19px 126px
    margin-right: 0
    margin-bottom: 12px

  .title_sign-button
    padding: 19px 108px

  ._pupil_process
    padding-top: 40px

  ._pupil_process-blocks
    grid-template-columns: repeat(1, 1fr)

  ._pupil_process-mobile-blocks
    grid-template-columns: repeat(1, 1fr)

  ._pupil_process-title
    font-size: 24px

  ._pupil_process-title-h3
    font-size: 24px

  ._block-p
    font-size: 16px

  ._advantages-blocks
    flex-direction: column

  ._advantages-block
    width: 100%

  ._main-iframe
    border-radius: 20px

  ._pupil_process-block-mobile
    margin: 0 auto

@media (max-width: 725px)
  ._info-cards-h3
    width: 343px
    font-size: 28px
    line-height: 30px

  ._video-studys
    margin-bottom: 196px

  ._personal-studys-img
    width: 255px
    height: 294px
    left: calc(50% - 127.5px)
    bottom: -30px

  ._video-layers-img
    width: 219px
    height: 343.78px
    left: calc(50% - 109.5px)
    bottom: -135px

  ._streak-task-img
    width: 337px
    height: 334.77px
    left: calc(50% - 168.5px)
    bottom: -80px

  ._cashback-notification-img
    width: 206.72px
    height: 446.81px
    left: calc(50% - 103.36px)
    bottom: -225px

  ._pupil-level-h3
    font-size: 24px
    line-height: 33.6px

  ._pupil-level-p
    font-size: 16px
    line-height: 22.4px

  ._pupil-level-link-h3
    font-size: 16px
    line-height: 19.81px

  ._pupil-level-link
    max-width: 229px

  ._diagnostic-math-h3
    font-size: 24px
    line-height: 33.6px

  ._diagnostic-math-h4
    max-width: 217px
    font-size: 24px
    line-height: 33.6px

  ._diagnostic-math-p
    font-size: 16px
    line-height: 22.4px

  ._task-list-img
    width: 247px
    height: 190px
    margin-top: 46.45px

  ._chat-gif
    width: 270px
    height: 209px

  ._chat-background
    width: 343px
    height: 209px
    background: url("../../images/landing/chat-background-medium.png")
    bottom: 0

  ._info-cards_cnt
    grid-template-columns: 343px

  ._people-feedbacks-img-one
    display: none

  ._people-feedbacks-img-two
    margin: 0 auto
    display: block

  ._people-feedbacks-h3
    font-size: 28px
    line-height: 34px

  ._people-feedbacks-p
    font-size: 16px
    line-height: 22px

@media (max-width: 685px)
  ._our-guys
    width: 550px
    height: 320px

  ._app-presentation_container
    padding: 31px 16px 390px 16px

  ._app-presentation-a
    width: 100%
    display: flex
    justify-content: center
    padding: 14px 0
    border-radius: 12px

  ._app-presentation-img
    display: none

  ._app-presentation-two-img
    display: block

  ._olympiad-cnt-p
    line-height: 22px
    font-size: 16px

  ._olympiad-cnt-a
    font-size: 16px
    line-height: 19px
    padding: 16px 77px

  ._olympiad-cnt-laptop
    right: calc(50% - 150px)
    width: 301px
    height: 274px

  ._footer-right
    flex-direction: column
    margin: 40px auto 0 auto
    width: auto

  ._footer-right-right
    margin-top: 40px

  ._footer-right-left, ._footer-right-right
    width: auto

  .get-advice-for
    font-size: 16px
    padding: 16px 70px

  ._header-logo
    width: 90px

  ._logotype_qalan
    width: 90px
    height: 29px

  ._logotype_qalan_uzb
    width: 90px
    height: 29px

  ._img-human-2
    top: -185px
    width: 341px
    height: 511px

  ._human-img-opacity
    width: 341px
    height: 511px

  ._freetry_h3
    width: 100%
    font-size: 28px
    margin-bottom: 8px

  ._freetry_p
    width: 100%
    font-size: 18px

  ._phone-img
    height: 330px

@media (max-width: 555px)
  ._our-guys
    margin-top: 20px
    width: 375px
    height: 245px

  ._main-pupil
    width: 377px
    height: 396px

  ._icon
    margin: 0 0 16px 0

  ._pupil_process-block
    flex-direction: column

  ._main-iframe
    width: 351px
    height: 198px

  ._main-container
    margin-top: 20px

  ._main_title-h3
    margin-bottom: 25px
    line-height: 30px
    font-size: 28px

  ._main_title-p
    font-size: 16px

  ._statistic-h3
    font-size: 36px

  .get-advice
    text-align: center
    width: 100%
    padding: 19px 0

  .title_sign-button
    width: 100%
    padding: 19px 0
