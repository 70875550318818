.footer-block
  background-image: url(../../images/landing/footer-bg.png)
  background-color: rgba(38, 89, 136, 0.95)
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15)
  display: flex
  flex-direction: column
  align-items: center
  padding-bottom: 20px
  width: 100%

  .vertical-line
    border-bottom: 1px solid #C4C4C4
    margin-top: 10px
    width: 100%
    height: 0

  .block-inner
    display: flex
    flex-direction: column
    margin-top: 67px
    width: 100%

    .block-body
      display: flex
      justify-content: space-around
      gap: 3%

      .column
        display: flex
        flex-direction: column

        .column-item
          display: flex
          flex-direction: column
          font-size: 16px
          line-height: 20px
          letter-spacing: 1px
          white-space: nowrap

          & + .column-item
            margin-top: 17px

          .column-item-title
            color: #FFFFFF
            font-weight: bold

          .column-item-info
            color: #FFFFFF

    .footer-block-rules
      display: flex
      justify-content: space-between
      margin-top: 37px

      a, span
        font-size: 14px
        line-height: 20px
        letter-spacing: 1px
        color: #FFFFFF

    .footer-block-rules span
      margin-left: 50px

    .rules
      margin-right: 50px

      .rule-2
        margin-left: 3rem
