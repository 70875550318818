.draggable-element
  cursor: grab
  transition: transform 0.2s, left 0.2s, top 0.2s

  &.active
    cursor: grabbing
    transform: scale(1.05)

.drop-place-draggable
  border: 2px dashed #ccc
  min-height: 50px
  min-width: 50px
  transition: background-color 0.2s, border-color 0.2s

.draggable-dragged-start
  border-color: #666
  transition: background-color 0.2s, border-color 0.2s

.no-scroll
  overflow-y: hidden !important
  overflow-x: hidden !important


