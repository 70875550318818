.input
  background: #FFFFFF
  border: 1px solid #D3D3D3
  box-sizing: border-box
  border-radius: 30px
  font-size: 15px
  line-height: 24px
  color: black
  padding: 8px 2rem

  &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active
    transition: background-color 5000s ease-in-out 0s
    -webkit-text-fill-color: #fff !important

  &::placeholder
    color: #727272

  &:focus
    outline: none

  &:disabled
    background-color: #D3D3D3
    border-color: #8f8d8d

.error-message
  font-size: 14px
  color: red
  margin: 8px 0
  height: 24px

  &.hidden
    visibility: hidden

.icon-delete
  background-image: url(../../images/ic-pupil-delete.png)
  background-size: contain
  width: 15px
  height: 15px
