.task-creator-modal, .math-editor-modal, .add-or-edit-variable-modal, .add-generation-to-task-modal, .add-or-edit-formula-modal
  background-color: #F8F8F8
  color: #353535
  border-radius: 30px
  display: flex
  flex-direction: column
  padding: 0
  height: 100%

  .modal-header
    background-color: #F6F6F6
    border-bottom: 1px solid #DCDCDC
    display: flex
    justify-content: flex-end

    .modal-title
      font-size: 18px
      margin: 0 auto
      display: flex
      align-self: center

  .modal-body
    overflow-x: auto
    overflow-y: hidden
    height: 100%
    padding: 0

    .modal-body-content
      display: flex
      flex-direction: column
      align-items: center
      padding: 1.5rem 2rem
      height: calc(100% - 50px)

      .variable-list
        display: flex
        flex-direction: column
        margin-bottom: 8px

        .variable-item
          display: flex
          margin-bottom: 8px
          align-items: center

          .variable-name
            font-size: 20px
            font-weight: bolder

          .edit-variable-button, .remove-variable-button
            margin: 0 8px
            padding: 0 6px
            max-height: 25px
            text-transform: none

      .description-container, .solution-container
        width: 100%

      .variable-button
        text-transform: none
        padding: 4px 25px
        margin-left: 8px

      .description-button, .solution-button
        align-self: flex-start

      .textarea
        border: 1px solid #D3D3D3
        width: 100%
        border-radius: 20px
        padding: 1rem
        box-sizing: border-box
        outline: none
        height: 200px

      .answer-container
        display: flex
        align-items: center

      .task-folder-container
        margin-top: 1rem

        .task-folder-select, .task-folder-title
          width: 350px

  .modal-footer
    display: flex
    justify-content: center
    margin-bottom: 2rem
    padding-top: 1rem
    border-top: 1px solid $border-grey

    .save-button
      width: 213px
      margin-left: 1rem

  .ScrollbarsCustom-TrackY
    width: 8px !important
    background: #FFFFFF !important
    border: 1px solid #D3D3D3
    box-sizing: border-box
    border-radius: 0 !important

    .ScrollbarsCustom-Thumb
      background: #4898C5 !important
      border-radius: 0 !important
