@media screen and (max-width: 600px), screen and (max-height: 600px)
  .landing-page
    background: none

    .block-1
      background: #2F5F8C linear-gradient(135.24deg, #112C43 2.96%, #123F63 26.66%, rgba(89, 89, 89, 0) 81.86%)
      display: flex
      flex-direction: column

      .temp-login-form
        margin: 4px auto 0 auto
        background: none
        border: none

        .sign-in-button
          width: 100%
          margin-top: 38px

        .register-button
          width: 100%
          margin-top: 9px

      @keyframes pulse
        0%
          transform: scale(1)
        50%
          transform: scale(1.1)
        100%
          transform: scale(1)

      .whatsapp-button
        position: fixed
        right: 10px
        bottom: 60px
        transform: translate(-50%, -50%)
        border-radius: 50%
        width: 70px
        height: 70px
        color: #fff
        text-align: center
        line-height: 53px
        font-size: 35px
        z-index: 9999
        transition: transform 0.3s
        animation: pulse 2s infinite

        &:hover
          transform: scale(1.2)

      .whatsapp-icon
        background-image: url("../../images/ic-whatsapp.svg")
        width: 70px
        height: 70px
        mask-size: 100%
        flex-shrink: 0

      .login-form-container
        display: flex
        flex-direction: column

        .video-container
          width: 100%
          position: relative

        .video-header
          position: relative
          text-align: center
          top: 27px
          font-size: 20px

        .iframe-container
          width: 100%
          height: 275px

        .video-container iframe
          position: relative
          top: 20px
          left: 0
          width: 100%
          height: 100%

        .login-form
          margin: 20px auto 0 auto
          background: none
          border: none

          .sign-in-button
            width: 100%
            margin-top: 38px

          .register-button
            width: 100%
            margin-top: 9px


    .feature-list-block
      .feature-list
        .feature-list-row
          flex-wrap: wrap
          margin-bottom: 0
          justify-content: center

          .feature-item
            margin-left: 0
            margin-bottom: 40px

    .how-it-works-block
      background: linear-gradient(135.24deg, #112C43 2.96%, #123F63 26.66%, rgba(89, 89, 89, 0) 81.86%), #2F5F8C
      padding: 28px 17px 36px
      text-align: center

      .background
        display: none

      .container-header
        .exclamation-icon
          display: none

        .container-header-info
          margin: 0

          .container-header-title
            font-size: 25px
            line-height: 100%

          .container-header-description
            font-weight: normal
            font-size: 14px
            line-height: 20px
            width: 100%
            margin-top: 6px

      .container-body
        flex-direction: column

        .container-body-column
          & + .container-body-column
            margin-left: 0

          .column-item
            margin-top: 25px

            .column-item-icon
              display: none

            .column-item-content
              margin: 0

              .column-item-content-description
                width: 100%
                font-size: 16px

    .b2b-info-block
      background: linear-gradient(135.24deg, #112C43 2.96%, #123F63 26.66%, rgba(89, 89, 89, 0) 81.86%), #2F5F8C
      padding: 28px 17px 36px
      flex-direction: column

      .background
        display: none

      .container-header
        .exclamation-icon
          display: none

        .container-header-info
          margin: 0

          .container-header-title
            font-weight: normal
            font-size: 30px
            line-height: 20px
            width: 100%
            margin-top: 6px

      .container-body
        flex-direction: column

        .container-body-column
          & + .container-body-column
            margin-left: 0

          .column-item
            margin-top: 25px
            margin-left: 8px
            display: flex
            align-items: center
            justify-content: center

            .column-item-icon
              margin-top: 0
              border-radius: 50%
              background-color: #2D9CDB
              width: 24px
              height: 24px
              flex-shrink: 0

            .column-item-content
              margin: 0 0 0 23px

              .column-item-content-description
                width: 100%
                font-size: 16px

      .images-with-contact-number
        .images-scroll-block
          width: 100%
          height: 100%
          margin: 10px 0

    .for-somebody-block
      padding-top: 18px
      padding-bottom: 60px

      .block-inner
        flex-wrap: wrap
        padding: 0 18px

        .column
          display: flex
          flex-direction: column
          width: 100%

          & + .column
            margin-left: 0
            margin-top: 30px

          .column-item
            height: auto !important
            margin: 8px

            .column-item-text
              width: auto !important

    .reviews-block
      align-items: center
      padding-top: 30px
      padding-bottom: 46px

      .block-inner
        .block-title
          display: none

        .review-list
          flex-wrap: wrap
          margin-top: 0

          .review-item
            flex-direction: column
            align-items: center

            & + .review-item
              margin: 2rem 0 0

            .review-author-avatar
              flex-shrink: 0

            .review-body
              align-items: center

              .review-author-name
                font-weight: bold
                font-size: 21px
                color: #525252

              .review-author-title
                color: #949494
                margin-top: 8px

              .review-author-words
                color: #525252
                width: 100%
                margin-top: 16px
                text-align: center

@media screen and (max-width: 1200px)
  .landing-page
    background: none

    .block-1
      background: #2F5F8C linear-gradient(135.24deg, #112C43 2.96%, #123F63 26.66%, rgba(89, 89, 89, 0) 81.86%)

      .login-form
        background: none
        border: none
