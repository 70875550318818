.kemel-page
  display: flex
  flex-direction: column
  background-color: #F5F7FE

  .header
    padding: 4px 0
    align-self: center

  .page-body
    display: flex
    flex-direction: column
    align-self: center
    width: 600px

    .welcome-section
      font-size: 36px
      font-weight: 300
      color: black
      max-width: 600px
      padding: 40px 40px 0 40px

      .olympiad-name
        font-weight: 700

        &.mobile
          max-width: 90%
          display: flex
          justify-content: center
          text-align: center

    .olympiad-table
      border-collapse: collapse
      border-spacing: 0
      border-radius: 20px
      overflow: hidden
      background-color: white
      text-align: center
      font-family: "Montserrat", serif
      font-size: 16px

      .first
        background-image: url("../../images/ic-alarm-clock.svg")
        background-repeat: no-repeat
        background-position: center

      .second
        background-image: url("../../images/ic-pointer-olympiad.svg")
        background-repeat: no-repeat
        background-position: center

      .third
        background-image: url("../../images/ic-tool-olympiad.svg")
        background-repeat: no-repeat
        background-position: center

      td
        font-weight: 100
        padding: 15px 25px 15px 25px
        border: 1px solid #EFEFEF

        &:first-child
          background-color: #305C88

        &:nth-child(2)
          text-align: left

    .participant-form
      background: white url("../../images/bg-olympiad.jpg") no-repeat right
      background-size: 220px
      background-position-y: 85px
      display: flex
      flex-direction: column
      margin-bottom: 2rem
      padding-left: 40px
      padding-bottom: 20px
      padding-top: 25px
      border-radius: 7px
      box-shadow: 0 4px 62px rgba(0, 0, 0, 0.04)
      font-family: Roboto, sans-serif
      font-weight: 300

      .input-wrapper
        position: relative
        margin-bottom: 5px

        .input
          margin-top: 1rem
          width: 330px
          border: none
          border-bottom: 1px solid #C4C4C4
          border-radius: 0
          padding: 2px 0

          &:focus
            border-bottom-color: #2080F6

          &:disabled
            background-color: #EDEDED
            border-radius: 3px
            opacity: 0.5
            padding-left: 10px

          &:focus ~ .floating-label, &:disabled ~ .floating-label, &:not(:focus):valid ~ .floating-label
            top: 2px
            bottom: 10px
            left: 0
            font-size: 11px
            opacity: 1

        .floating-label
          position: absolute
          pointer-events: none
          left: 0
          top: 18px
          transition: 0.2s ease all
          color: #C3C3C3

      .grade-select
        margin-top: 1rem
        width: 100%
        max-width: 330px

        .Select-control
          border-radius: 0
          border: none
          border-bottom: 1px solid #C4C4C4

          .Select-placeholder
            padding-left: 0
            font-size: 15px

          .Select-value-label
            color: #2080F6

      .button-list
        margin-top: 40px
        display: flex
        flex-direction: column
        align-items: flex-start

        .start-button
          background-color: #3E608A
          border-radius: 5px
          text-transform: capitalize
          font-weight: bold

    .olympiad-result-section
      display: flex
      flex-direction: column
      border-radius: 7px
      box-shadow: 0 4px 62px rgba(0, 0, 0, 0.04)
      background-color: white
      padding: 20px 40px 40px 40px
      margin-bottom: 20px

      .result-label
        background-color: #3E608A
        text-transform: none

      .place-label
        font-family: Verdana, sans-serif

      .unsuccessful-label
        margin-top: 8px
        white-space: normal
        font-weight: 300

      .buy-diploma-container
        display: flex
        flex-direction: column
        align-items: center
        margin-top: 0

        .buy-diploma-title
          color: black
          font-weight: bold
          font-size: 18px
          margin: 20px 0

        .buy-label
          margin-top: 1rem
          margin-bottom: 8px
          font-size: 18px

      .download-button
        background-image: url("../../images/ic-download.png")
        background-color: #3E608A
        background-size: 32px
        background-position-x: 20px
        background-position-y: 3px
        background-repeat: no-repeat
        padding-left: 65px
        padding-right: 30px
        border-radius: 5px
        text-transform: none
        white-space: nowrap
        margin-bottom: 20px
        max-width: 250px
        align-self: center

@media screen and (max-width: 600px), screen and (max-height: 600px), (hover: none) and (pointer: coarse)
  .kemel-page
    .page-body
      width: 100%
      padding: 0 10px

      .welcome-section
        font-size: 26px

      .participant-form
        background: white
        padding: 20px
        align-self: center
        box-sizing: border-box
        width: 90%

        .input
          width: 100% !important

        .button-list
          margin-top: 20px
          align-items: center
          justify-content: center

      .olympiad-result-section
        padding: 20px
        margin-bottom: 20px
        width: 90%
        align-self: center
        box-sizing: border-box

      .buy-diploma-container
        .buy-diploma-title
          font-size: 15px !important
          margin-top: 20px !important

        .buy-button-list
          flex-direction: column

          .kaspi-button
            margin-right: 0
            margin-bottom: 8px

