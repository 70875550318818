.teacher-lesson-modal
  background-color: #F8F8F8
  color: #353535
  border-radius: 30px
  display: flex
  flex-direction: column
  padding: 0
  height: 100%

  .modal-header
    background-color: #F6F6F6
    border-bottom: 1px solid #DCDCDC
    display: flex
    justify-content: flex-end

  .modal-body
    overflow-x: auto
    overflow-y: hidden
    height: 100%
    padding: 0

    .modal-body-content
      display: flex
      flex-direction: column
      align-items: center
      padding: 1.5rem 0
      height: calc(100% - 50px)

      .task-folders-select
        width: 300px
        margin-left: 1rem

      .task-button
        position: absolute
        top: 13px
        width: 60px
        font-weight: bold
        font-size: 18px
        line-height: 24px
        color: #FFFFFF
        text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2)
        padding: 0
        height: 33px

      .tasks-quantity-container
        display: flex
        justify-content: space-between
        width: 100%
        margin-top: 22px

        .tasks-quantity
          background: linear-gradient(180deg, rgba(33, 130, 185, 0.5) 0%, rgba(255, 255, 255, 0) 100%), rgba(1, 112, 175, 0.8)
          border-radius: 0 30px 30px 0
          font-size: 18px
          line-height: 33px
          color: #FFFFFF
          text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2)
          width: 200px
          text-align: center

          &.right
            border-radius: 30px 0 0 30px

      .tasks-selector
        display: flex
        width: 100%
        height: 100%
        margin-top: 1.5rem

        .left-side
          flex-basis: 50%

        .lesson-task-list
          margin-right: 1rem
          margin-left: 22px

          .lesson-task-item
            padding: 22px 110px 22px 3rem
            margin: 10px 0
            display: flex
            justify-content: space-between
            align-items: center
            background: #FFFFFF
            border: 1px solid #D3D3D3
            box-sizing: border-box
            border-radius: 30px
            position: relative

            .add-task-button
              right: 1rem

        .selected-task-list
          height: 100%
          overflow: auto
          margin-left: 1rem
          margin-right: 22px

          .selected-task-item
            padding: 22px 3rem 22px 110px
            margin: 10px 0
            display: flex
            justify-content: space-between
            align-items: center
            background: #FFFFFF
            border: 1px solid #D3D3D3
            box-sizing: border-box
            border-radius: 30px
            user-select: none
            cursor: move
            position: relative

            .remove-task-button
              left: 1rem

  .modal-footer
    display: flex
    flex-direction: column
    align-items: center
    margin-bottom: 2rem

    .save-button
      width: 213px

  .ScrollbarsCustom-TrackY
    width: 8px !important
    background: #FFFFFF !important
    border: 1px solid #D3D3D3
    box-sizing: border-box
    border-radius: 0 !important

    .ScrollbarsCustom-Thumb
      background: #4898C5 !important
      border-radius: 0 !important
