.pupil-details-modal
  .modal-body
    padding-left: 0
    padding-right: 0

  .my-table
    th
      white-space: normal

  .lesson-list-container
    .chapter-list
      margin-left: 0
      margin-right: 0

@media screen and (max-width: 600px)
  .pupil-details-modal
    .pupil-details-lesson-item
      display: flex
      flex-direction: column !important

      .left-part
        width: 100% !important
        justify-content: center !important

      .connector
        display: none

      .right-part
        width: 100% !important
        border: none !important

