.edit-personal-study-modal
  color: $black
  border-radius: 20px
  display: flex
  flex-direction: column
  height: 100%

  .modal-header
    background-color: #F6F6F6
    border-bottom: 1px solid #DCDCDC
    display: flex
    justify-content: flex-end

    .modal-title
      font-size: 18px
      margin: 0 auto
      display: flex
      align-self: center

  .modal-body
    overflow-x: auto
    overflow-y: hidden
    height: 100%
    padding: 0

    .modal-body-content
      display: flex
      flex-direction: column
      align-items: center
      padding: 1.5rem 2rem
      height: calc(100% - 50px)

  .modal-footer
    display: flex
    justify-content: center
    margin-bottom: 2rem
    padding-top: 1rem
    border-top: 1px solid $border-grey
