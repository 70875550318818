.telegram-pagination-panel
  display: flex
  list-style: none
  padding: 0
  margin: 0

  .page-item
    margin: 0 5px

    &.active
      a
        background-color: #2e8abd
        color: #fff
        border-color: #2e8abd

    &:hover:not(.active) 
      a
        background-color: #f8f9fa
        color: #2e8abd

  .page-link 
    display: block
    padding: 0.5rem 0.75rem
    color: #2e8abd
    text-decoration: none
    background-color: #fff
    border: 1px solid #dee2e6
    border-radius: 0.25rem
    transition: all 0.3s ease

    &:hover
      background-color: #e9ecef
      color: #0056b3

    &:focus
      outline: none
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25)