// All variables defined here will be automatically
// available to all sass files thanks to
// 'sass-resources-loader'
$red: #FF0000
$blue: #046ff4
$black: #000000
$green: #02F84F
$grey: #C3C3C3
$border-grey: #c5c0c0
$white: #ffffff
$border-grey-2: #D7D7D7
$battery-color: #e7eced
$box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2)
$header-side-width: 305px
$header-height: 48px
$header-side-background: linear-gradient(to bottom, #E4E4E4, #D6D6D6)
$header-font-color: #6a6a6a
$background-color: #F5F5F5
$border-grey-3: #d7d7d7
$background-elem: linear-gradient(to bottom, #FCFCFC, #F7F7F7)
$default-background: rgba(38, 89, 136, 0.95)
$latex-symbols-popup-max-width: 400px

// All mixins defined here will be automatically
// available to all sass files thanks to
// 'sass-resources-loader'
@use "sass:math"

@function str-replace($string, $search, $replace: '')
  $index: str-index($string, $search)
  @if $index
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace)
  @return $string

=button($color)
  background-color: $color
  border-radius: 51px
  color: white
  padding: 5px

=penalty-icon($color: "", $dimension: 8)
  $color: str-replace($color, "#", "%23")
  $dimension_div_2: 4 //calc($dimension / 2)
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="#{$dimension}" height="#{$dimension}"><path d="M 0,0 L 0,#{$dimension} L #{$dimension},#{$dimension_div_2} z" fill="#{$color}"/></svg>')
  background-repeat: no-repeat
  background-color: transparent
  border-radius: 0
  background-size: 100%

@import "~react-select-v1/dist/react-select.css"
@import '~rc-slider/assets/index.css'
@import "~react-datepicker/dist/react-datepicker.min.css"
@import '~react-dropdown/style.css'
@import '~react-phone-input-2/lib/style.css'
@import '~react-virtualized/styles.css'

sup
  top: 0

html, body
  height: 100%
  font-family: "Roboto", sans-serif

svg
  -webkit-tap-highlight-color: transparent

foreignObject
  overflow: visible

.uk-text
  color: $black

.uk-text-primary
  color: $blue !important

.uk-text-bold
  font-weight: 600

.uk-label-default
  background-color: #6a6a6a
  color: white

.uk-form-horizontal .uk-form-label
  width: auto
  margin-top: 0px

.uk-countdown-number, .uk-countdown-separator
  font-size: 10.5px
  line-height: 14px
  color: #8f8d8d

.uk-button
  border-radius: 5px
  border: 1px solid #DCDCDC
  color: $black
  font-size: 14px
  text-align: center
  padding: 5px
  line-height: 1
  background-color: #FBFBFB

  &:hover:enabled
    background-color: #F6F6F6

.uk-button-primary
  border-color: #96C3FB
  max-height: 3rem
  background-color: #F6F6F6

  &:hover:enabled, &.selected
    background-color: #046FF4
    border-color: #0045BC
    color: $white

fieldset
  border-radius: 5px

.icon
  width: 21px
  height: 21px
  background-repeat: no-repeat
  background-position: center center
  background-size: 100%

.mjx-chtml
  padding: 2px 0 !important

.mq-editable-field
  padding: 8px 20px
  background: #FFFFFF
  border: 1px solid #D3D3D3
  box-sizing: border-box
  border-radius: 20px

  &.mq-focused
    border-color: rgb(44, 152, 240) !important
    box-shadow: none !important
    border-radius: 20px !important

  .mq-root-block
    border: none

.ReactModal__Body--open
  overflow: hidden
  position: fixed
  width: 100%
  height: 100%

  //formula latex to center
  .mjx-math
    width: auto !important
  //text latex with newline to center
  .mjx-full-width
    width: auto !important

.rcs-custom-scroll .rcs-inner-handle
  background-color: $border-grey-2 !important

.ScrollbarsCustom-Thumb
  background-color: #c5c0c0 !important

.uk-accordion-title
  line-height: 1.5
  color: $black
  padding: 0 4px
  font-weight: 500

  &::before
    background-image: url(../../images/ic-dropdown.png)

.uk-open
  .uk-accordion-title
    &::before
      background-image: url(../../images/ic-dropdown.png)
      transform: rotate(-90deg)

.logo
  background-image: url(../../images/logo-full.svg)
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))
  width: 141px
  height: 51px

@import "button"
@import "input"
@import "select"
@import "select-new"
@import "table"
@import "date-time-picker"
@import "dropdown-tree-select"
@import "tab-list"
@import "card-list"
@import "../all/pagination-panel"
@import "../all/telegram-pagination-panel"
@import "../all/jitsi-meet"
@import "../all/calculator"
@import "../all/landing-header"
@import "../all/landing-header.mobile"
@import "../all/footer"
@import "../all/footer.mobile"
@import "../all/landing-page"
@import "../all/main-page"
@import "../all/olympiad-preview"
@import "../all/landing-page.mobile"
@import "../all/register-page"
@import "../all/kemel-page"
@import "../all/olympiad-page"
@import "../all/olympiad-mobile-page"
@import "../all/mobile-cashback-info-page"
@import "../all/free-tasks-page"
@import "../all/page-header"
@import "../all/pupil-page"
@import "../all/navigation-panel"
@import "../all/my-profile-info"
@import "../all/rating-page"
@import "../all/pupil-self-works-page"
@import "../all/pricing-page"
@import "../all/personal-study-page"

@import "../all/login-form"
@import "../all/lesson-list"
@import "../all/task-modal"
@import "../all/confirmation-modal"
@import "../all/add-self-work-modal"
@import "../all/teacher-lesson-modal"
@import "../all/pupil-details-modal"
@import "../all/comment-list-modal"
@import "../all/task-creator-modal"
@import "../all/add-or-edit-variable-modal"
@import "../all/add-generation-to-task-modal"
@import "../all/edit-personal-study-modal"
@import "../all/chat"
@import "../all/diploma-pay-modal"
@import "../all/structure-chart"

@import "../all/teacher-page"
@import "../all/pupil-list-dashboard"
@import "../all/rating-dashboard"
@import "../all/task-list-dashboard"
@import "../all/high-view-dashboard"
@import "../all/realtime-dashboard"
@import "../all/control-works-dashboard"
@import "../all/self-works-dashboard"
@import "../all/constructor-dashboard"
@import "../all/admin-dashboard"
@import "../all/personal-study-dashboard"
@import "../all/sales-tv-dashboard"
@import "../all/unt-button"
@import "../all/unt-input"
@import "../all/sbb-tv"
@import "../all/matching-task"
@import "../all/game-item"
@import "../all/draggable"
@import "../all/new-pupil-call"
@import "../all/kanban-board"

.lesson-theory
  font-size: 1rem
  font-weight: 300
  margin-top: 2rem

.loader-indicator
  display: flex
  flex-direction: column
  flex-grow: 1
  flex-shrink: 0
  align-items: center
  justify-content: center
  margin-top: 1rem

.page
  max-width: 1366px
  margin: 0 auto
  overflow: hidden

.time-left
  display: flex
  align-items: center
  font-size: 10.5px
  line-height: 14px
  color: #8f8d8d
  margin-right: 0.5rem
  margin-left: 4px


