.rating-page
  .title
    display: flex
    margin: 24px auto 10px auto

    span
      font-size: 24px
      font-weight: bold

    .golden-star
      background-image: url(../../images/ic-golden-star.png)
      background-size: contain
      width: 28px
      height: 28px


  .filter
    display: flex
    font-size: 15px
    line-height: 24px
    color: #727272
    align-self: center
    align-items: center
    padding-top: 20px
    padding-bottom: 21px
    background-color: #F8F8F8
    width: 100%
    justify-content: center

    .filter-text
      font-weight: 500
      text-transform: capitalize
      margin-right: 26px

    .filter-by-place
      display: flex

    .filter-by-grade
      display: flex

    .radio-label
      text-transform: lowercase
      margin-right: 20px

      .uk-radio
        margin-right: 8px

  .points-info
    width: 100%
    height: 55px
    background: #F1F1F1
    border-radius: 10px 10px 0 0
    display: flex
    align-items: center
    justify-content: center

    span
      font-size: 15px
      line-height: 24px
      color: #727272

      & + span::before
        content: "|"
        margin-left: 10px
        margin-right: 10px

  .user-rating-info
    width: 100%
    background: #F1F1F1
    display: flex
    align-items: center
    justify-content: center
    margin-top: -12px
    flex-direction: column

  .uk-overflow-auto
    margin-bottom: 2rem

  .my-table
    .left
      padding-left: 78px

    .place1
      background-image: url(../../images/olympiad_1_place.png)
      background-size: contain
      width: 45px
      height: 45px
      margin: auto

    .place2
      background-image: url(../../images/olympiad_2_place.png)
      background-size: contain
      width: 45px
      height: 45px
      margin: auto

    .place3
      background-image: url(../../images/olympiad_3_place.png)
      background-size: contain
      width: 45px
      height: 45px
      margin: auto

  .battery
    display: flex
    background-color: #02F84F
    border-radius: 12px
    width: 86px
    height: 34px
    margin: auto

    span
      font-size: 16px
      font-weight: bold
      margin: auto

@media screen and (max-width: 600px)
  .rating-page
    .filter
      .filter-text
        font-weight: bold
        text-transform: uppercase

      .radio-label
        margin-top: 1rem

      .filter-by-place
        display: flex
        flex-wrap: wrap
        padding-left: 1rem

      .filter-by-grade
        padding-left: 1rem

    .points-info
      display: flex
      flex-direction: column
      height: 120px

      span::after, span::before
        content: "|"
        margin-left: 10px
        margin-right: 10px

    .user-rating-info
      margin-bottom: 4px

    .my-table
      th
        font-size: 14px
        font-weight: bold

      td
        font-size: 12px

      .left
        text-align: center!important


    .battery
      background-color: #02F84F
      border-radius: 12px
      width: 70px
      height: 30px
      margin: auto

      span
        font-size: 16px
        font-weight: bold
        margin: auto
