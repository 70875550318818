.latex-symbols-popup
  position: relative
  display: flex
  width: 325px
  justify-content: space-between
  flex-wrap: wrap
  margin: 0 auto 6px
  outline: none
  max-width: $latex-symbols-popup-max-width

  &.touch-device
    width: 100%

  .latex-symbol-wrapper
    height: auto
    margin-bottom: 5px
    cursor: pointer

.calculator-popup
  position: absolute
  top: -33px
  left: -4px
  z-index: 100
  height: 55px
  pointer-events: all

@media screen and (max-width: 600px), screen and (max-height: 600px)
  .latex-symbols-popup
    width: 100%
    margin-top: 0
