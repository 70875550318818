.confirmation-modal
  padding: 1rem
  display: flex
  flex-direction: column
  align-items: center

  .question
    margin-top: 2rem

  .decision-buttons
    margin-top: 2rem

    .confirm-button
      margin-right: 8px

