$cell-width: 70px
$lesson-title-cell-width: 300px
$cell-margin-right: 8px
$table-header-margin-left: $lesson-title-cell-width + 84px

.high-view-dashboard
  display: flex
  flex-direction: column
  align-items: center

  .filter-container
    display: flex
    align-items: center
    justify-content: center
    margin-bottom: 2rem

    .show-button
      margin-left: 14px
      width: 213px
      padding: 6px
      font-size: 14px

  .ic-collapse-expand
    background-image: url(../../images/ic-collapse-expand.svg)
    transform: rotate(-90deg)
    width: 16px
    height: 16px
    &.expanded
      transform: none

  .custom-scrollbars-holder
    position: relative
    height: calc(100vh - 588px)
    width: 100%

  .navbar
    display: flex
    flex-direction: column
    width: calc(100% - 60px)

    .tab-list
      display: flex

      .tab-item
        width: 215px
        background: #FAFAFA
        border: 1px solid #D3D3D3
        box-sizing: border-box
        border-radius: 30px 30px 0 0
        padding: 10px 0 40px
        text-align: center
        margin: 0 -8px
        cursor: pointer

        &.selected
          background: #FFFFFF

    .tab-item-content
      background: #FFFFFF
      width: calc(100% + 16px)
      margin-left: -8px
      border: 1px solid #D3D3D3
      box-sizing: border-box
      border-radius: 30px
      margin-top: -30px
      padding-bottom: 30px

      .chapter-item
        display: flex
        flex-direction: column

        .chapter-title
          font-weight: bold
          font-size: 17px
          line-height: 24px
          color: #2F5F8C
          border-bottom: 1px solid #D3D3D3
          padding-top: 21px
          padding-bottom: 14px
          padding-left: 34px

        .chapter-content
          display: flex
          flex-direction: column
          padding-left: 20px

          .unit-item
            display: flex

            .unit-left-part
              width: 350px
              flex-shrink: 0

              .unit-title
                font-weight: bold
                font-size: 16px
                line-height: 24px
                color: #353535
                text-align: center
                padding: 22px 0

              .unit-lesson-list
                display: flex
                flex-direction: column
                border: 1px solid #D3D3D3
                border-right: none
                border-bottom: none

                .lesson-title
                  border-bottom: 1px solid #D3D3D3
                  padding: 0 1rem
                  height: 50px
                  display: flex
                  align-items: center

                  span
                    font-size: 15px
                    line-height: 18px
                    color: #6D6D6D

            .unit-right-part
              display: flex
              flex-direction: column
              padding-top: 11px
              overflow: auto
              -webkit-overflow-scrolling: touch

              .pupil-list
                .pupil-item
                  font-size: 14px
                  line-height: 18px
                  color: #6D6D6D
                  min-width: 113px
                  padding-bottom: 20px
                  text-align: center

                  span
                    display: block

              .lesson-list-table
                border-spacing: 0

                thead
                  tr
                    td
                      border-bottom: 1px solid #D3D3D3

                tbody
                  tr
                    height: 51px

                    &:last-child
                      td
                        border-bottom: 1px solid #D3D3D3

                .lesson-results
                  font-size: small
                  padding: 0
                  background-color: #FFFFFF

                  &:nth-child(2n-1)
                    background-color: #F7F7F7

                .pupil-lesson-container
                  display: flex
                  justify-content: center

                  &.pupil-control-work-container
                    flex-basis: 100%

                  .results-container
                    position: absolute
                    border-radius: 5px
                    background-color: white
                    border: 1px solid $border-grey-2
                    padding: 4px
                    z-index: 101
                    width: 68px

                    .lesson-task-btn
                      cursor: pointer
                      display: flex
                      flex-shrink: 0
                      margin: 2px 0
                      align-items: center

                  .result-item
                    box-sizing: border-box
                    display: flex
                    align-items: center
                    justify-content: center
                    cursor: pointer
                    color: #FFFFFF
                    width: 86px
                    height: 34px

                    &.regular-result-item
                      background: url("../../images/new-regular-battery.svg")
                      color: #353535
                      span
                        width: 100%
                        text-align: right
                        padding: 0 10px

                    &.control-work-result-item
                      background: url("../../images/new-empty-battery.svg")
                      color: #353535

                    //&.multiple
                      //border-style: dashed
                    &.completed
                      background-color: #0BC509
                      color: #FFFFFF
                      border: 1px solid white
                      border-radius: 5px
                      width: 40px
                      height: 28px
                    &.passed
                      color: #FFFFFF
                      background: url("../../images/new-regular-battery-passed.svg")
                      width: 85px
                      height: 32px
                    &.control-work-passed
                      background: url("../../images/new-control-work-battery.svg")
                      color: #FFFFFF



