.rating-dashboard
  display: flex
  flex-direction: column
  align-items: center

  .filter-container
    display: flex
    align-items: center
    margin-bottom: 2rem

    .show-button
      margin-left: 14px
      width: 213px
      padding: 6px
      font-size: 14px

  .uk-overflow-auto
    width: 100%



