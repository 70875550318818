.constructor-dashboard
  display: flex
  flex-direction: column

  .icon
    cursor: pointer

  .ic-cancel
    background-image: url(../../images/ic-cancel.svg)
    width: 19px
    height: 19px

  .ic-restore
    background-image: url(../../images/ic-restore.svg)

  .my-table
    margin-top: 1rem

    th:last-child, td:last-child
      text-align: center
      padding-right: 15px
      width: 1%
      white-space: nowrap
      padding-left: 15px

