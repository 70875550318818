._video-iframe
  width: 100%
  height: 450px

._prize-cnt
  margin: 0 auto
  display: grid
  justify-content: center
  grid-template-columns: 440px 440px 440px
  gap: 40px

._about-olympiad-cnt
  margin: 0 auto
  display: grid
  justify-content: center
  grid-template-columns: 1fr 1fr 1fr
  gap: 40px

._about-olympiad-div
  max-width: 816px

._about-olympiad-div-h3
  display: flex
  margin: 0 0 8px 0

._mark-olympiad
  transform: rotate(2deg)
  max-height: 26px
  margin-left: 12px
  padding: 4px 8px
  background: #00BE75
  display: flex
  align-items: center
  border-radius: 100px

._mark-olympiad2
  transform: rotate(2deg)
  max-height: 26px
  margin-left: 12px
  padding: 4px 8px
  background: #FFD500
  display: flex
  align-items: center
  border-radius: 100px

._mark-olympiad-p2
  font-weight: 600
  font-size: 16px
  color: #2B2C34
  line-height: 22.4px
  margin: 0

._white-dot
  margin-right: 4px
  width: 4px
  height: 4px
  background: #FFFFFF
  border-radius: 100px

._mark-olympiad-p
  font-weight: 600
  font-size: 16px
  color: #FFFFFF
  line-height: 22.4px
  margin: 0

._about-olympiad-div2
  grid-column: span 3
  background: #F1F3F4
  border-radius: 24px
  padding: 24px
  display: flex

._about-olympiad-h3
  font-weight: 700
  font-size: 24px
  color: #2B2C34
  line-height: 33.6px
  margin: 0

._about-olympiad-p
  font-weight: 400
  font-size: 20px
  color: #2B2C34
  line-height: 28px
  margin: 0

  span
    font-weight: 700

._info-cards-h100
  grid-column: span 3

._info-cards-h100
  grid-column: span 3

._info-cards-h33
  color: #1A83FF
  font-weight: 700
  font-size: 54px
  line-height: 66px
  margin: 40px 0 0 0

._info-cards-h99
  grid-column: span 3

._prize-first-img
  background: url("../../images/landing/prizes-1.jpg")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  border-radius: 12px
  width: 440px
  height: 246px

._prize-second-img
  background: url("../../images/landing/prizes-2.jpg")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  border-radius: 12px
  width: 440px
  height: 246px

._prize-third-img
  background: url("../../images/landing/prizes-3.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  border-radius: 12px
  width: 440px
  height: 246px

._almaty-img
  background: url("../../images/landing/almaty-img.png")
  background-size: cover
  background-repeat: no-repeat
  width: 520px
  height: 260px
  margin-right: 16px

._tier-list-cnt
  margin: 0 auto
  display: grid
  justify-content: center
  grid-template-columns: 440px 440px 440px
  gap: 40px

._info-cards-h67
  grid-column: span 3

._tier-list-class
  border-radius: 12px
  background: #F1F3F4
  padding: 16px

._tier-list-class-h3
  color: #2B2C34
  font-size: 28px
  font-weight: 700
  line-height: 39.2px
  margin: 0 0 8px 0

._tier-list-class-cnt
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 8px

._tier-list-class-medal
  display: flex

._tier-list-class-first-img
  background: url("../../images/landing/1place.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  width: 32px
  height: 32px
  margin-right: 8px

._tier-list-class-second-img
  background: url("../../images/landing/2place.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  width: 32px
  height: 32px
  margin-right: 8px

._tier-list-class-third-img
  background: url("../../images/landing/3place.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  width: 32px
  height: 32px
  margin-right: 8px

._tier-list-class-cnt3
  background: #F1F3F4
  border-radius: 20px
  position: relative
  padding: 38px 24px 38px 223px
  grid-column: span 2


._all-tier-list-cnt-h3
  color: #000000
  margin: 0 0 12px 0
  font-size: 28px
  font-weight: 600
  line-height: 39.2px

._all-tier-list-cnt-a
  border-radius: 12px
  display: inline-block
  background: #1A83FF
  padding: 28px 40px
  color: #FFFFFF
  margin: 0
  font-size: 20px
  font-weight: 600
  line-height: 20px

._all-tier-list-cnt-a:hover
  color: #FFFFFF
  background: #0471f6

._phone-olympiad-prev-img
  background: url("../../images/landing/phone.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  position: absolute
  left: 0
  bottom: 0
  width: 223px
  height: 212px

._tier-list-class-p
  margin: 0
  color: #2B2C34
  font-size: 20px
  font-weight: 600
  line-height: 28px

._tier-list-class-p2
  margin: 0
  color: #949FAC
  font-size: 20px
  font-weight: 500
  line-height: 28px

._steps-cnt
  margin: 0 auto
  display: grid
  justify-content: center
  grid-template-columns: 680px 680px
  gap: 40px

._info-cards-h90
  grid-column: span 2

._steps-img1
  background: url("../../images/landing/stepone.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  width: 680px
  height: 380px
  margin-bottom: 20px

._steps-img3
  background: url("../../images/landing/stepthree.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  width: 680px
  height: 380px
  margin-bottom: 20px

._steps-img2
  background: url("../../images/landing/steptwo.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  position: relative
  width: 680px
  height: 380px
  margin-bottom: 20px

._steps-img4
  background: url("../../images/landing/stepfour.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  position: relative
  width: 680px
  height: 380px
  margin-bottom: 20px

._steps-img2-cnt
  background: url("../../images/landing/step-2-img.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  position: absolute
  top: -35px
  left: calc(50% - 270px)
  width: 540px
  height: 450px
  margin-bottom: 20px

._steps-img4-cnt
  background: url("../../images/landing/step-4-img.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  position: absolute
  top: -35px
  left: calc(50% - 270px)
  width: 540px
  height: 450px
  margin-bottom: 20px

._ai-warning_container
  margin-top: 20px
  padding: 48px
  border-radius: 16px
  background: #F1F3F4

._ai-warning-p
  color: #2B2C34
  font-size: 24px
  line-height: 33.6px
  font-weight: 600
  margin: 0

._faq-h3
  text-align: center
  color: #1A83FF
  font-size: 54px
  line-height: 66.85px
  font-weight: 700
  margin: 40px 0 0 0

._ask
  margin: 20px 0
  background: #F1F3F4
  border-radius: 16px
  padding: 20px

._ask-h3
  color: #1A83FF
  font-weight: 700
  font-size: 20px
  line-height: 32px
  margin: 0

._ask-cnt
  margin-top: 40px

._ask-p
  color: #2B2C34
  font-weight: 500
  font-size: 16px
  line-height: 22.4px
  margin: 0 0 5px 0

._main-oplympiad-img
  background: url("../../images/landing/main-olympiad-img.png")
  background-size: contain
  background-repeat: no-repeat
  background-position: center
  position: absolute
  border-radius: 100px
  right: -140px
  top: 120px
  width: 1104px
  height: 733px

._main_title2
  margin: 150px 0

@media (max-width: 2000px)
  ._main-oplympiad-img
    right: -180px
    top: 120px

@media (max-width: 1800px)
  ._main-oplympiad-img
    right: -220px
    top: 120px

@media (max-width: 1700px)
  ._main-oplympiad-img
    right: -280px
    top: 120px

@media (max-width: 1600px)
  ._main-oplympiad-img
    margin-top: 50px
    border-radius: 100px 100px 0 0
    position: relative
    right: 0
    top: 0

  ._main_title2
    margin: 0

@media (max-width: 780px)
  ._info-cards-h33
    color: #2B2C34
    font-size: 24px
    line-height: 33px
    margin: 50px 0 0 0

  ._main-oplympiad-img
    border-radius: 30px 30px 0 0
    width: 522px
    height: 348px
    position: relative
    right: 0
    top: 0

@media (max-width: 1435px)
  ._faq-h3
    font-size: 24px
    line-height: 38.4px

  ._prize-cnt
    grid-template-columns: 343px 343px 343px
    gap: 20px

  ._prize-first-img
    width: 343px
    height: 192px

  ._prize-second-img
    width: 343px
    height: 192px

  ._prize-third-img
    width: 343px
    height: 192px

  ._tier-list-cnt
    grid-template-columns: 40% 40%

  ._info-cards-h67
    grid-column: span 2

  ._tier-list-class-cnt3
    padding: 15px 24px 15px 24px
    grid-column: span 1

  ._phone-olympiad-prev-img
    display: none

  ._tier-list-class-p2
    font-size: 14px

  ._steps-cnt
    grid-template-columns: 680px

  ._info-cards-h90
    grid-column: span 1

  ._ai-warning_container
    padding: 16px
    border-radius: 0

@media (max-width: 1100px)
  ._about-olympiad-cnt
    grid-template-columns: 1fr
    gap: 20px

  ._about-olympiad-div-h3
    display: flex
    margin: 0 0 8px 0

  ._about-olympiad-div2
    flex-direction: column-reverse
    grid-column: span 1

  ._almaty-img
    border-radius: 12px
    margin: 8px 0 0 0

  ._info-cards-h100
    grid-column: span 1

  ._prize-cnt
    grid-template-columns: 367px 367px
    gap: 20px

  ._prize-first-large
    grid-column: span 2

  ._prize-first-img
    width: 754px
    height: 421px

  ._prize-second-img
    width: 367px
    height: 206px

  ._prize-third-img
    width: 367px
    height: 206px

  ._info-cards-h99
    grid-column: span 2

@media (max-width: 875px)
  ._tier-list-cnt
    grid-template-columns: 100%
    gap: 20px

  ._info-cards-h67
    grid-column: span 1

@media (max-width: 810px)
  ._prize-cnt
    grid-template-columns: 350px 350px
    gap: 20px

  ._info-cards-h99
    grid-column: span 2

  ._prize-first-large
    grid-column: span 2

  ._prize-first-img
    width: 714px
    height: 400px

  ._prize-second-img
    width: 350px
    height: 195px

  ._prize-third-img
    width: 350px
    height: 195px

@media (max-width: 780px)
  ._prize-cnt
    grid-template-columns: 343px 343px
    gap: 20px

  ._info-cards-h99
    grid-column: span 2

  ._prize-first-large
    grid-column: span 2

  ._prize-first-img
    width: 706px
    height: 396px

  ._prize-second-img
    width: 343px
    height: 192px

  ._prize-third-img
    width: 343px
    height: 192px

@media (max-width: 725px)
  ._steps-cnt
    grid-template-columns: 343px
    gap: 20px

  ._steps-img1
    width: 343px
    height: 193px

  ._steps-img2
    width: 343px
    height: 193px

  ._steps-img3
    width: 343px
    height: 193px

  ._steps-img4
    width: 343px
    height: 193px

  ._steps-img2-cnt
    top: -65px
    left: calc(50% - 145px)
    width: 290px
    height: 320px

  ._steps-img4-cnt
    top: -65px
    left: calc(50% - 145px)
    width: 290px
    height: 320px

  ._prize-cnt
    grid-template-columns: 343px
    gap: 20px

  ._info-cards-h99
    grid-column: span 1

  ._prize-first-large
    grid-column: span 1

  ._prize-first-img
    width: 343px
    height: 192px

  ._prize-second-img
    width: 343px
    height: 192px

  ._prize-third-img
    width: 343px
    height: 192px

@media (max-width: 610px)
  ._about-olympiad-h3
    font-size: 16px
    line-height: 22.4px

  ._about-olympiad-p
    font-size: 14px
    line-height: 19.6px

  ._almaty-img
    background-size: contain
    background-position: center
    width: 343px
    height: 192px

  ._about-olympiad_container
    padding: 0

  ._info-cards-h100
    margin-left: 16px

  ._about-olympiad-div
    padding-left: 16px
    padding-right: 16px

  ._about-olympiad-div2
    padding: 16px

    ._about-olympiad-div
      padding-left: 0
      padding-right: 0



