.task-modal
  .modal-body
    align-items: center

.task-modal, .lesson-theory-modal, .user-action-modal, .user-action-popup, .add-teacher-lesson-modal, .pupil-details-modal, .comment-list-modal
  color: $black
  border-radius: 20px
  display: flex
  flex-direction: column
  height: 100%

  .part-item
    width: 100%
    background-color: lightslategrey
    border-radius: 10px
    padding: 10px
    font-weight: bold
    color: white
    text-align: center
    cursor: pointer

    &.active
      background: $default-background

    &:hover
      background-color: #2C98F0

  .lesson-item
    padding: 3px 0
    font-weight: normal
    display: flex
    align-items: center
    font-size: 14px

    .left-part
      display: flex
      justify-content: flex-end
      flex-basis: 50%
      padding-right: 9px

      .lesson-title
        cursor: pointer
        text-align: left
        padding: 9px 2rem
        background: #FFFFFF
        border: 1px solid #D3D3D3
        box-sizing: border-box
        border-radius: 20px
        width: 100%
        min-width: 200px

        &:hover
          background: linear-gradient(to bottom, #FBFBFB, #EBEBEB)

      .lesson-title-non-clickable
        cursor: default

        &:hover
          background: transparent

    .right-part
      display: flex
      justify-content: flex-start
      flex-basis: 50%
      margin-left: 9px

      .regular-lesson-battery
        margin-right: 0
        display: flex
        align-items: center
        flex-shrink: 0

  .modal-header
    display: flex
    justify-content: center
    align-items: center
    background: #F7F7F7
    position: relative
    height: 50px
    flex-shrink: 0

    .theory-button
      margin-left: 10px

    .modal-title
      font-size: 14px
      margin: 0 auto
      display: flex
      line-height: 24px
      color: #B6B6B6

    .header-right
      position: absolute
      right: 0
      display: flex
      align-items: center
      float: right

      .task-attempt-list
        display: flex

        .task-attempt-item
          height: 20px
          width: 20px
          background-color: #FF5555
          mask-size: contain
          mask-repeat: no-repeat
          mask-image: url("../../images/ic-heart.svg")
          margin-left: 4px

          &.error
            background-color: #D3D3D3

  .modal-body
    display: flex
    flex-direction: column
    padding: 1.5rem
    overflow-x: auto

    .display-theory-block-button
      display: flex
      width: 85%
      background-color: white
      border-radius: 20px
      align-items: center
      justify-content: space-between
      top: auto
      bottom: auto
      padding: 8px 16px

      div
        width: 24px
        height: 24px

      .arrow-top
        background: url("../../images/ic-chevron-up.svg")

      .arrow-bottom
        background: url("../../images/ic-chevron-down.svg")

    .task-title
      font-size: 16px
      line-height: 1rem
      margin: 0 auto 1rem
      font-family: MJXc-TeX-main-R, MJXc-TeX-main-Rw, "Times New Roman", serif

    .task-description-with-answer-input
      margin: 0 auto

    .error-message
      margin: 0 auto 1rem

    .equation-task-description
      display: flex
      flex-direction: column
      align-items: center

    .task-text-description
      max-width: 600px
      display: flex
      flex-direction: column
      align-items: center

    .task-text-inline-description
      display: flex
      line-height: 2
      align-items: baseline

    .audio-task-description
      .white
        background-color: gray
        width: 100%

    .task-complex-description
      max-width: 600px
      min-width: 600px
      display: flex
      flex-direction: column
      align-items: center

      .task-complex-description-image-repeatable
        border-color: white
        background: none
        color: white
        border-style: none
        width: 100px
        height: 100px
        cursor: pointer

      .task-complex-description-image-clicked
        background-color: white
        border-radius: 15px
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2)

      .task-complex-description-image-text
        position: relative
        top: -130px,
        border: 2px solid red
        border-radius: 50%
        width: 24px
        height: 24px
        display: flex
        justify-content: center
        align-items: center
        color: red
        font-weight: bold
        left: 40%


    .test-task-description
      max-width: 800px

      .audio-listen
        background-image: url("../../images/ic-audio-listen.svg")
        background-repeat: no-repeat
        background-position: center
        min-height: 60px
        border: none
        padding: 62px

      .multiple-choice-solution-index
        position: relative
        top: -180
        left: 40%
        border: 2px solid red
        border-radius: 50%
        width: 24px
        height: 24px
        display: flex
        justify-content: center
        align-items: center
        color: red
        font-weight: bold


    .task-description
      font-family: MJXc-TeX-main-R, MJXc-TeX-main-Rw, "Times New Roman", serif
      font-size: 18px

    .box-container
      .trash-icon
        background-image: url("../../images/ic-trash.png")
        background-repeat: no-repeat
        background-position: center
        min-height: 60px

    .answer-cell-task-description
      display: flex
      flex-direction: column

    .table-task-description
      margin-bottom: 1rem

      .table-task-content
        max-width: 600px

    .calculate-by-image-task-description
      max-width: 600px

      .trash-icon
        background-image: url("../../images/ic-trash.png")
        background-repeat: no-repeat
        background-position: center
        min-height: 60px

    .frequency-polygon-task-description
      .trash-icon
        background-image: url("../../images/ic-trash.png")
        background-repeat: no-repeat
        background-position: center
        min-height: 60px
        max-width: 60px

    .frequency-histogram-task-description
      .trash-icon
        background-image: url("../../images/ic-trash.png")
        background-repeat: no-repeat
        background-position: center
        min-height: 60px
        max-width: 60px

    .coloring-task-description
      .trash-icon
        background-image: url("../../images/ic-trash.png")
        background-repeat: no-repeat
        background-position: center
        min-height: 60px

    .image-task-description
      .trash-icon
        background-image: url("../../images/ic-trash.png")
        background-repeat: no-repeat
        background-position: center
        min-height: 60px

    .matching-with_dragging-task-description
      .drag-n-drop-image
        display: flex
        flex-direction: row
        justify-content: flex-end

        .drop-image
          width: max-content
          min-height: 20px
          min-width: 20px
          display: flex
          justify-content: center
          align-items: center
          height: max-content

      .drag-n-drop-row
        display: flex
        flex-direction: row
        justify-content: flex-end

        .drop-place
          width: max-content
          min-height: 30px
          min-width: 30px
          background: #f7f8fa
          display: flex
          justify-content: center
          align-items: center
          height: max-content
          border: 1px solid black

      .drag-n-drop
        display: flex
        flex-direction: column
        justify-content: flex-end

        .drop-place
          width: max-content
          min-height: 150px
          min-width: 150px
          background: #f7f8fa
          display: flex
          justify-content: center
          align-items: center
          height: max-content
          border: 1px solid black

          .task-complex-description-image-clicked
            background-color: white
            border-radius: 15px
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2)

      .drag-n-drop2
        display: flex
        flex-direction: column
        justify-content: flex-start

        .drop-place
          width: max-content
          min-height: 150px
          min-width: 150px
          background: #f7f8fa
          display: flex
          justify-content: center
          align-items: center
          height: max-content
          border: 1px solid black

        .drop-place-with-image
          width: max-content
          min-height: 40px
          min-width: 40px
          background: #f7f8fa
          display: flex
          justify-content: center
          align-items: center
          height: max-content
          border: 1px solid black

    .text-type-answer-input
      display: flex
      flex-wrap: wrap
      justify-content: center
      align-items: center
      margin-top: 1rem

      &.multiple
        align-items: center

      .your-answer-text
        font-size: 18px
        line-height: 24px
        text-align: center
        color: #B6B6B6
        margin-right: 22px

    .multiple-choice-figures-container
      display: flex
      flex-direction: row
      flex-wrap: wrap
      align-items: center
      justify-content: center
      max-width: 600px

      &.figures-vertical
        flex-direction: column

    .multiple-choice-variant
      display: flex
      align-items: baseline

      .uk-checkbox
        flex-shrink: 0

      .horizontal-variant
        border: 3px solid #0071BC
        cursor: pointer
        padding: 5px 0
        //font-family: Georgia
        font-size: 30px
        width: 150px
        text-align: center

    .video-explanation-iframe
      width: 100%
      max-width: 600px
      height: 300px
      margin-top: 20px

    .task-hint
      display: flex
      flex-direction: column
      margin: 0 auto

    .task-table
      overflow-x: auto
      width: 100%

      table, th, td
        border-collapse: collapse
        border: 1px solid $grey

      table.remove-borders
        border: none

        td
          border: none

      table.remove-padding
        td
          padding: 0


    .task-solution
      display: flex
      flex-direction: column
      margin: 1rem auto 0
      align-items: center

      .task-solution-answer
        display: flex
        margin: 0 auto
        align-items: baseline

        &.multiple
          align-items: center

        .answer-label
          font-weight: 600
          margin-right: 8px

        .answer-input-label
          margin-left: 4px
          margin-right: 4px
          color: $grey
          font-size: 14px
          display: flex
          align-items: center

          & + span
            display: flex
            align-items: center

      .task-solution-content
        margin-top: 8px
        font-family: MJXc-TeX-main-R, MJXc-TeX-main-Rw, "Times New Roman", serif
        font-size: 18px

    .multiline-task-solution
      display: flex
      flex-direction: column
      margin-bottom: 4px

    .task-complex-solution
      min-width: 600px
      max-width: 700px
      display: flex
      flex-direction: column
      align-items: center

      .html-image
        position: relative
        top: -180
        left: 40%
        border: 2px solid red
        border-radius: 50%
        width: 24px
        height: 24px
        display: flex
        justify-content: center
        align-items: center
        color: red
        font-weight: bold

      .text
        margin-bottom: 5px
        margin-top: 5px
        width: 100%

    .calculate-by-image-task-solution-image
      width: 100%
      height: 100%
      display: flex
      justify-content: center
      align-items: center

    .calculate-by-image-task-solution-content
      position: absolute
      top: 50%
      left: 50%
      transform: translate(-50%, -50%)
      border-radius: 50%
      padding: 5px
      color: white

    .answer-input-container
      display: inline-flex
      align-items: center
      margin: 4px 0

      .answer-input-before
        margin-right: 4px
        display: flex
        align-items: center
        font-size: 14px
        color: #6a6a6a

      .answer-input-after
        margin-left: 4px
        margin-right: 4px
        display: flex
        align-items: center
        font-size: 14px
        color: #6a6a6a

        &.is-degree-sign
          color: black
          font-size: 20px
          align-self: baseline
          margin-left: 1px

      .answer-input-up
        align-items: center
        font-size: 14px
        color: #6a6a6a

      .answer-input-down
        align-items: center
        font-size: 14px
        color: #6a6a6a

    .answer-comparison-input-container
      display: flex
      flex-direction: column

    .ic-calculator
      height: 27px
      width: 19px
      cursor: pointer
      background: url(../../images/ic-calculator.svg) no-repeat
      background-size: 100%

      &:hover, &.active
        background-image: url(../../images/ic-calculator-active.svg)

      &.large
        height: 40px
        width: 29px
        align-self: flex-start

    .tool-choosing-container
      max-width: $latex-symbols-popup-max-width
      align-self: center

    .ic-hand-touch
      height: 40px
      width: 29px
      cursor: pointer
      background: url(../../images/ic-hand-touch.svg) no-repeat
      background-size: 100%
      align-self: flex-start

    .ic-redo, .ic-undo, .ic-trash, .ic-eraser, .ic-pencil, .ic-dashed-solid
      height: 25px
      width: 25px
      cursor: pointer
      border-radius: 5px
      border: 1px solid $border-grey-2
      background-size: 100%
      background-repeat: no-repeat

    .ic-redo
      background-image: url(../../images/ic-redo.png)

    .ic-undo
      background-image: url(../../images/ic-undo.png)

    .ic-trash
      background-image: url(../../images/ic-trash.png)

    .ic-eraser
      background-image: url(../../images/ic-eraser.png)

    .ic-pencil
      background-image: url(../../images/ic-pencil.png)

    .ic-dashed-solid
      background-image: url(../../images/ic-dashed-solid.png)

    .action-btn-list
      display: flex
      align-items: center
      margin: 1rem auto 0

      .left-buttons, .right-buttons
        display: flex
        flex-direction: column

      .button
        min-width: 170px
        width: 220px
        max-width: 220px
        padding-left: 0
        padding-right: 0
        margin: 0 7px
        min-height: 26px


    .dot-list
      display: flex
      flex-wrap: wrap
      margin: 2rem auto 0
      align-items: center

      .dot
        background-color: grey
        width: 1rem
        height: 1rem
        border-radius: 50%
        margin-left: 4px

      .dot-answered
        background-color: $blue

      .dot-active
        width: 1.5rem
        height: 1.5rem
        display: flex
        justify-content: center
        align-items: center

      .dot-penalty
        @include penalty-icon("grey")

      .dot-show-solution
        background-color: #960018

        &.dot-penalty
          @include penalty-icon("#960018")

      .dot-show-video-explanation
        background-color: orange

        &.dot-penalty
          @include penalty-icon("orange")

      .dot-error
        background-color: red

        &.dot-penalty
          @include penalty-icon("red")

      .dot-success
        background-color: green

        &.dot-penalty
          @include penalty-icon("green")

  .modal-footer
    margin-top: auto
    margin-bottom: 8px
    display: flex

  .overlay
    content: ''
    display: block
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    background-color: white
    opacity: 0.9
    z-index: 50
    border-radius: 5px
    pointer-events: all

  .comparison-symbols-popup
    display: flex
    flex-direction: column
    margin-bottom: 1rem

    .comparison-latex-symbol
      border-radius: 5px
      border: 1px solid $border-grey
      text-align: center
      cursor: pointer

  .handwriting-recognition-image-before
    border-radius: 15px
    border: 1px solid #40c8e3
    margin-top: 15px

  .handwriting-recognition
    display: flex
    flex-direction: column
    position: relative

    .background-image
      top: 30%
      left: 40%
      position: absolute
      opacity: 50%

    .input-controls
      top: -40px
      right: 0
      position: absolute
      display: flex

      .input-control-item
        width: 40px
        cursor: pointer

    .drawing-canvas
      cursor: pointer
      border: 1px solid $border-grey
      border-radius: 5px
      margin: 0 auto

    .canvas-controls
      margin-top: 28px
      display: flex
      justify-content: center
      z-index: 100

      .icon
        width: 35px
        height: 35px
        margin: 0 4px
        font-size: 12px

    .set-answer-buttons
      margin-top: 3px
      display: flex
      justify-content: center
      z-index: 100

      .uk-button-primary
        margin-left: 4px

  .coordinate-plane
    .drawing-figures-chooser
      position: relative
      display: flex

      .add-figure-button
        padding-left: 1rem
        padding-right: 1rem
        font-size: 1rem
        font-weight: bold

      .drawing-figures-chooser-popup
        position: absolute
        bottom: 0
        left: 42px
        box-sizing: border-box
        z-index: 100
        border: 1px solid $border-grey
        border-radius: 5px
        background-color: white

        .drawing-figures-item
          cursor: pointer
          border-bottom: 1px solid $border-grey
          padding: 8px

          &:hover
            background-color: $blue
            color: white


  .audio-button
    cursor: pointer

    .ic-audio
      mask-image: url("../../images/ic-audio-icon.svg")
      width: 20px
      height: 20px
      mask-size: 100%
      flex-shrink: 0
      background-color: black


    &:hover
      .ic-audio
        background-color: black

    .mini-loading
      background-image: url("../../images/gif-mini-loading.gif")
      background-size: 100%
      width: 20px
      height: 20px
      mask-size: 100%
      flex-shrink: 0

  .disabled-button
    pointer-events: none
    cursor: not-allowed

  .max-width
    max-width: 400px

@media screen and (max-width: 600px), screen and (max-height: 600px)
  .ReactModal__Content--after-open
    .modal-header
      .modal-title
        margin-left: 1rem

      .task-attempt-item
        font-size: 14px !important
        height: 20px !important

      .theory-button
        margin-right: auto

    .modal-body
      padding: 8px

      .task-complex-description, .task-complex-solution
        min-width: initial

      .video-explanation-iframe
        height: 200px

      .task-complex-solution
        width: 350px

        .task-table td
          padding: 8px 4px

    .action-btn-list
      margin-bottom: 0 !important
      display: flex
      width: 100%
      justify-content: center

      .button
        margin: 3px !important
        width: 50% !important

    .ic-calculator
      height: 40px !important
      width: 30px !important

@media screen and (max-width: 600px), screen and (max-height: 600px), (hover: none) and (pointer: coarse)
  .ReactModal__Content--after-open
    .answer-input-container
      font-size: 24px

    .answer-comparison-input-container
      font-size: 24px

      .mq-editable-field
        padding: 0 8px !important
        border-radius: 8px

    .answer-column-input-container
      font-size: 24px

      .mq-editable-field
        padding: 0 8px !important
        border-radius: 8px
