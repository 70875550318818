.warning-text
  font-size: 20px
  color: red

.warning-text-mobile
  font-size: 17.5px
  color: red

.personal-study-page
  .explanation-text
    display: flex
    justify-content: center
    margin-bottom: 1rem
    align-items: center

  .cashback-info-text
    display: flex
    flex-direction: column
    justify-content: center
    margin-bottom: 1rem
    align-items: center

  .add-button-column
    width: 250px

  .battery-cell
    .battery-wrapper
      display: flex
      justify-content: center

  .freeze-status
    mask-image: url("../../images/ic-freeze-status.svg")
    width: 30px
    height: 30px
    mask-size: 100%
    flex-shrink: 0
    background-color: #2893ea

  .filter
    display: flex
    justify-content: space-around

    .radio-label
      margin-bottom: 8px

  .tab-item
    width: 240px
    display: flex
    justify-content: center
    align-items: center

  .card-list
    display: flex
    flex-direction: column
    margin: 0 8px

    .card-item
      border-radius: 20px
      margin-bottom: 1rem
      border: 1px solid #D3D3D3
      padding: 1rem 2rem
      background-color: #FFFFFF

      .item-title
        font-weight: 400
        font-size: 16px
        text-align: center
        color: #6D6D6D

      .item-property
        display: flex
        margin-top: 1rem
        font-size: 14px

        .property-title
          flex-basis: 50%
          font-weight: 500

@media screen and (max-width: 600px)
  .personal-study-page
    .explanation-text
      display: block
      text-align: center
      margin: 0 8px
      font-size: 14px

    .explanation-text-mobile
      color: black
      display: flex
      justify-content: center
      text-align: left
      padding: 25px
      border-radius: 15px
      box-shadow: 0 0 6px 0 #5fa8b7
      font-size: 17px
      font-family: "Montserrat", serif  Roboto, sans-serif  Verdana, sans-serif

    .filter
      flex-direction: column
      align-items: center


