.lesson-list-container
  width: 100%
  min-height: 417px

  .chapter-list
    display: flex
    flex-direction: column
    align-items: center
    margin-left: 170px
    margin-right: 170px
    margin-bottom: 0

    .chapter-item
      width: 100%
      margin-top: -45px
      text-align: center
      background: linear-gradient(0deg, #FFFEFE 36.59%, #EAE9E9 100%)
      border: 1px solid #D3D3D3
      box-sizing: border-box
      box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1)
      border-radius: 30px
      padding-bottom: 42px

      &:first-child
        margin-top: 0

      &:last-child
        padding-bottom: 6px

      &.uk-open
        background: #FFFFFF
        border: 1px solid #D3D3D3
        box-sizing: border-box
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1), 0px -2px 5px rgba(0, 0, 0, 0.1)
        border-radius: 30px
        height: auto

        & + .chapter-item
          margin-top: 0

        .uk-accordion-title
          font-weight: bold
          font-size: 16px
          color: #2E5F8C

    .uk-accordion-title
      font-size: 16px
      line-height: 23px
      color: #353535
      padding: 13px 0 12px
      position: relative

      &::before
        background: url("../../images/ic-select.png") no-repeat center center
        position: initial
        float: left
        padding-right: 8px
        display: inline-block

      span.is-geometry
        padding: 0 4px
        border-bottom: 1px solid grey

    .uk-accordion-content
      margin-top: 0
      padding-left: 3rem

    .unit-list
      .unit-item
        margin-top: 0.5rem
        margin-bottom: 2rem

        .unit-item-header
          margin-bottom: 8px
          padding-left: 2rem

          .unit-item-title
            font-weight: 500
            font-size: 16px
            color: #353535
            text-align: left

    .lesson-list
      color: $black

    .lesson-item
      padding: 3px 0
      font-weight: normal
      display: flex
      align-items: center
      font-size: 14px

      .left-part
        display: flex
        justify-content: flex-end
        flex-basis: 50%
        padding-right: 9px

        .lesson-title
          cursor: pointer
          text-align: left
          padding: 9px 2rem
          background: #FFFFFF
          border: 1px solid #D3D3D3
          box-sizing: border-box
          border-radius: 20px
          width: 100%
          min-width: 200px

          &:hover
            background: linear-gradient(to bottom, #FBFBFB, #EBEBEB)

        .lesson-title-non-clickable
          cursor: default

          &:hover
            background: transparent

      .right-part
        display: flex
        justify-content: flex-start
        flex-basis: 50%
        margin-left: 9px

        .regular-lesson-battery
          margin-right: 4px
          display: flex
          align-items: center
          flex-shrink: 0

        .control-work-battery
          display: flex
          align-items: center

          .time-left
            color: red
            span
              color: red

        .preview-tasks-button
          margin-right: 1rem
          text-transform: lowercase
          padding: 6px 1.5rem

    .pupil-details-lesson-item
      .left-part
        padding-right: 0

      .connector
        height: 1px
        width: 18px
        border-bottom: 1px solid $border-grey-3
        flex-shrink: 0

      .right-part
        flex-direction: column
        border: 1px solid $border-grey-2
        border-radius: 5px
        padding: 4px
        margin-left: 0
        flex-basis: calc(50% - 18px)
        box-sizing: border-box

        &.empty-result
          flex-basis: auto

        .user-results
          display: flex
          align-items: center

        svg
          flex-shrink: 0

        .user-lesson-labels
          margin-left: 8px
          cursor: default
          flex-shrink: 0

          svg
            margin-right: 4px

    .control-work
      padding: 0.5rem 0
      font-weight: 500
      display: flex
      justify-content: space-between
      align-items: center
      font-size: 14px

    .control-work-last
      justify-content: center

    .control-work-completed
      fill: $green

    .lesson-task-btn
      cursor: pointer
      display: flex
      flex-shrink: 0
      margin: 2px 0
      align-items: center


    .tasks-label
      margin-right: 30px

.lesson-battery-container
  position: relative
  flex-shrink: 0

  .results-container
    position: absolute
    border-radius: 5px
    background-color: white
    border: 1px solid $border-grey-2
    padding: 4px
    z-index: 100

@media screen and (max-width: 600px)
  .lesson-list-container
    .chapter-list
      margin-left: 0 !important
      margin-right: 0 !important

      .chapter-item
        border-radius: 0

        &.uk-open
          border-radius: 0

        .uk-accordion-title
          text-align: left
          font-weight: normal
          display: flex

          &::before
            position: initial
            float: left
            padding-right: 8px
            display: inline-block

        .uk-accordion-content
          padding-left: 18px
          padding-right: 18px

          .unit-item-header
            padding-left: 1rem

          .left-part, .right-part
            flex-basis: initial

          .left-part
            width: 300px
          .right-part
            width: 90px

          .lesson-item
            padding: 9px 24px
            border: 1px solid #D3D3D3
            box-sizing: border-box
            border-radius: 20px
            margin-bottom: 11px

            .lesson-title
              border: none
              padding: 0
              color: #727272

