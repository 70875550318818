@media screen and (max-width: 600px), screen and (max-height: 600px), (hover: none) and (pointer: coarse)
  .footer-block
    background: linear-gradient(135.24deg, #112C43 2.96%, #123F63 26.66%, rgba(89, 89, 89, 0) 81.86%), #2F5F8C
    padding-bottom: 13px

    .block-inner
      margin-top: 27px

      .block-body
        flex-direction: column
        align-items: center

        .column
          text-align: center
          margin-top: 1rem

          &.column-1, &.column-2, &.column-3, &.column-4
            margin-left: 0

      .footer-block-rules
        display: flex
        flex-direction: column
        align-items: center
        margin-top: 37px
        text-align: center

        .rules
          display: flex
          flex-direction: column

        .rule-2
          margin-left: 0

      .footer-block-rules span
        margin-left: 0

      .rules
        margin-right: 0



