.mobile-chat-button
  position: fixed
  right: 16px
  bottom: 16px
  z-index: 1001
  cursor: pointer
  mask-repeat: no-repeat
  flex-shrink: 0
  display: flex
  justify-content: center
  border-radius: 25%
  width: 62px
  height: 62px
  -webkit-box-shadow: 1px 24px 56px -5px rgba(27, 59, 84, 0.34)
  -moz-box-shadow: 1px 24px 56px -5px rgba(27, 59, 84, 0.34)
  box-shadow: 1px 24px 56px -5px rgba(27, 59, 84, 0.34)

  .button-title
    align-self: flex-start
    position: absolute
    top: 40px
    font-size: 14px
    color: #32ADE6

  .mobile-chat
    background-image: url("../../images/mobile-chat.svg")
    width: 30px
    height: 30px
    background-size: 100%
    background-repeat: no-repeat
    position: absolute
    align-self: center
    top: 10px
    flex-shrink: 0

  .unread-messages-quantity
    align-self: flex-start
    position: absolute
    right: 6px
    top: 6px
    background-color: #FF0090
    border-radius: 50%
    color: white
    padding: 1px 5px
    border: 2px solid white
    font-size: 12px

.chat-button
  position: fixed
  right: 10px
  bottom: 0
  z-index: 1001
  cursor: pointer
  mask-repeat: no-repeat
  flex-shrink: 0
  display: flex
  justify-content: center
  border-radius: 50%
  background-color: white
  width: 45px
  height: 45px

  &.qalan-school
    position: fixed
    right: 25px
    bottom: 15px
    z-index: 1001
    cursor: pointer
    mask-repeat: no-repeat
    flex-shrink: 0
    display: flex
    justify-content: center
    border-radius: 50%
    background-color: white
    width: 45px
    height: 45px

  .ic-chat
    background-image: url("../../images/ic-chat-message.svg")
    width: 25px
    height: 23px
    background-size: 100%
    background-repeat: no-repeat
    align-self: center
    flex-shrink: 0

    &.qalan-school
      background-image: url("../../images/ic-chat-message-qalan-school.svg")
      width: 50px
      height: 50px
      background-size: 100%
      background-repeat: no-repeat
      align-self: center
      flex-shrink: 0

  &.opened
    .ic-chat
      background-image: url("../../images/ic-close.svg")
      width: 21px
      height: 21px

  .unread-messages-quantity
    align-self: flex-start
    background-color: #2D9CDB
    border-radius: 50%
    color: white
    padding: 0 5px

.chat
  position: fixed
  right: 10px
  bottom: 20px
  width: 350px
  z-index: 1001
  border-radius: 10px
  background-color: rgba(38, 89, 136, 0.75)
  padding: 0 8px 8px 8px
  box-sizing: border-box
  box-shadow: rgba(0, 0, 0, 0.15) 0 4px 4px 0

  &.parent-chat
    background-color: rgba(0, 15, 0, 0.75)

  .chat-button
    bottom: 0

  .close-button
    padding: 12px 16px 0 16px
    margin-left: auto
    width: 1px

    .ic-close
      background-color: white

      &:hover
        background-color: black

  .message-list
    display: flex
    flex-direction: column
    padding: 10px 0
    height: 400px
    max-height: 400px
    width: 100%
    overflow-y: auto
    margin-bottom: 1rem

    .message-item
      display: table
      position: relative
      background-color: white
      border-radius: 10px
      padding: 5px
      margin: 4px auto 4px 0
      max-width: 80%

      .select-message
        display: none

      &:hover
        .select-message
          display: block

      &.is-read
        border: 2px solid green

      &.is-mine
        margin-left: auto
        margin-right: 0

      .message-sender-name
        font-size: 13px
        color: #2D9CDB
        margin-bottom: 8px
        display: block

      .is-message-read
        background: url("../../images/ic-double-tick.svg")
        min-width: 15px
        height: 12px
        align-self: flex-end
        margin-left: 8px

      .message-sent-at
        color: #8f8d8d
        font-size: 12px

      .message-status-sent
        background: url("../../images/ic-double-tick.svg")

      .message-status
        min-width: 15px
        height: 12px
        align-self: flex-end
        margin-left: 8px
        mask-repeat: no-repeat

        &.sent
          mask-image: url("../../images/ic-single-tick.svg")
          background-color: gray

        &.delivered
          mask-image: url("../../images/ic-double-tick.svg")
          background-color: gray

        &.read
          mask-image: url("../../images/ic-double-tick.svg")
          background-color: #013bb2

        &.failed
          mask-image: url("../../images/ic-single-tick.svg")
          background-color: red

    .message-text
      white-space: pre-wrap

  .message-input-box
    width: 90%
    height: 38px
    outline: none
    border: none
    border-radius: 10px
    margin: 0 auto
    padding: 5px 10px
    box-sizing: border-box

  .ic-send-message
    mask-image: url("../../images/ic-send-message.svg")
    width: 30px
    height: 30px
    mask-size: 100%
    cursor: pointer
    background-color: white
    mask-repeat: no-repeat
    flex-shrink: 0
    margin-left: 8px
    align-self: center

    &:hover
      background-color: rgba(1, 112, 175, 1)

    &:disabled
      background-color: lightgrey
      cursor: default

@media screen and (max-width: 600px), screen and (max-height: 600px)
  .chat
    .message-list
      height: calc(100vh - 180px)


.criteria-text
  margin: 10px
  display: block
  color: black

.rewind-buttons
  justify-content: flex-start
  display: flex
  margin: 5px
  gap: 5px

.rewind-button
  width: 30px
  height: 30px
  border: none
  background-image: url("../../images/rewind.png")
  background-size: cover

.forward-button
  width: 30px
  height: 30px
  border: none
  background-image: url("../../images/forward.png")
  background-size: cover


.chat-container
  display: flex
  flex-direction: column
  padding: 10px
  margin-bottom: 10px

  .chat-bubble
    margin: 10px
    padding: 10px
    border-radius: 10px
    max-width: 60%
    word-wrap: break-word
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1)

    &.employee
      background-color: #dcf8c6
      /* Light green for employee */
      align-self: flex-end


    &.client
      background-color: #f1f0f0
      /* Light gray for client */
      align-self: flex-start


    .chat-time
      font-size: 0.8rem
      color: gray


    .chat-word
      font-size: 1rem


