.control-works-dashboard
  display: flex
  flex-direction: column
  align-items: center

  .control-works-tab, .diagnostics-tab
    display: flex
    flex-direction: column
    align-items: center
    width: 100%

    .filter-container
      display: flex
      flex-direction: column
      align-items: center

  .control-work-time-range
    display: flex
    align-items: center
    margin: 1rem 0

    .date-picker
      border-radius: 5px
      border: 1px solid $border-grey-3

    .react-datepicker__portal
      .react-datepicker
        border-width: 1px 0

    .date-time-picker-interval-separator
      width: 20px
      margin: 0 9px
      background-color: #6D6D6D
      height: 1px


  .control-work-table
    margin-top: 3rem

    .group-row
      cursor: pointer
      &.new
        border: 1px solid #32d296
      &:hover
        background-color: bisque
      &.selected
        background-color: #265988
        color: #FFFFFF
        font-weight: 500
        border: none

    .date-column
      width: 160px

  .lesson-task-btn
    cursor: pointer
    display: flex
    justify-content: center

  .inner-table
    border: none !important

    & > td
      padding-top: 0
      background: #265988

    .table-wrapper
      margin: 0 22px
      border: 1px solid #D3D3D3
      box-sizing: border-box
      border-radius: 30px
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1), 0px -2px 5px rgba(0, 0, 0, 0.1)

      .pupil-list-table
        background: #FFFFFF
        border-radius: inherit

        thead
          background-color: #FFFFFF

          th
            color: #353535

        td
          padding: 10px 20px

  .icon
    cursor: pointer

  .ic-cancel
    background-image: url(../../images/ic-cancel.svg)
    width: 19px
    height: 19px

  .ic-restore
    background-image: url(../../images/ic-restore.svg)

  .status-label
    border-radius: 30px
    display: inline-block
    padding: 10px 20px
    background-color: $grey
    line-height: 18px
    font-size: 13px
    color: #fff
    vertical-align: middle
    white-space: nowrap
    text-transform: uppercase
    min-width: 220px

    &.not-started
      background-color: #778899
    &.finished
      background-color: #32d296
    &.active
      background-color: #1e87f0
    &.deadline-passed
      background-color: #F19CBB
    &.cancelled
      background-color: #DCDCDC

@media screen and (max-width: 30rem)
  .control-works-dashboard
    padding: 0
    .control-work-time-range
      flex-direction: column
