.pupil-list-dashboard
  margin-top: -34px

  .pupil-list-table
    width: 100%

    td
      padding-top: 10px
      padding-bottom: 12px

    th:last-child, td:last-child
      text-align: right
      padding-right: 15px
      width: 1%
      white-space: nowrap
      padding-left: 15px

