.pupil-self-works-page
  .page-body-content
    background-color: #8f8d8d

    .battery-cell
      padding-left: 16px

    td
      padding-left: 8px
      padding-right: 8px

    .lesson-title
      min-width: 250px

    .card-list
      display: flex
      flex-direction: column
      margin: 0 8px

      .card-item
        border-radius: 20px
        margin-bottom: 1rem
        border: 1px solid #D3D3D3
        padding: 1rem 2rem
        background-color: #FFFFFF

        .self-work-title
          font-weight: 400
          font-size: 16px
          text-align: center
          color: #6D6D6D

        .self-work-property
          display: flex
          margin-top: 1rem
          font-size: 14px

          .property-title
            flex-basis: 50%
            font-weight: 500

          .self-work-status
            padding: 3px 8px
            color: #FFFFFF
            border-radius: 5px

            &.assigned
              background-color: #2D9CDB

            &.finished
              background-color: green

            &.finished-unsuccessfully
              background-color: #800000

