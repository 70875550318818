.comment-list-modal
  .comment-list
    .comment-item
      border: 1px solid #D3D3D3
      padding: 1rem 24px
      margin-bottom: 1rem
      border-radius: 20px

      .author-fullname
        color: rgba(1, 112, 175, 0.8)
        font-weight: 700
      .text
        color: #353535
        margin: 8px 0
      .created-at
        color: #8f8d8d
        margin-left: auto
        font-size: 14px

  .add-comment-section
    display: flex
    flex-direction: column
    align-items: center
    margin-top: 1rem

    .comment-textarea
      border: 1px solid #D3D3D3
      max-width: 550px
      width: 100%
      border-radius: 20px
      padding: 1rem
      box-sizing: border-box
      outline: none
      margin-bottom: 1rem

    .button
      width: 200px


