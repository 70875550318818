@font-face
  font-family: 'Gilroy-Bold'
  src: local('Gilroy-Bold'), url(../fonts/Gilroy-UltraLight.ttf) format('truetype')

.olympiad-page
  width: 100%
  height: 100%
  font-family: Gilroy-Bold, serif

  .general
    display: flex
    margin-top: 20px

    .info
      border-radius: 50px
      width: 67%
      padding: 70px
      background-color: #184979
      position: relative

      .background-line
        top: 0
        left: 0
        position: absolute
        background-image: url("../../images/olympiad-background-line.svg")
        width: 100%
        height: 100%

      .content
        position: relative

        h1
          color: white
          font-weight: bold
          font-size: 40px
          margin: 0

        h2
          color: white
          font-weight: bold
          font-size: 30px
          margin: 0
          opacity: 0.5

        p
          color: white
          font-weight: normal
          font-size: 25px

        ul
          padding-left: 0

        li
          color: white
          font-size: 16px
          opacity: 0.7

        .info-item
          border-radius: 30px
          background-color: white
          padding: 4%
          width: 23%
          text-align: center
          display: flex
          flex-direction: column
          flex-wrap: wrap
          justify-content: space-between

          .info-item-header
            color: #202020
            opacity: 0.7
            font-size: 1.1vw

          .info-item-answer
            display: flex
            align-items: center
            justify-content: center
            height: 30%
            margin: 0 auto

            span
              text-align: left
              width: 60%
              margin-left: 15px
              font-weight: bold
              color: black
              font-size: 1.8vw

            .time-icon
              background-image: url("../../images/olympiad-time-icon.svg")
              width: 40px
              height: 40px

            .pen-icon
              background-image: url("../../images/olympiad-pen-icon.svg")
              width: 53px
              height: 40px

            .helmet-icon
              background-image: url("../../images/olympiad-helmet-icon.svg")
              width: 40px
              height: 40px

    .register
      width: 40%
      border-radius: 50px
      background-color: #113253
      padding: 70px


      .qalan-icon
        background-image: url("../../images/olympiad-qalan-icon.svg")
        width: 82px
        height: 26px
        margin-bottom: 30px

      .register-form
        width: 70%
        height: 75%
        border-radius: 50px
        padding: 40px 23px
        position: relative

      input
        width: 100%
        height: 10%
        margin-bottom: 10px
        background-color: #E8E8E8
        border-radius: 10px
        padding: 20px

      ::placeholder
        color: #202020
        font-size: 12px

      .grade-select
        width: 100%
        background-color: #E8E8E8
        color: #E8E8E8
        margin-bottom: 10px

        input
          background-color: white

      span
        font-size: 12px
        font-weight: bold
        text-align: center
        margin-top: auto

      button
        background-color: #184979
        border-radius: 15px
        width: 100%
        padding: 30px 15px
        font-size: 18px

      .support-info
        color: white
        width: 75%
        margin-top: 10px
        font-weight: normal

  .result
    display: flex
    flex-direction: column
    align-items: center
    width: 80%
    margin-inline: auto
    background-color: rgba(24, 73, 121, 0.1)
    margin-top: 20px
    border-radius: 75px
    padding: 60px 0
    text-align: center
    gap: 30px

    .congratulation
      font-weight: bold
      color: black
      font-size: 20px
      width: 30%

    .contact-info
      background-color: #174775
      color: white
      padding: 15px
      border-radius: 17px

    .certificate
      background-color: #174775
      border-radius: 50px
      display: flex
      width: 70%

      .download-certificate
        background-color: white
        display: flex
        flex-direction: column
        align-items: center
        border-radius: 50px
        padding: 30px 70px
        color: black
        gap: 10px

        .download-certificate-button
          width: 100%
          background-color: #174775
          color: white
          padding: 15px 40px
          border-radius: 17px

      .certificate-image
        margin: 20px 40px
        display: flex
        justify-content: center
        align-items: center

        img
          width: 80%
          border-radius: 20px

  .olympiad-failed
    padding: 40px 80px
    display: flex
    flex-direction: column
    align-items: center
    margin-inline: auto
    border-radius: 75px
    background-color: rgba(24, 73, 121, 0.1)
    text-align: center
    margin-top: 20px
    width: 50%

    .olympiad-failed-text-1
      border-radius: 17px
      background-color: #184979
      color: white
      padding: 10px

    .olympiad-failed-text-2
      margin-top: 20px


    .olympiad-failed-text-3
      border: 1px solid black
      border-radius: 30px
      padding: 10px
      margin-top: 30px



@media screen and (max-width: 600px), screen and (max-height: 600px), (hover: none) and (pointer: coarse)
  .olympiad-page
    .general
      flex-direction: column
      background-color: #113253
      width: auto

      .info
        padding: 0
        width: 100%
        border-bottom-left-radius: 50px
        border-bottom-right-radius: 50px

        .content
          .mobile-content
            padding: 30px 30px
            h1
              font-size: 35px

            h2
              font-size: 25px

            p
              font-size: 15px

            li
              font-size: 12px

            .info-item
              border-radius: 15px
              width: 24%

              .info-item-header
                font-size: 12px

              .info-item-answer
                height: 30%

                span
                  font-size: 12px

                .time-icon
                  background-image: url("../../images/olympiad-small-time-icon.svg")
                  width: 20px
                  height: 20px

                .pen-icon
                  background-image: url("../../images/olympiad-small-pen-icon.svg")
                  width: 29px
                  height: 20px

                .helmet-icon
                  background-image: url("../../images/olympiad-small-helmet-icon.svg")
                  width: 20px
                  height: 20px

      .register
        width: 100%
        padding: 20px 0

    .result
      flex-direction: column
      align-items: center
      border-radius: 75px
      text-align: center
      gap: 30px
      width: 100%

      .congratulation
        font-size: 20px
        width: 90%

      .contact-info
        width: 80%


      .certificate
        flex-direction: column
        width: 90%

    .olympiad-failed
      padding: 20px 40px
      border-radius: 20px
