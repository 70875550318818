.org-tree
  margin: auto

  ul
    margin-left: 0
    padding-left: 0
    padding-top: 20px
    position: relative
    transition: all 0.5s
    -webkit-transition: all 0.5s
    -moz-transition: all 0.5s

    ul
      &::before
        content: ""
        position: absolute
        top: 0
        left: 50%
        border-left: 1px solid #ccc
        width: 0
        height: 20px

  li
    float: left
    text-align: center
    list-style-type: none
    position: relative
    padding: 20px 5px 0 5px
    transition: all 0.5s
    -webkit-transition: all 0.5s
    -moz-transition: all 0.5s

    &::after
      right: auto
      left: 50%
      border-left: 1px solid #ccc

    &:only-child
      padding-top: 0

    &:last-child
      &::before
        border-right: 1px solid #ccc
        border-radius: 0 5px 0 0
        -webkit-border-radius: 0 5px 0 0
        -moz-border-radius: 0 5px 0 0

    &:first-child
      &::after
        border-radius: 5px 0 0 0
        -webkit-border-radius: 5px 0 0 0
        -moz-border-radius: 5px 0 0 0

.org-tree li::before,
.org-tree li::after
  content: ""
  position: absolute
  top: 0
  right: 50%
  border-top: 1px solid #ccc
  width: 50%
  height: 20px

.org-tree li:only-child::after,
.org-tree li:only-child::before
  display: none

.org-tree li:first-child::before,
.org-tree li:last-child::after
  border: 0 none

.org-tree li a:hover,
.org-tree li a:hover + ul li a
  background: #c8e4f8
  color: #000
  border: 1px solid #94a0b4

.org-tree li a:hover + ul li::after,
.org-tree li a:hover + ul li::before,
.org-tree li a:hover + ul::before,
.org-tree li a:hover + ul ul::before
  border-color: #94a0b4

.chart-block
  position: relative
  border-radius: 10px
  width: 180px
  margin-bottom: 0

  .chart-block-body
    display: block
    text-align: center
    position: relative
    z-index: 1
    left: 50%
    transform: translateX(-50%)
    padding: 5px
    font-family: Arial, serif

    span
      font-weight: 600
      line-height: 20px

.chart-block-description-card
  position: absolute
  border: 1px solid $border-grey-2
  cursor: pointer
  display: flex
  flex-direction: column
  flex-shrink: 0
  margin: 2px 0 2px 200px
  align-items: center
  z-index: 101
  border-radius: 20px
  padding: 15px
  width: 300px

  img
    width: 200px
    border-radius: 150px
    border: 3px solid #284d28
    box-shadow: 0 0 7px #666
    margin-bottom: 10px

  p
    text-align: justify

.employee-result
  width: 800px
  border: 1px solid #d9d6d6
  border-radius: 10px
  padding: 15px
  margin-bottom: 20px
  display: flex

  .user-tests
    background-color: white
    padding: 5px
    border-radius: 10px
    position: absolute
    z-index: 101

  .test-results
    margin-left: auto
