li, ol, ul
  margin: 0
  padding: 0

a
  text-decoration: none

a:hover
  text-decoration: none

button
  background: none
  border: none
  outline: none
  box-shadow: none

.olympiad-mobile-rule
  margin-bottom: 12px
  display: flex

.olympiad-mobile-rule-icon
  background-image: url("../../images/olympiad-success-icon.svg")
  background-position: center
  background-repeat: no-repeat
  background-size: contain
  margin-right: 4px
  width: 16px
  height: 16px

.olympiad-result-success-icon
  background-image: url("../../images/result-success-icon.png")
  background-position: center
  background-repeat: no-repeat
  background-size: contain
  width: 28px
  height: 28px

.olympiad-result-clock-icon
  background-image: url("../../images/result-clock-icon.png")
  background-position: center
  background-repeat: no-repeat
  background-size: contain
  width: 28px
  height: 28px

._pay-kaspi-icon
  background-image: url("../../images/pay-kaspi-icon.svg")
  background-position: center
  background-repeat: no-repeat
  background-size: contain
  width: 20px
  height: 20px
  margin-right: 2px

._chat-certificate-img
  background-image: url("../../images/chat-sertificate-img.png")
  background-position: center
  background-repeat: no-repeat
  background-size: contain
  border-radius: 16px
  width: 343px
  height: 258px

._phone-mobile-app
  background-image: url("../../images/app-mobile-img.png")
  background-position: center
  background-repeat: no-repeat
  background-size: contain
  border-radius: 16px
  width: 343px
  height: 258px

.diploma-border-img
  background-image: url("../../images/diploma-border-img.png")
  background-position: center
  background-repeat: no-repeat
  background-size: contain
  border-radius: 16px
  width: 343px
  height: 258px

.diploma-background-img
  background-image: url("../../images/diploma-background-img.png")
  position: relative
  background-position: center
  background-repeat: no-repeat
  background-size: contain
  border-radius: 16px
  width: 343px
  height: 258px

.laptop-diploma-img
  background-image: url("../../images/laptop-diploma-img.png")
  position: absolute
  background-position: center
  background-repeat: no-repeat
  background-size: contain
  right: -10px
  bottom: -5px
  width: 282.58px
  height: 257.45px

.cup-diploma-img
  background-image: url("../../images/cup-diploma-img.png")
  position: absolute
  background-position: center
  background-repeat: no-repeat
  background-size: contain
  top: 10px
  left: -24px
  width: 130px
  height: 130px

.clock-icon
  background-image: url("../../images/olympiad-clock-icon.png")
  width: 32px
  height: 32px

.dock-icon
  background-image: url("../../images/olympiad-dock-icon.png")
  width: 32px
  height: 32px

.note-icon
  background-image: url("../../images/olympiad-note-icon.png")
  width: 32px
  height: 32px

.wrapper-olympiad
  min-height: 100%

._container-olympiad
  max-width: 1440px
  margin-left: auto
  margin-right: auto
  padding-left: 15px
  padding-right: 15px
  box-sizing: content-box

._container-two
  max-width: 800px
  margin: 0 auto
  padding: 0 16px
  box-sizing: content-box

.olympiad-mobile-title_container
  display: flex
  flex-direction: column
  align-items: center

.olympiad-mobile-title_h3
  font-weight: 700
  font-size: 32px
  color: #384048
  margin: 24px 0 0 0
  line-height: 40px

.olympiad-mobile-title_p
  font-weight: 700
  font-size: 22px
  color: #00B0FF
  line-height: 28px
  margin: 0

.olympiad-mobile-rules_title
  font-weight: 700
  font-size: 34px
  line-height: 42px
  color: #1A83FF
  margin: 0 0 24px 0

.olympiad-mobile-rules_p
  font-weight: 500
  font-size: 16px
  line-height: 19px
  color: #2B2C34
  margin: 0 0 12px 0

.olympiad-mobile-rule-p
  font-weight: 400
  font-size: 14px
  line-height: 16.8px
  color: #2B2C34
  margin: 0

.olympiad-mobile-rule-list
  align-items: center

.olympiad-mobile-rule-item
  display: flex
  align-items: center

.olympiad-mobile-rule-item::before
  content: "•"
  padding: 0 4px 0 0
  font-size: 25px
  color: #384048

.olympiad-mobile-rule-item span
  line-height: 19.6px
  font-weight: 400
  font-size: 14px
  color: #384048

.olympiad-mobile-products
  margin-top: 16px

.olympiad-mobile-products_container
  display: grid
  grid-template-columns: 1fr 1fr 1fr
  gap: 8px
  margin-top: 24px

.olympiad-mobile-products-content
  background: #F1F3F4
  border-radius: 8px
  width: 100%

.olympiad-mobile-products-item
  display: flex
  align-items: center
  padding: 20px

.products-item-text
  margin-left: 8px

.products-item-text-p
  font-size: 10px
  font-weight: 400
  line-height: 14px
  color: #2B2C34
  margin: 0

.products-item-text-h3
  font-size: 14px
  font-weight: 600
  line-height: 19px
  color: #2B2C34
  margin: 0

.olympiad-mobile-form
  margin-top: 24px

.olympiad-mobile-form_form
  background: #F4F6F8
  border-radius: 12px
  padding: 28px
  display: flex
  flex-direction: column

.olympiad-mobile-form_h3
  margin: 0 0 12px 0
  font-weight: 700
  font-size: 26px
  line-height: 36px
  color: #1A83FF

.olympiad-mobile-form_p
  margin: 0 0 12px 0
  font-weight: 400
  font-size: 14px
  line-height: 19px
  color: #2B2C34

.olympiad-mobile-form_name
  margin-top: 12px
  width: 100%

.olympiad-mobile-form-p
  font-weight: 400
  font-size: 13px
  line-height: 16px
  color: #8090A0
  margin: 0 0 8px 0

.olympiad-mobile-form_input
  all: unset
  width: calc(100% - 42px)
  background: #FFFFFF
  border-radius: 12px
  border: #E4E8EC 1px solid
  padding: 13px 20px
  font-weight: 400
  font-size: 17px
  color: #384048
  line-height: 22px
  caret-color: #00B0FF

.olympiad-mobile-form_input:focus
  border: #1A83FF 2px solid

.olympiad-mobile-phonenumber_cnt
  display: flex

.olympiad-mobile-phonenumber_cnt:focus
  border: #1A83FF 2px solid

.olympiad-mobile-form_input-phn
  all: unset
  width: 100%
  background: #FFFFFF
  border-radius: 12px
  border: #E4E8EC 1px solid
  padding: 13px 20px
  font-weight: 400
  font-size: 17px
  color: #384048
  line-height: 22px
  caret-color: #00B0FF

.olympiad-mobile-form_input-phn:focus
  border: #1A83FF 2px solid

.olympiad-mobile-form_select
  width: 100%
  color: #384048
  font-weight: 400
  font-size: 17px

  .Select-placeholder
    margin: 5px 0 13px 14px
    color: #384048
    font-weight: 400
    font-size: 17px

  input
    margin: 5px 0 13px 14px

  .Select-value
    padding: 5px 0 20px 0
    color: #384048
    font-weight: 400
    font-size: 17px

  .Select-value
    margin-left: 14px

.Select-control:focus
  border: #00B0FF 2px solid

.olympiad-mobile-submit-button
  cursor: pointer
  width: 100%
  padding-top: 13px
  padding-bottom: 13px
  border-radius: 12px
  color: #FFFFFF
  font-weight: 600
  font-size: 16px
  line-height: 22px
  background: #1A83FF

.olympiad-mobile-submit-button:hover
  background: #43C5FF

.olympiad-mobile-submit-button:disabled
  background: #868686
  color: #636363

.olympiad-mobile-submit-button:active
  background: #008BCA

.olympiad-mobile-submit-button-cnt
  margin-top: 20px
  width: 100%

.olympiad-mobile-warning-p1
  margin: 8px 0 0 0
  color: #384048
  font-weight: 400
  font-size: 13px
  line-height: 16px

.olympiad-mobile-warning-p2
  margin: 12px 0 0 0
  color: #FF0090
  font-weight: 400
  font-size: 13px
  line-height: 16px

.olympiad-mobile-contacts
  margin-top: 24px

.olympiad-mobile-contacts-p
  color: #384048
  font-weight: 400
  font-size: 13px
  line-height: 16px

.olympiad-mobile-content
  margin-top: 40px
  display: flex
  justify-content: center

.olympiad-mobile-content_one
  margin-right: 40px

.olympiad-progress_container
  display: flex
  margin-top: 40px
  justify-content: space-between
  align-items: center
  border-radius: 20px
  padding: 40px 28px
  background-color: #00D080

.olympiad-progress-p-block
  opacity: 60%
  height: 24px
  display: flex
  align-items: center

.olympiad-progress-p-block p
  font-size: 20px
  font-weight: 600
  line-height: 20px
  color: #FFFFFF

.olympiad-progress-p-access
  margin-right: 2px
  width: 12px
  height: 12px
  background-image: url("../../images/access-icon.svg")
  background-size: contain
  background-repeat: no-repeat
  background-position: center

.olympiad-slash
  margin: 0 4px

.olympiad-progress-p-wrong
  margin-right: 2px
  width: 12px
  height: 12px
  background-image: url("../../images/wrong-icon.svg")
  background-size: contain
  background-repeat: no-repeat
  background-position: center

.olympiad-progress
  margin-top: 40px

.olympiad-progress-h3
  margin-bottom: 16px
  font-size: 28px
  font-weight: 700
  line-height: 39px
  color: #1A83FF

.olympiad-progress-p
  margin-bottom: 8px
  font-size: 16px
  font-weight: 600
  line-height: 22px
  color: #2B2C34

.olympiad-result-h3
  margin-bottom: 4px
  font-size: 12px
  font-weight: 400
  line-height: 16px
  color: #2B2C34

.olympiad-result-p
  margin: 0
  font-size: 16px
  font-weight: 600
  line-height: 22px
  color: #2B2C34

.olympiad-result
  display: grid
  grid-template-columns: 1fr 1fr
  gap: 16px

.olympiad-result-cnt
  background: #F1F3F4
  border-radius: 8px
  padding: 12px
  display: flex
  justify-content: space-between

.flex-wrapper
  display: flex
  flex-flow: row nowrap

.single-chart
  width: 100px
  justify-content: space-around

.circular-chart
  display: block
  margin: 0
  max-width: 100%
  max-height: 250px

.circle-bg
  fill: none
  stroke: #384048
  opacity: 32%
  stroke-width: 3.8

.circle
  fill: none
  stroke-width: 3.8
  stroke-linecap: round
  animation: progress 1s ease-out forwards

.olympiad-notification
  margin-top: 20px

.olympiad-notification_container
  display: flex
  justify-content: space-between
  align-items: stretch

.olympiad-result-next
  background-color: #F2F4F7
  width: 35%
  border-radius: 12px
  padding: 20px

.olympiad-result-next-p
  color: #384048
  margin-bottom: 0
  font-weight: 600
  font-size: 16px
  line-height: 22px

.olympiad-ad-app
  position: relative
  background-color: #00B0FF
  margin-left: 20px
  width: 60%
  border-radius: 20px
  padding: 20px

.olympiad-ad-app-h3
  max-width: 404px
  color: #FFFFFF
  margin: 0
  font-weight: 700
  font-size: 16px
  line-height: 20px

.olympiad-ad-app-p
  max-width: 404px
  color: #FFFFFF
  margin: 4px 0 12px 0
  font-weight: 500
  font-size: 12px
  line-height: 15px

.olympiad-ad-app-buttons
  display: flex

.olympiad-ad-app-playmarket
  margin-right: 13px
  width: 123px
  height: 40px
  background-image: url('../../images/playmarket-white.svg')
  background-size: contain
  background-repeat: no-repeat
  background-position: center

._dont-worry
  margin-top: 16px
  padding: 20px
  background: #F1F3F4
  border-radius: 12px

._dont-worry-p
  color: #2B2C34
  font-weight: 400
  font-size: 16px
  line-height: 22.4px
  margin: 0

.olympiad-ad-app-apple
  width: 110px
  height: 40px
  background-image: url('../../images/appstore-white.svg')
  background-size: contain
  background-repeat: no-repeat
  background-position: center

.kaspi-logo
  width: 24px
  height: 24px
  background-image: url('../../images/kaspi-icon.svg')
  background-size: contain
  background-repeat: no-repeat
  background-position: center

.olympiad-ad-app-phone
  position: absolute
  bottom: -10px
  right: 30px
  width: 146px
  height: 171px
  background-image: url('../../images/phone-app-olympiad.png')
  background-size: contain
  background-repeat: no-repeat
  background-position: center

@keyframes progress
  0%
    stroke-dasharray: 0 100

.circular-chart.orange .circle
  stroke: #EEF4FF

.percentage
  fill: #EEF4FF
  font-size: 8px
  font-weight: 600
  text-anchor: middle

.olympiad-diploma
  margin-top: 24px

.olympiad-diploma_container
  display: flex
  flex-direction: column
  align-items: center

.olympiad-certificate-image
  margin: 29px auto 0 auto
  background: #F1F3F4
  border-radius: 12px

.olympiad-certificate-example-img
  margin: 0 auto
  width: 452px
  height: 318px
  background-image: url('../../images/diploma-example-img.svg')
  background-size: contain
  background-repeat: no-repeat
  background-position: center

.olympiad-certificate-image img
  border-radius: 15px

.olympiad-certificate-button
  margin-top: 12px
  width: 100%
  max-width: 689px

._what-next-h3
  margin: 20px 0 0 0
  color: #2B2C34
  font-weight: 700
  font-size: 24px
  line-height: 33px

._what-next_h3
  margin: 8px 0 8px 0
  color: #2B2C34
  font-weight: 700
  font-size: 16px
  line-height: 22.4px

._what-next_p
  margin: 0
  color: #2B2C34
  font-weight: 400
  font-size: 14px
  line-height: 19.6px

._what-next_a
  background: #F14635
  border-radius: 12px
  display: flex
  width: max-content
  padding: 14px 24px
  justify-content: center
  align-items: center
  color: #FFFFFF
  margin: 8px 0 0 0
  font-weight: 600
  font-size: 16px
  line-height: 20px

._what-next_a:disabled
  background-color: #6A6A6A

._download-app__a
  margin-top: 8px
  display: block
  width: max-content
  background: #1A83FF
  padding: 14px 16px
  color: #FFFFFF
  border-radius: 12px
  font-weight: 600
  font-size: 16px
  line-height: 20px

._what-next-cnt
  margin-top: 20px
  margin-bottom: 12px
  width: 100%
  display: flex
  justify-content: center

._what-next-content
  display: grid
  grid-template-columns: 343px 343px
  gap: 40px

.olympiad-certificate-button button
  cursor: pointer
  border-radius: 12px
  margin: 0 0 20px 0
  background-color: #00B0FF
  color: #FFFFFF
  font-size: 16px
  font-weight: 600
  line-height: 22px
  width: 100%
  padding: 13px 0

.diploma-pay-header
  display: flex
  justify-content: space-between
  align-items: center

.diploma-pay-price
  margin: 0 0 0 14px

.diploma-modal-line
  width: 100%
  height: 1px
  background-color: #708090

.diploma-pay-button
  width: 100%
  padding: 0
  background-color: #F14635
  border-radius: 12px

.diploma-pay-button-cnt
  justify-content: center
  align-items: center
  display: flex
  margin: 13px auto

.diploma-pay-button-cnt-p
  margin: 8px 0 0 8px
  color: #01162D
  font-weight: 600
  font-size: 12px
  line-height: 16.8px

.diploma-pay-button-cnt p
  margin: 0 0 0 8px
  color: #FFFFFF
  font-weight: 600
  font-size: 16px
  line-height: 22px

.diploma-help-call
  color: #384048
  font-size: 12px
  font-weight: 400
  line-height: 22px

@media screen and (max-width: 1060px)
  .olympiad-mobile-content
    flex-direction: column

  .olympiad-mobile-content_one
    margin-right: 0

  .olympiad-mobile-content_two
    margin-top: 40px
    margin-bottom: 16px

@media screen and (max-width: 890px)
  .olympiad-notification_container
    flex-direction: column
    align-items: start

  .olympiad-result-next
    padding: 20px 0
    margin: 0
    width: 100%

  .olympiad-ad-app
    padding: 20px 0
    margin: 24px 0 0 0
    width: 100%

  .olympiad-result-next-p
    margin: 0 20px

  .olympiad-ad-app-h3
    margin: 0 20px

  .olympiad-ad-app-p
    margin: 0 20px

  .olympiad-ad-app-buttons
    margin: 0 20px

  .olympiad-ad-app-phone
    bottom: -15px
    right: 30px
    width: 134px
    height: 159px

@media screen and (max-width: 800px)
  ._what-next-content
    grid-template-columns: 343px
    gap: 42px

  .olympiad-certificate-image
    max-width: 343px

  .olympiad-certificate-example-img
    width: 319px
    height: 224px

@media screen and (max-width: 785px)
  .olympiad-mobile-products_container
    grid-template-columns: 1fr

@media screen and (max-width: 540px)
  .olympiad-ad-app-phone
    display: none
    visibility: hidden

  .olympiad-ad-app-h3
    margin: 0 auto
    text-align: center

  .olympiad-ad-app-p
    margin: 0 auto
    text-align: center

  .olympiad-ad-app-buttons
    margin: 12px 0 0 0
    justify-content: center
