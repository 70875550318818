.text-style
  color: #c645db
  letter-spacing: 0.2em
  font-weight: 100

.game-item-container
  display: grid
  grid-template-columns: 33% 33% 33%
  padding: 10px
  min-width: 800px

  .game-item-block
    display: flex
    flex-direction: column
    transition: all 0.5s
    width: 200px
    margin: 10px
    flex: 1
    padding: 20px
    border: 1px solid #0d6be8
    border-radius: 15px
    background-color: #f8f8f8
    min-height: 300px

  .game-item-block hr
    margin: 10px 0

  .game-item-block:hover
    border: 1px solid #0d6be8
    transform: scale(1.05)

  .game-item-block:active
    transform: scale(0.95) rotateZ(1.7deg)

  .game-item-image
    overflow: hidden
    position: relative
    width: 100%
    height: 150px
    background-color: #f6f6f6
    border-radius: 0.5rem

  .game-item-title
    margin: 10px auto 0 auto
    font-weight: 300
    font-size: 28px
    color: #c706fc
    letter-spacing: 1.5px

  .game-item-body
    display: flex
    justify-content: space-between

  .game-item-body-text
    font-size: 18px
    color: #0e19fa
    letter-spacing: 1.5px
    font-weight: 300

.game-item-button
  padding: 17px 40px
  border-radius: 50px
  cursor: pointer
  border: 0
  background-color: white
  box-shadow: rgb(0 0 0 / 5%) 0 0 8px
  letter-spacing: 1.5px
  text-transform: uppercase
  font-size: 15px
  transition: all 0.5s ease

.game-item-button:hover
  letter-spacing: 3px
  background-color: hsl(261deg 80% 48%)
  color: hsl(0, 0%, 100%)
  box-shadow: rgb(93 24 220) 0 7px 29px 0

.game-item-button:active
  background-color: hsl(261deg 80% 48%)
  color: hsl(0, 0%, 100%)
  box-shadow: rgb(93 24 220) 0 0 0 0
  transition: 100ms

.game-item-password
  display: flex
  line-height: 30px
  align-items: center
  position: relative
  max-width: 200px


.game-item-password.input
  width: 100%
  height: 45px
  line-height: 30px
  padding: 0 5rem
  padding-left: 3rem
  border: 2px solid transparent
  border-radius: 10px
  outline: none
  background-color: #f8fafc
  color: #0d0c22
  transition: .5s ease


.game-item-password input::placeholder
  color: #94a3b8


.game-item-password input:focus, .game-item-password input:hover
  outline: none
  border-color: rgba(129, 140, 248)
  background-color: #fff
  box-shadow: 0 0 0 5px rgb(129 140 248 / 30%)

.icon-game
  position: absolute
  left: 1rem
  fill: none
  width: 1rem
  height: 1rem

.game-item-footer
  display: flex
  justify-content: space-around
  padding: 20px 0
  margin-top: 10px

@mixin game-item-footer-button
  background-color: #eee
  border: none
  padding: 1rem
  font-size: 1rem
  width: 10em
  border-radius: 1rem
  box-shadow: 0 0.4rem #dfd9d9
  cursor: pointer

  &:active
    color: white
    box-shadow: 0 0.2rem #dfd9d9
    transform: translateY(0.2rem)


  &:hover:not(:disabled)
    text-shadow: 0 0.1rem #bcb4b4


  &:disabled
    background-color: #cecece

.game-item-footer-button
  @include game-item-footer-button

  &.lightcoral
    color: lightcoral

    &:hover:not(:disabled)
      background: lightcoral
      color: white

  &.green
    color: #70ce68

    &:hover:not(:disabled)
      background: #70ce68
      color: white

.game-item-image-container
  width: 400px
  height: 300px
  position: relative
  overflow: hidden
  display: flex
  justify-content: center
  align-items: center

.game-item-image-container img
  max-width: 100%
  max-height: 100%
  object-fit: fill
