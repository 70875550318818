.info-block
  border-radius: 18px
  box-shadow: 0px 8px 32px 0 rgba(32, 64, 96, 0.08), 0px 0px 2px 0 rgba(32, 64, 96, 0.08)

  .ic-tenge
    background: url("../../images/tenge.svg")
    width: 36px
    height: 36px
    background-size: contain
  background-repeat: no-repeat
  background-position: center

  .ic-rub
    background: url("../../images/rub.svg")
    width: 36px
    height: 36px
    background-size: contain
    background-repeat: no-repeat
    background-position: center

  .ic-uzb
    background: url("../../images/uzs.svg")
    width: 44px
    height: 24px
    background-size: contain
    background-repeat: no-repeat
    background-position: center

  .ic-diamond
    background: url("../../images/diamond.svg")
    width: 36px
    height: 36px
    background-size: contain
    background-repeat: no-repeat
    background-position: center
