.select-new
  background: url(../../images/dropdown-arrow.svg) no-repeat scroll right 13px center
  background-size: 15px 15px
  background-position-y: 18px
  display: flex
  align-items: center
  justify-content: center
  box-sizing: border-box
  font-weight: 500
  border-radius: 16px
  font-size: 12px
  color: #2E5F8C
  border: none

  .Select-control
    background: #FFFFFF
    border: 1px solid #FFFFFF
    box-sizing: border-box
    border-radius: 16px
    padding-left: 15px
    padding-right: 15px

    .Select-value, .Select-placeholder
      padding-left: 27px

.Dropdown-control
  border-radius: 16px !important
  padding: 3px 27px !important
  color: #2E5F8C !important

.Dropdown-arrow
  display: none !important

.Dropdown-menu-new
  border-radius: 12px
  text-align: center
  font-weight: 500
  font-size: 16px
  color: #0075FF
  top: calc(100% + 5px)

.Dropdown-menu-new .Dropdown-option
  color: #0075FF

.Dropdown-menu
  max-height: max-content
