.wrap
  height: 100%
  display: flex
  align-items: center
  justify-content: center

.unt-button-test
  min-width: 300px
  min-height: 60px
  font-family: 'Nunito', sans-serif
  font-size: 22px
  text-transform: uppercase
  letter-spacing: 1.3px
  font-weight: 700
  color: #313133
  background: #4FD1C5
  background: linear-gradient(90deg, rgba(129, 230, 217, 1) 0%, rgba(79, 209, 197, 1) 100%)
  border: none
  border-radius: 1000px
  box-shadow: 12px 12px 24px rgba(79, 209, 197, .64)
  transition: all 0.3s ease-in-out 0s
  cursor: pointer
  outline: none
  position: relative
  padding: 10px

.unt-button-test-math-literacy
  min-width: 300px
  min-height: 60px
  font-family: 'Nunito', sans-serif
  font-size: 22px
  text-transform: uppercase
  letter-spacing: 1.3px
  font-weight: 700
  color: #313133
  background-color: #FBAB7E
  background-image: linear-gradient(62deg, #FBAB7E 0%, #F7CE68 100%)
  border: none
  border-radius: 1000px
  box-shadow: 0 4px 10px rgba(251, 171, 126, 0.5), 0 8px 20px rgba(247, 206, 104, 0.4)
  transition: all 0.3s ease-in-out 0s
  cursor: pointer
  outline: none
  position: relative
  padding: 10px

.unt-button-control-work
  min-width: 300px
  min-height: 60px
  font-family: 'Nunito', sans-serif
  font-size: 22px
  text-transform: uppercase
  letter-spacing: 1.3px
  font-weight: 700
  color: #313133
  background: #3399ff
  background: linear-gradient(90deg, rgba(51, 153, 255, 1) 0%, rgba(77, 166, 255, 1) 100%)
  border: none
  border-radius: 1000px
  box-shadow: 12px 12px 24px rgba(128, 187, 255, .64)
  transition: all 0.3s ease-in-out 0s
  cursor: pointer
  outline: none
  position: relative
  padding: 10px

.unt-button-test:disabled, .unt-button-control-work:disabled, .unt-button-test-math-literacy:disabled
  background: #ccc
  color: #666
  cursor: not-allowed
  pointer-events: none
  opacity: 0.7
  box-shadow: none

  &.loading
    cursor: wait

  &.before
    content: ''
    border-radius: 1000px
    min-width: calc(300px + 12px)
    min-height: calc(60px + 12px)
    border: 6px solid #00FFCB
    box-shadow: 0 0 60px rgba(0, 255, 203, .64)
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    opacity: 0
    transition: all .3s ease-in-out 0s

.unt-button-test:hover, .unt-button-test:focus, .unt-button-control-work:hover, .unt-button-control-work:focus, .unt-button-test-math-literacy:hover, .unt-button-test-math-literacy:focus
  color: #313133
  transform: translateY(-6px)

  &.hover::before, &.focus::before
    opacity: 1

  &.hover::after, &.focus::after
    animation: none
    display: none
