.task-list-dashboard
  display: flex
  flex-direction: column
  align-items: center

  .grade-select-wrapper
    width: 271px
    margin-bottom: 21px

    .grade-select
      border: 1px solid #D3D3D3
      box-sizing: border-box
      border-radius: 30px
      background-color: #FFFFFF
      background-image: url(../../images/ic-select.png)
      background-position-y: 19px
      font-size: 15px

    .Dropdown-control
      border-radius: 30px !important
      border: 1px solid #D3D3D3 !important
      padding: 11px 27px !important
      color: #727272 !important

  .lesson-list-container
    .chapter-list
      margin: 0 100px


