.add-or-edit-variable-modal
  .range-container
    display: flex
    margin-bottom: 1rem
    margin-top: 1rem

    .input
      width: 150px
      margin-left: 4px
      margin-right: 4px

    .generate-button
      margin-top: 18px

  .variable-list-container
    margin-left: 1rem

    .value-item
      margin-bottom: 8px

      .value
        font-size: 20px
        margin-right: 8px

      .remove-value-button
        padding: 0 6px
