.pupil-page
  display: flex
  flex-direction: column
  align-items: center

  .olympiad-panel
    background-image: url("../../images/olympiad_image.png")
    min-height: 150px
    //max-width: 100%
    background-repeat: no-repeat
    background-size: contain

  .olympiad-panel-rus
    background-image: url("../../images/olympiad_image_rus.png")
    min-height: 150px
    //max-width: 100%
    background-repeat: no-repeat
    background-size: contain

  .mobile-panel
    background-image: url("../../images/mobile_image.png")
    height: 170px
    width: 100%
    background-repeat: no-repeat
    background-size: cover
    background-position: center

  .mobile-panel-rus
    background-image: url("../../images/mobile_image_rus.png")
    height: 170px
    width: 100%
    background-repeat: no-repeat
    background-size: cover
    background-position: center

  .page-body
    width: 100%
    max-width: 1246px
    display: flex
    justify-content: center
    flex-direction: column

    .page-body-content
      padding-top: 27px
      padding-bottom: 2rem
      background-color: #F8F8F8
      display: flex
      justify-content: center
      flex-direction: column

@media screen and (max-width: 1060px)
  .pupil-page
    .mobile-panel, .mobile-panel-rus
      height: 145px

@media screen and (max-width: 880px)
  .pupil-page
    .mobile-panel, .mobile-panel-rus
      height: 120px

@media screen and (max-width: 700px)
  .pupil-page
    .mobile-panel, .mobile-panel-rus
      height: 95px

@media screen and (max-width: 600px)
  .pupil-page
    .page-body
      width: 100%

    .olympiad-panel
      background-image: url("../../images/olympiad_image_mobile.png")
      min-height: 120px
      background-repeat: no-repeat
      background-size: cover
      width: 100%

    .olympiad-panel-rus
      background-image: url("../../images/olympiad_image_mobile_rus.png")
      min-height: 120px
      background-repeat: no-repeat
      background-size: cover
      width: 100%

    .mobile-panel
      background-image: url("../../images/qalan_mobile_image_mobile.png")
      min-height: 100px
      background-repeat: no-repeat
      width: 100%
      background-position: center

    .mobile-panel-rus
      background-image: url("../../images/qalan_mobile_image_mobile_rus.png")
      min-height: 120px
      background-repeat: no-repeat
      background-size: cover
      width: 100%


.pupil-math-page
  .page-body
    .page-body-content
      padding-top: 14px !important

      .select-wrapper
        width: 160px
        margin: 0 auto
        display: none
        margin-bottom: 1rem !important
        background-color: transparent

@media screen and (max-width: 1024px)
  .pupil-math-page
    .select-wrapper
      display: block !important


