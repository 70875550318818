.unt-input-container
  display: flex
  align-items: center

  .form-label
    color: red
    font-size: 16px
    margin-bottom: 10px

  .input-field
    padding: 8px
    border: 1px solid #ccc
    border-radius: 4px
    font-size: 16px
    box-sizing: border-box

