.sales-tv
  width: 100%
  height: 98%
  background: #fafafa

  span
    color: black
    font-weight: bold
    font-size: 1.5vw

  .title
    text-align: center
    font-size: 1.4vw
    font-weight: bold
    color: black
    width: 100%

    span
      font-size: 1.8vw
      color: white

  .main-board
    width: 100%
    height: 80%

    .logo-blue
      background-image: url(../../images/logo-full-blue.png)
      background-repeat: no-repeat
      width: 223px
      height: 74px

    .global-rating
      background: #fafafa
      border-radius: 1vw
      width: 100%
      height: 100%
      display: flex
      margin-bottom: 0.5vw

      .plan-fact-board
        width: 32%
        height: 100%
        background: #1e307c
        display: flex
        flex-direction: column

        .plan-fact
          margin-top: 5%
          font-weight: bold
          color: white
          font-size: 1.2vw

        .span
          color: #1E307C

      .rating-tab
        width: 68%
        height: 100%
        display: flex
        flex-direction: column

        .total-sum
          border-top: 7px solid #1E307C
          border-left: 7px solid #1E307C
          border-right: 7px solid #1E307C

          span
            margin-top: 7%
            font-size: 2.5vw

        .secondary-rating-tab
          width: 100%
          height: 100%
          display: flex

          .command-rating
            width: 62%
            padding: 20px
            display: flex
            flex-direction: column
            text-align: center
            align-items: center
            border-bottom: 7px solid #1E307C
            border-left: 7px solid #1E307C

            .command-span
              font-size: 1.4vw

            span
              font-size: 1.4vw

            span
              font-size: 2vw
              color: black

          .managers-rating
            width: 38%
            padding: 20px
            display: flex
            flex-direction: column
            align-items: center
            border-bottom: 7px solid #1E307C
            border-right: 7px solid #1E307C

            .manager-span
              font-size: 1.4vw
              color: black

    .golden-star
      background-image: url(../../images/ic-golden-star.svg)
      background-repeat: no-repeat
      margin-top: 1%
      width: 67px
      height: 66px

  .departments-rating
    padding: 1vw
    background: #fafafa
    width: 98%
    height: 18%
    bottom: 0
    display: flex
    flex-wrap: nowrap
    justify-content: flex-start

    .department
      background: #fafafa
      width: 33.33%
      height: 90%
      display: flex
      flex-direction: column
      align-items: center
      border: 7px solid #1E307C

      .circle
        width: 1.5vw
        height: 1.5vw
        font-size: 0.9vw
        line-height: 1.5vw

      .rectangle
        height: 1.5vw
        border: none

        span
          line-height: 1.3vw
          font-size: 1vw

.place
  margin: 1%
  width: 98%
  display: flex

  .circle
    font-weight: bold
    width: 2vw
    height: 2vw
    border-radius: 50%
    border: 5px solid black
    font-size: 1.5vw
    color: black
    text-align: center
    line-height: 2.5vw

  .rectangle
    width: 90%
    height: 2.5vw
    border-top: 0.05px solid black
    border-right: 0.05px solid black
    border-bottom: 0.05px solid black
    border-bottom-left-radius: 2vw
    border-top-left-radius: 2vw

    span
      text-align: center
      display: table-cell
      color: black
      line-height: 2.4vw
      vertical-align: middle

.white-star
  background-image: url(../../images/ic-white-star.svg)
  background-repeat: no-repeat
  width: 47px
  height: 47px

.blue-star
  background-image: url(../../images/ic-blue-star.svg)
  background-repeat: no-repeat
  width: 47px
  height: 47px

.command-rating-table
  width: 98%
  height: 96%
  padding: 10px
  background: #fafafa

  span
    color: black
    font-weight: bold
    font-size: 1.5vw

  .title
    text-align: center
    font-size: 4vw
    font-weight: bold
    color: #1E307C
    width: 100%

    span
      font-size: 1.8vw
      color: white

  .command-main-board
    .table
      thead
        background-color: #1E307C

        tr
          width: 100%
          display: flex

        th
          text-align: center
          font-size: 3vw
          font-weight: bold
          color: white
          width: 100%

      tbody
        background: #fafafa

        tr
          width: 100%
          display: flex

        .td1
          td
            text-align: center
            font-size: 2vw
            font-weight: bold
            color: black
            width: 100%
            border: 1px solid black

        .td2
          td
            text-align: center
            font-size: 2vw
            font-weight: bold
            color: black
            width: 100%
            border: 1px solid black

@media screen and (min-width: 3840px) and (min-height: 2160px)
  .command-rating
    width: 39%
    padding: 20px
    display: flex
    flex-direction: column
    text-align: center
    align-items: center
    border: 7px solid #1E307C

    .command-span
      margin-top: 4% !important
      font-size: 1.4vw

  .managers-rating
    width: 27%
    padding: 20px
    display: flex
    flex-direction: column
    align-items: center
    border: 7px solid #1E307C

    .manager-span
      margin-top: 4% !important
      font-size: 1.4vw
      color: black

  .golden-star
    background-image: url(../../images/ic-golden-star.svg)
    background-size: 150px
    background-repeat: no-repeat
    max-width: 100%
    max-height: 100%
    width: 150px !important
    height: 150px !important








