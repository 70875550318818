.add-self-work-modal
  color: $black
  border-radius: 20px
  display: flex
  flex-direction: column
  height: 100%

  .modal-header
    background-color: #F6F6F6
    border-bottom: 1px solid #DCDCDC
    display: flex
    justify-content: flex-end

  .modal-body
    padding: 1.5rem
    overflow-x: auto
    height: 100%

    .modal-body-content
      display: flex
      flex-direction: column
      align-items: center

      .teacher-lessons-select
        width: 360px

      .date-time-picker
        margin: 1rem 0

      .react-datepicker__portal
        .react-datepicker
          border-width: 1px 0

      .blue-percent-select
        width: 70px

      .my-table
        margin-top: 2rem
        margin-bottom: 2rem
