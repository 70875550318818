.header
  display: flex
  background: rgba(38, 89, 136, 0.95)
  justify-content: center
  width: 100%

  .header-inner
    display: flex
    width: 1120px
    justify-content: space-between

  .header-left
    display: flex
    align-items: center

    .separator
      margin: 0 20px
      width: 0
      border-right: 1px solid rgba(255, 255, 255, 0.28)
      height: 35px

    .web-site-info
      font-size: 16px
      line-height: 19px
      color: #FFFFFF
      text-shadow: -1px 2px 0px rgba(0, 0, 0, 0.15)
      width: 250px

  .header-right
    display: flex
    align-items: center

    .contact-number-container
      display: flex
      align-items: center

      .phone-icon
        background-image: url(../../images/ic-phone.svg)
        width: 24px
        height: 24px

      .phone-number
        font-weight: bold
        font-size: 1rem
        line-height: 1.5
        color: #FFFFFF
        margin-left: 1rem

        .phone-number-prefix
          color: #2D9CDB

    .language-select-wrapper
      margin-left: 3rem

      .language-item
        color: #FFFFFF


