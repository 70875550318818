.pagination-panel
  display: flex
  flex-direction: column
  list-style-type: none
  justify-content: center
  width: 100%
  background-color: #F3F3F3

  .pagination-navbar
    display: flex
    list-style-type: none
    justify-content: center

    .pagination-item
      display: flex
      flex-direction: column
      align-items: center
      cursor: pointer
      padding: 0 6px

      &.selected
        cursor: default
        border-bottom: 1px solid #2C3B41
