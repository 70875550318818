.radio-without-circle
  appearance: none

.pupil-details-button
  mask-image: url("../../images/ic-view-details.svg")
  width: 63px
  height: 31.5px
  mask-size: 100%
  cursor: pointer
  background-color: rgba(38, 89, 136, 0.95)
  flex-shrink: 0

.pupil-show-phone-number-button
  mask-image: url("../../images/ic-view-details.svg")
  width: 40px
  height: 25px
  mask-size: 100%
  cursor: pointer
  background-color: rgb(62, 159, 20)
  flex-shrink: 0

  &:hover
    background-color: rgba(1, 112, 175, 0.8)

.tab-list
  display: flex
  align-self: center

  .tab-item
    width: 264px
    background: #F8F8F8
    border: 1px solid #D3D3D3
    box-sizing: border-box
    border-radius: 30px
    padding: 7px 0
    text-align: center
    font-weight: 500
    font-size: 15px
    line-height: 24px
    text-transform: uppercase
    color: #727272
    cursor: pointer

    &:hover
      border: 1px solid $default-background
      color: $default-background

    &.active
      background: linear-gradient(180deg, rgba(33, 130, 185, 0.5) 0%, rgba(255, 255, 255, 0) 100%), rgba(1, 112, 175, 0.8)
      color: #FFFFFF
      cursor: default
      border: none

    & + .tab-item
      margin-left: 12px

.tab
  display: flex
  flex-direction: column
  align-items: center
  padding-top: 55px
  width: 381px
  align-self: center

@media screen and (max-width: 600px)
  .tab-list
    display: flex
    flex-wrap: wrap
    justify-content: center
    border-bottom: 1px solid #D3D3D3
    width: 90%
    align-self: center

    .tab-item
      margin-bottom: 15px
      width: 100%

      & + .tab-item
        margin-left: 0

      &:last-child
        margin-bottom: 26px

  .tab
    padding-top: 28px
    width: 90%
