.teacher-page
  margin: 0 auto
  display: flex
  flex-direction: column
  align-items: center

  .page-body
    width: 1246px
    display: flex
    justify-content: center
    flex-direction: column

    .page-body-content
      padding-top: 16px
      padding-bottom: 2rem
      background-color: #F8F8F8
      display: flex
      justify-content: center
      flex-direction: column

  .teacher-page-footer
    display: flex
    margin-bottom: 5rem

    .contact-list, .presentation-list
      display: flex
      flex-direction: column
      margin: 1rem

  .it-support-button
    width: 36px
    height: 36px
    position: fixed
    right: 25px
    bottom: 25px
    mask-image: url(../../images/landing/support-icon.png)
    background-color: #1e87f0
    cursor: pointer


@media screen and (max-width: 600px)
  .teacher-page
    .page-body
      width: 100%

      .navigation-panel
        .nav-item, .separator-dot
          display: none
          border-bottom: none

        .nav-item.selected
          display: flex

