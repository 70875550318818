.button-small-font
  font-size: 12px

.personal-study-dashboard
  display: flex
  flex-direction: column
  align-items: center

  .tab-list
    .tab-item
      width: 200px
      display: flex
      justify-content: center
      align-items: center

  .tab
    display: flex
    flex-direction: column
    align-items: center
    width: 100%
    padding-top: 0

  .pupil-details-button
    mask-image: url("../../images/ic-view-details.svg")
    width: 63px
    height: 31.5px
    mask-size: 100%
    cursor: pointer
    background-color: rgba(38, 89, 136, 0.95)
    flex-shrink: 0

    &:hover
      background-color: rgba(1, 112, 175, 0.8)

  .freeze-status
    mask-image: url("../../images/ic-freeze-status.svg")
    width: 30px
    height: 30px
    mask-size: 100%
    flex-shrink: 0
    background-color: #2893ea

  .parent-problem-status
    background-image: url("../../images/ic-parent-problem.svg")
    width: 25px
    height: 25px
    mask-size: 100%
    flex-shrink: 0

  .pupil-problem-status
    background-image: url("../../images/ic-pupil-problem.svg")
    width: 25px
    height: 25px

  .diagnostic-status
    background-image: url("../../images/ic-diagnostic-unpassed-status.svg")
    width: 30px
    height: 30px
    mask-size: 100%
    flex-shrink: 0

  .diagnostic-status-passed
    background-image: url("../../images/ic-diagnostic-passed-status.svg")
    width: 30px
    height: 30px
    mask-size: 100%
    flex-shrink: 0

  .pupil-details-button-return-requested
    background-color: red

  .personal-study-tab, .diagnostics-tab
    width: 100%

    .filter-container
      display: flex
      flex-direction: column
      align-items: center

    .my-table
      margin-top: 3rem
      width: 100%

      th
        white-space: normal

  .chat-message-list-button
    mask-image: url("../../images/ic-chat-message.svg")
    width: 25px
    height: 23px
    mask-size: 100%
    cursor: pointer
    background-color: rgba(38, 89, 136, 0.95)
    mask-repeat: no-repeat
    flex-shrink: 0

    &:hover
      background-color: rgba(1, 112, 175, 0.8)

    &.parent-chat
      background-color: green

  .comment-list-button
    mask-image: url("../../images/ic-note.svg")
    width: 23px
    height: 23px
    mask-size: 100%
    cursor: pointer
    background-color: rgba(38, 89, 136, 0.95)
    margin-right: 1rem
    mask-repeat: no-repeat
    flex-shrink: 0

    &:hover
      background-color: rgba(1, 112, 175, 0.8)

  .comment-list-exists
    background-color: green

    .chat-popup
      width: auto
      background: none
      padding: 0
      top: initial
      bottom: -20px
      left: 40px

    .chat
      position: initial

      .chat-header
        display: flex
        align-items: center
        color: white
        justify-content: center
        padding-top: 12px

        .close-button
          padding-top: 0

      .message-input-box
        width: 100%


.math-course
  color: black
  background: orange

.unt-course
  color: black
  background: lightgreen

.english-course
  background: purple


