.div-changes
  &.changed
    border: 1px solid red
  &.changed-white
    border: 1px solid white

.my-table
  border-radius: 10px 10px 0 0
  border-collapse: collapse
  overflow: hidden
  width: 100%

  .sales-rating
    &:first-child
      background-color: #92d050
      color: black

    &:nth-child(2)
      background-color: #ffc000
      color: black

    &:nth-child(3)
      background-color: #ffff00
      color: black

  thead
    background-color: #265988

  .nowrap
    white-space: nowrap

  tr:nth-child(2n)
    background: #EFEFEF
    border: 1px solid #DCDCDC
    box-sizing: border-box

  th
    padding: 9px 8px 6px
    font-weight: 500
    font-size: 15px
    height: 40px
    text-transform: uppercase
    color: #FFFFFF
    text-align: center
    position: relative
    white-space: nowrap
    vertical-align: middle

    &::after
      content: ''
      background-color: #9D9D9D
      position: absolute
      left: 100%
      top: 24px
      bottom: 20px
      width: 1px

  @media screen and (max-width: 600px)
    th
      font-size: 8px

  td
    text-align: center
    font-size: 15px
    padding: 25px 0 20px

  @media screen and (max-width: 600px)
    td
      font-size: 8px

  .left
    text-align: left
    padding-left: 2rem

@media screen and (max-width: 600px)
  .left
    padding-left: 19px !important
