.pricing-page
  .page-body-content
    align-items: center

  .tariff-list
    display: flex
    align-items: center
    .tariff-item
      display: flex
      flex-direction: column
      border-radius: 10px
      width: 200px
      margin: 8px
      background-color: white
      box-shadow: rgba(0, 0, 0, 0.15) 0 4px 4px 0
      text-align: center
      font-size: 18px

      .tariff-item-price
        background-color: rgba(38, 89, 136, 0.95)
        border-radius: 10px 10px 0 0
        text-align: center
        color: white
        font-size: 18px
        padding: 8px 0

    .tariff-item-description
      display: flex
      flex-direction: column
      justify-content: center
      color: #353535
      height: 120px

      &.free-tariff-description
        height: 160px

    .buy-subscription-button
      border-radius: 0 0 10px 10px

@media screen and (max-width: 600px)
  .pricing-page
    .tariff-list
      flex-direction: column

