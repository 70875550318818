.select
  background: #F8F8F8 url(../../images/ic-select.png) no-repeat scroll right 9px center
  background-size: 7px 4px
  background-position-y: 9px
  border: 1px solid #225482
  display: flex
  align-items: center
  justify-content: center
  box-sizing: border-box
  font-weight: 500
  border-radius: 30px
  font-size: 12px
  color: #2E5F8C

  .Select-control
    background: #FFFFFF
    border: 1px solid #D3D3D3
    box-sizing: border-box
    border-radius: 20px
    padding-left: 15px
    padding-right: 15px

    .Select-value, .Select-placeholder
      padding-left: 27px

.Dropdown-control
  border-radius: 30px !important
  border: 1px solid #225482
  padding: 3px 27px !important
  color: #2E5F8C !important

.Dropdown-arrow
  display: none !important

.Dropdown-menu
  border-radius: 5px
  max-height: max-content
