.jitsi-meet
  position: fixed
  margin: 0 auto
  left: 0
  right: 0
  bottom: 0
  width: 700px
  height: 240px
  z-index: 1000
  display: none

  &.opened
    display: block
  &.fullscreen-enabled
    width: 100%
    height: 100%

  .fullscreen-button
    position: fixed
    z-index: 1001
    display: none

@media screen and (max-width: 600px), screen and (max-height: 600px)
  .jitsi-meet
    width: 100%
    height: 240px

    &.opened
      .fullscreen-button
        display: block
