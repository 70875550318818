.navigation-panel
  display: flex
  flex-direction: column
  list-style-type: none
  justify-content: center
  width: 100%
  background-color: #F3F3F3

  .horizontal-line
    height: 7px
    width: 100%
    background: linear-gradient(180deg, #C3C6CB 0%, rgba(136, 136, 136, 0) 100%)

  .navbar
    display: flex
    list-style-type: none
    justify-content: center
    padding-top: 19px
    padding-bottom: 9px
    padding-left: 0

    .nav-item
      display: flex
      flex-direction: column
      align-items: center
      cursor: pointer
      padding: 0 6px

      &:hover
        .nav-icon
          background-color: #31618E

        .nav-item-link
          color: #31618E
          text-decoration: none

      &.selected
        cursor: default
        border-bottom: 1px solid #2C3B41

        .nav-icon
          background-color: #2C3B41

        .nav-item-link
          color: #2C3B41
          font-weight: 500
          cursor: default

      .nav-item-link
        font-size: 15px
        line-height: 27px
        color: #727272
        white-space: nowrap

    .separator-dot
      border-radius: 50%
      height: 3px
      width: 3px
      background: #1E307C
      margin: 35px 3px 0
      flex-shrink: 0

.nav-icon
  width: 20px
  height: 20px
  background-color: #727272
  mask-repeat: no-repeat

  &.ic-profile
    mask-image: url("../../images/ic-profile.svg")

  &.ic-rating
    mask-image: url("../../images/ic-rating.svg")

  &.ic-diagnostics
    mask-image: url("../../images/ic-diagnostics.svg")

  &.ic-personal-study
    mask-image: url("../../images/ic-personal-study.svg")

  &.ic-service-dashboard
    mask-image: url("../../images/ic-service-dashboard.svg")

  &.ic-sbb-dashboard
    mask-image: url("../../images/ic-sbb-dashboard.svg")

  &.ic-sales-dashboard
    mask-image: url("../../images/ic-sales-dashboard.svg")

  &.ic-finance-dashboard
    mask-image: url("../../images/ic-finance-dashboard.svg")

  &.ic-marketing-dashboard
    mask-image: url("../../images/ic-marketing-dashboard.svg")

  &.ic-pi
    mask-image: url("../../images/ic-pi.svg")

  &.ic-self-works
    mask-image: url("../../images/ic-self-works.svg")

  &.ic-subscription
    mask-image: url("../../images/ic-subscription.svg")

  &.ic-pupil-list
    mask-image: url("../../images/ic-pupil-list.svg")

  &.ic-task-list
    mask-image: url("../../images/ic-task-list.svg")

  &.ic-high-view
    mask-image: url("../../images/ic-high-view.svg")

  &.ic-monitoring
    mask-image: url("../../images/ic-monitoring.svg")

  &.ic-control-works
    mask-image: url("../../images/ic-control-works.svg")

  &.ic-constructor
    mask-image: url("../../images/ic-constructor.svg")

  &.ic-personal-study
    mask-image: url("../../images/ic-personal-study.svg")

  &.ic-edit
    mask-image: url("../../images/ic-edit.svg")

  &.ic-three-dots
    mask-image: url("../../images/ic-three-dots.svg")

  &.ic-hr
    mask-image: url("../../images/ic-hr.svg")

@media screen and (max-width: 600px)
  .navigation-panel
    .navbar
      overflow-x: auto
      margin-bottom: 0


