.page-header
  width: 100%
  height: 54px
  background: $default-background
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15)
  display: flex
  justify-content: center

  .header-inner
    width: 1246px
    display: flex
    justify-content: space-between
    align-items: center
    position: relative

  .left-side
    display: flex
    align-items: center
    height: 100%

    .user-menu-block
      position: relative
      display: flex
      cursor: pointer
      height: 100%
      color: #FFFFFF

      &.active
        background: $default-background

        .user-fullname
          color: #FFFFFF
          display: flex
          flex-direction: column

      .user-menu-popup
        position: absolute
        background: $default-background
        width: 100%
        top: $header-height
        left: 0
        box-sizing: border-box
        z-index: 100
        padding: 0.5rem 0
        cursor: default

        .user-menu-item
          padding: 1rem 1.5rem

          a
            color: $white

    .ic-hamburger
      background: url("../../images/ic-hamburger.png")
      width: 31px
      height: 31px
      margin-left: 16px
      margin-top: 10px

    .header-profile
      display: inline-flex
      align-items: center
      max-width: 220px
      padding-left: 24px
      padding-right: 24px

      .down-arrow-icon
        background-image: url(../../images/ic-down-arrow.png)
        width: 9px
        height: 5px

    .user-avatar
      background-image: url(../../images/user-avatar.png)
      width: 28px
      height: 28px

    .user-fullname
      font-size: 14px
      line-height: 100%
      color: #F2F2F2
      margin-left: 12px
      margin-right: 16px

    .language-select
      overflow: hidden

  .logo
    background: url(../../images/logo-full.svg) no-repeat scroll center center
    background-size: 100%
    width: 125px
    height: 45px
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1))
    flex-shrink: 0
    position: absolute
    left: 0
    right: 0
    margin-left: auto
    margin-right: auto

  .right-side
    display: flex
    align-items: center
    justify-content: space-between
    padding: 1rem
    box-sizing: border-box

    .rating-points
      margin-left: 27px
      margin-right: 34px
      font-size: 14px
      line-height: 24px
      color: #FFFFFF

    .teacher-code
      margin-left: auto
      margin-right: 30px
      font-weight: 500
      font-size: 14px
      color: #FFFFFF

    .slider
      width: 112px
      margin-right: 7px

@media screen and (max-width: 600px)
  .page-header
    height: 50px

    .slider, .grade-select, .teacher-code
      display: none

    .rating-points
      margin-right: 0 !important
      margin-left: auto !important

    .down-arrow-icon
      display: none

    .left-side
      .language-select
        display: none

      .user-menu-block
        .user-menu-popup
          width: 300px
          background: #FFFFFF
          top: 0
          height: 100vh
          padding-left: 16px
          padding-top: 10px
          padding-right: 16px

          .popup-header
            display: flex
            justify-content: space-between
            align-items: center

            .ic-close
              background: url("../../images/ic-close.svg")
              width: 25px
              height: 25px
              background-size: 100%

            .select-language-text
              font-size: 14px
              line-height: 20px
              color: #727272
              margin-right: 12px

            .language-select
              display: block
              background-color: #FFFFFF

          .current-user
            margin-top: 23px
            display: flex
            align-items: center
            padding-bottom: 20px
            border-bottom: 1px solid #B6B6B6

            .user-avatar
              background: url("../../images/user-avatar-black.png")
              width: 28px
              height: 28px

            .user-fullname
              display: flex
              flex-direction: row
              font-weight: 500
              font-size: 16px
              line-height: 14px
              color: #353535


          .nav-menu
            display: flex
            flex-direction: column
            border-bottom: 1px solid #B6B6B6

            .nav-menu-item
              padding-top: 15px
              padding-bottom: 15px
              display: flex
              align-items: center

          .logout-menu-item
            margin-top: 10px
            padding-top: 10px
            padding-bottom: 10px
            display: flex
            align-items: center

            .ic-logout
              background: url("../../images/ic-logout.png")
              width: 22px
              height: 22px

          .link
            font-size: 16px
            line-height: 14px
            color: #727272
            margin-left: 1rem



