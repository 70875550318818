.button
  font-family: Roboto, sans-serif
  padding: 9px 32px
  border-radius: 30px
  font-weight: 500
  font-size: 15px
  line-height: 23px
  text-transform: uppercase
  color: #FFFFFF
  text-align: center
  background: linear-gradient(180deg, rgba(33, 130, 185, 0.5) 0%, rgba(255, 255, 255, 0) 100%), rgba(1, 112, 175, 0.8)
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2)
  border: none
  cursor: pointer
  box-sizing: border-box
  -webkit-tap-highlight-color: transparent

  a
    color: #FFFFFF
    text-decoration: none

  &:hover
    background: linear-gradient(180deg, rgba(3, 55, 85, 0.5) 0%, rgba(255, 255, 255, 0) 100%), rgba(1, 112, 175, 0.8)

  &:active, &.selected
    background: linear-gradient(180deg, rgba(3, 55, 85, 0.5) 0%, rgba(255, 255, 255, 0) 100%), rgba(1, 112, 175, 0.8)
    box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25)

  &:disabled
    background: white
    border: 1px solid #D3D3D3
    color: #999
    pointer-events: none

  &.loading
    cursor: wait

  &:focus
    outline: none

  &.button-danger
    background: #a40303
    border: 1px solid #D3D3D3
    color: #ffffff

  &.button-secondary
    background: #F8F8F8
    border: 1px solid #D3D3D3
    color: #353535

    a
      color: #353535

    &:hover
      background: #E4E4E4

    &:active:focus
      background: #E4E4E4
      box-shadow: inset 0 4px 4px rgba(0, 0, 0, 0.25)

    &:disabled
      background: white
      border: 1px solid #D3D3D3
      color: #999
      pointer-events: none

  &.add-button
    font-weight: normal
    padding: 6px 45px
    text-transform: none

    &:before
      content: url("../../images/ic-add.png")
      vertical-align: text-top
      margin-right: 23px
      display: inline-block
      width: 15px
      height: 15px

  &.edit-button
    @extend .button-secondary
    font-weight: normal
    padding: 6px 25px
    text-transform: none

    &:before
      content: url("../../images/ic-edit.svg")
      display: inline-block
      width: 20px
      height: 20px
      vertical-align: middle
      margin-right: 13px

  &.cancel-button
    @extend .button-secondary
    font-weight: normal
    padding: 6px 25px
    margin-left: 13px
    text-transform: none

    &.sbb-delete
      padding: 0px
      margin-left: 0px
      width: 22px
      height: 25px

    &:before
      content: url("../../images/ic-cancel.png")
      display: inline-block
      width: 20px
      height: 20px
      vertical-align: middle
      margin-right: 23px

  &.button-small
    padding: 0 10px

.refuse-button
  font-family: Roboto, sans-serif
  padding: 9px 32px
  border-radius: 30px
  font-weight: 500
  font-size: 15px
  line-height: 23px
  text-transform: uppercase
  color: #FFFFFF
  text-align: center
  border: none
  cursor: pointer
  box-sizing: border-box
  background-color: #e61919

  &:hover
    background: #ec5252

  &:disabled
    background: #FA8072
    pointer-events: none

.close-button
  padding: 19px
  cursor: pointer

  .ic-close
    mask-image: url(../../images/ic-close.svg)
    background-color: #313131
    height: 13px
    width: 13px
    background-size: 100%

  &:hover
    .ic-close
      background-color: black


.ukk-button
  margin: 0
  border: none
  overflow: visible
  font: inherit
  color: inherit
  text-transform: none
  display: inline-block
  box-sizing: border-box
  padding: 0 30px
  vertical-align: middle
  font-size: 16px
  line-height: 40px
  text-align: center
  text-decoration: none

  &.ukk-button-primary
    background: linear-gradient(180deg, rgba(33, 130, 185, 0.5) 0%, rgba(255, 255, 255, 0) 100%), rgba(1, 112, 175, 0.8)
    color: #fff

  &.ukk-button-primary:hover,
  &.ukk-button-primary:focus
    background: linear-gradient(180deg, rgba(3, 55, 85, 0.5) 0%, rgba(255, 255, 255, 0) 100%), rgba(1, 112, 175, 0.8)
    color: #fff

  &.ukk-button-primary:active,
  &.ukk-button-primary.uk-active
    background: #0f7ae5
    color: #fff

  &:disabled, &:disabled:hover, &:disabled:focus
    background: #f8f8f8
    color: #999

.label-status
  background: linear-gradient(180deg, rgba(33, 130, 185, 0.5) 0%, rgba(255, 255, 255, 0) 100%), rgba(1, 112, 175, 0.8)
  color: #fff
  z-index: 0

.dropdown-menu 
  position: absolute
  top: 100%
  left: 0
  border: 1px solid #ddd
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1)
  padding: 10px
  background-color: white
  z-index: 100

.dropdown-menu-item
  padding: 5px 10px
  cursor: pointer
  text-align: left
  transition: transform 0.3s

.dropdown-menu-item:hover
  background-color: rgba(149, 149, 149, 0.57)
