@media screen and (max-width: 600px), screen and (max-height: 600px), (hover: none) and (pointer: coarse)
  .header
    .header-inner
      width: 100%
      justify-content: space-between
      flex-direction: column
      align-items: center

    .header-left
      .logo
        width: 107px
        height: 35px
        background-size: 100%

      .separator
        margin: 0 10px

      .web-site-info
        font-size: 12px
        line-height: 14px
        width: 160px

    .header-right
      margin-top: 16px

      .contact-number-container
        .phone-icon
          width: 16px
          height: 16px

        .phone-number
          font-size: 12px
