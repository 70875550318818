.register-page
  display: flex
  flex-direction: column

  .header
    padding: 4px 0
    align-self: center

  .page-body
    display: flex
    flex-direction: column
    align-items: center
    align-self: center
    width: 80%
    background: #F8F8F8

    .registration-new-user-section
      background: #F3F3F3
      width: 100%
      text-align: center
      padding: 40px 0 30px
      font-weight: bold
      font-size: 24px
      line-height: 100%
      text-transform: uppercase
      color: #2E5F8C

    .horizontal-line
      height: 7px
      background: linear-gradient(180deg, #C3C6CB 0%, rgba(136, 136, 136, 0) 100%)
      width: 100%

    .register-form
      align-self: center
      background: none
      display: flex
      flex-direction: column
      align-items: center
      margin-bottom: 2rem

      .input
        margin-top: 1rem
        width: 381px
        margin-left: 5px
        margin-right: 5px

      .passwords
        margin-top: 35px
        display: flex
        flex-direction: column
        width: 100%

      .input-password
        background-color: #FFFFFF
        margin-left: 0
        margin-right: 0

      .input-register-phone-number
        .flag-dropdown
          color: black
          border: none
          background: inherit

          .selected-flag
            background: inherit
            border: none

          .country-list
            width: 250px

        .form-control
          width: 223px
          border: none
          background: inherit
          color: black

      .pupil-or-teacher-chooser
        margin-top: 25px
        display: flex
        justify-content: space-around
        width: 100%

        label
          cursor: pointer

      .button-list
        margin-top: 35px
        display: flex
        flex-direction: column
        align-items: center

        .register-button
          width: 247px

        .back-link
          font-size: 15px
          line-height: 24px
          color: #A5A5A5
          margin-top: 19px

@media screen and (max-width: 600px), screen and (max-height: 600px), (hover: none) and (pointer: coarse)
  .register-page
    .page-body
      width: 100%

      .register-form
        width: 90%

        .passwords
          margin-top: 24px

        .input
          width: 100%

