.item-container
  position: relative

  .dot1
    position: absolute
    top: 50%
    right: -10px
    width: 10px
    height: 10px
    background-color: gray
    border-radius: 50%
    transform: translateY(-50%)
    cursor: pointer

    &:hover
      background-color: blue

  .dot2
    position: absolute
    top: 50%
    left: -10px
    width: 10px
    height: 10px
    background-color: gray
    border-radius: 50%
    transform: translateY(-50%)
    cursor: pointer

    &:hover
      background-color: blue

  .item
    display: inline-block
    padding-left: 10px
  
  .matching-item
    display: inline-block
  
  .matching-item-container-left
    display: block
    text-align: center
    vertical-align: middle
    border: 1px solid rgb(82, 168, 255)
    border-radius: 10px
    position: relative

  .matching-item-container-right
    display: block
    text-align: center
    vertical-align: middle
    border: 1px solid rgb(82, 168, 255)
    border-radius: 10px
    position: relative
  
  .matching-item-container-left::before 
    content: ""
    position: absolute
    width: 9px 
    height: 9px
    border-radius: 50% 
    background-color: white
    top: 50%
    right: -7px 
    transform: translateY(-50%) 
    border: 2px solid rgb(82, 168, 255) 

  .matching-item-container-right::before 
    content: ""
    position: absolute
    width: 9px 
    height: 9px
    border-radius: 50% 
    background-color: white 
    top: 50% 
    left: -7px 
    transform: translateY(-50%) 
    border: 2px solid rgb(82, 168, 255)