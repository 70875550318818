.kanban
  .board
    display: flex !important
    gap: 20px
    //padding: 20px
    width: 100%

  .column
    background: #f4f4f4
    border-radius: 5px
    padding: 15px
    flex: 1
    width: 300px
    min-height: 500px
    display: flex
    flex-direction: column

    .column-header h5
      margin: 0 0 0 4px
      display: inline-flex

  .column.locked
    opacity: 0.8
    pointer-events: none

  .column
    .task-list
      display: flex
      flex-direction: column
      gap: 10px

      .task-card
        background: #ffffff
        border-radius: 5px
        padding: 15px
        text-decoration: none
        transition: all 0.3s ease
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1)
        user-select: none

      .task-card:hover
        background: #d0d0d0

      .task-card.locked
        background-color: #F8F8F8
        opacity: 0.8

      .task-card.disable-to-move
        cursor: not-allowed

      .task-card
        .badge
          top: 8px
          left: 8px
          font-size: 12px
          font-weight: bold
          padding: 4px 8px
          border-radius: 12px
          text-transform: uppercase
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2)
          display: inline-block
          margin-right: 5px
          color: #fff

        .badge.personal-study
          background-color: red

        .badge.server
          background-color: blue

        .badge.language
          background-color: orange

        .labels
          display: flex
          margin-bottom: 5px

        a
          color: black

          &:hover
            text-decoration: underline

        h4
          margin: 0 0 5px

        h5
          margin: 0 0 5px
          text-align: right

.kanban.modal-overlay
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(0, 0, 0, 0.5)
  overflow-y: hidden

  .modal-card
    display: flex
    flex-direction: column
    border-radius: 4px
    width: 100%

    .modal-header
      display: flex
      justify-content: space-between
      align-items: center
      margin-bottom: 20px

    .modal-header h3
      margin: 0
      display: inline-block

    .modal-header
      .close
        width: 32px
        height: 32px
        background: none
        font-size: 24px
        cursor: pointer

      .close:hover
        background-color: #d0d0d0

    .modal-body
      display: flex
      flex: 1

      .modal-content
        display: flex
        flex: 1
        gap: 20px

        .modal-left
          flex: 1
          width: 70%
          max-width: 70%

          .card-title
            font-size: 24px
            margin-bottom: 12px

          .card-description
            margin-bottom: 16px
            padding: 8px
            cursor: pointer
            transition: background-color 0.2s ease-in-out
            max-width: 100%

            .description-text
              margin: 0
              padding: 4px
              white-space: pre-line

            .description-input
              width: 100%
              border: none
              outline: none
              resize: none
              font: inherit
              padding: 4px
              background: transparent
              height: auto
              min-height: 20px
              max-height: 500px
              line-height: 1.5
              white-space: pre-line

          .card-description:hover
            background-color: #f0f0f0

          .card-description.editing
            border: 2px solid #007bff
            padding: 6px

          .image-container
            position: relative
            border-radius: 8px
            background-color: #fff
            width: 90%
            text-align: center

            img
              width: 100%
              height: auto
              border-radius: 8px

            .dot-navbar
              display: flex
              justify-content: center
              gap: 10px

              .dot
                width: 10px
                height: 10px
                border-radius: 50%
                cursor: pointer
                background-color: #ccc

              .dot.active
                background-color: #000

        .modal-right
          flex: 1
          width: 30%
          max-width: 30%

          .card-details
            display: flex
            flex-direction: column
            gap: 10px
            border: 1px solid #ddd
            padding: 16px
            border-radius: 4px

          .current-state select
            padding: 8px
            font-size: 14px
            width: 100%
            border-radius: 4px
            border: 1px solid #ccc

          .card-meta h5
            margin: 10px 0

    .modal-footer
      display: flex
      justify-content: center
      padding: 16px
