.my-profile-info
  padding-top: 12px
  padding-bottom: 37px
  align-self: center
  display: flex
  flex-direction: column

  .tab
    .select
      margin-bottom: 15px
      width: 100%
      border: none

    .save-button
      width: 213px
      font-size: 15px
      margin-top: 18px

  .code
    margin-bottom: 1rem
    align-self: flex-start

    .label
      font-weight: 500

    .value
      color: black

  .pupil-main-info-tab
    .form-input
      width: 100%
      margin-bottom: 1rem

  .subscribe-to-teacher-tab
    .subscribe-to-teacher-button
      font-size: 15px
      width: 100%

    .my-teachers-text
      font-weight: 500
      font-size: 18px
      line-height: 24px
      text-transform: uppercase
      color: #2C3B41
      margin-top: 38px
      margin-bottom: 10px
      width: 100%
      text-align: center

    .teacher-list
      display: flex
      flex-direction: column
      width: 100%

      .teacher-item
        margin-bottom: 5px
        background: #F8F8F8
        border: 1px solid #D3D3D3
        box-sizing: border-box
        border-radius: 25px
        width: 100%
        font-size: 17px
        line-height: 100%
        color: #727272
        padding: 16px 0 16px 35px

  .change-password-tab
    .input-password
      width: 100%

      & + .input-password
        margin-top: 17px

@media screen and (max-width: 600px)
  .my-profile-info
    padding-top: 0
    padding-bottom: 0







