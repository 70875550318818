.landing-page
  display: flex
  flex-direction: column
  background-image: url(../../images/landing/bg.jpg)
  background-repeat: no-repeat
  z-index: -1

  &.qalan-school
    background-image: url(../../images/landing/ru-bg.jpg)

  .bold
    font-weight: bold

  .button
    padding-top: 12px
    padding-bottom: 12px
    font-size: 18px
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25)

    &:hover
      background: linear-gradient(180deg, rgba(23, 88, 124, 0.5) 0%, rgba(255, 255, 255, 0) 100%), rgba(7, 82, 124, 0.9)
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25)

    &:disabled
      background: #07527C
      box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.55)
      cursor: default
      border: none
      color: #FFFFFF

  .block-1
    background: rgba(0, 0, 0, 0.72)
    position: relative
    display: flex
    flex-direction: column
    align-items: center
    padding-top: 26px

    .header
      background: none

    .temp-login-form
      display: flex
      flex-direction: column
      align-items: center
      margin-top: 120px
      margin-bottom: 80px
      background: rgba(255, 255, 255, 0.1)
      border: 1px solid rgba(255, 255, 255, 0.1)
      box-sizing: border-box
      backdrop-filter: blur(10px)
      border-radius: 10px
      width: 339px
      height: 455px
      color: #FFFFFF
      line-height: 1.5rem
      padding: 43px 35px 0

      .welcome-message
        font-weight: bold
        font-size: 24px
        text-transform: uppercase

      .login-message
        font-size: 14px

      .sign-in-input
        border: none
        border-bottom: 1px solid rgba(255, 255, 255, 0.62)
        color: white
        padding: 10px 0 10px 32px
        width: 100%
        box-sizing: border-box

        &::placeholder
          color: #FFFFFF

        &:focus
          outline: none

        &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active
          transition: background-color 5000s ease-in-out 0s
          -webkit-text-fill-color: #fff !important

      .input-phone-number
        background: url(../../images/ic-phone-call.png) no-repeat scroll 10px center

      .input-password
        background: url(../../images/ic-password.png) no-repeat scroll 10px center
        margin-top: 15px

      .sign-in-button
        width: 100%
        margin-top: 38px

      .register-button
        width: 100%
        margin-top: 9px
        background: rgba(255, 255, 255, 0.04)

        &:hover
          background: rgba(255, 255, 255, 0.15)

      .forgot-password-link
        font-size: 14px
        line-height: 24px
        text-decoration-line: underline
        color: rgba(255, 255, 255, 0.80)
        margin-top: 20px
        margin-left: 0

    @keyframes pulse
      0%
        transform: scale(1)
      50%
        transform: scale(1.1)
      100%
        transform: scale(1)

    .whatsapp-button
      position: fixed
      right: 30px
      bottom: 60px
      transform: translate(-50%, -50%)
      border-radius: 50%
      width: 70px
      height: 70px
      color: #fff
      text-align: center
      line-height: 53px
      font-size: 35px
      z-index: 9999
      transition: transform 0.3s
      animation: pulse 2s infinite

      &:hover
        transform: scale(1.2)

    .whatsapp-icon
      background-image: url("../../images/ic-whatsapp.svg")
      width: 70px
      height: 70px
      mask-size: 100%
      flex-shrink: 0

    .header
      background: none

    .login-form-container
      display: flex
      justify-content: center
      flex-direction: row
      width: 100%

      .video-container
        display: flex
        flex-direction: column
        justify-content: space-between
        position: relative

      .video-header
        position: absolute
        top: 120px
        font-size: 30px
        font-weight: bold
        color: #FFFFFF
        text-shadow: -1px 2px 0px rgba(0, 0, 0, 1.55)

      .iframe-container
        position: relative
        width: 600px
        height: 450px

      .video-container iframe
        position: absolute
        top: 185px
        left: 0
        width: 94%
        height: 92%

      .login-form
        display: flex
        flex-direction: column
        align-items: center
        margin-top: 120px
        margin-left: 2%
        margin-bottom: 80px
        background: rgba(255, 255, 255, 0.1)
        border: 1px solid rgba(255, 255, 255, 0.1)
        box-sizing: border-box
        backdrop-filter: blur(10px)
        border-radius: 10px
        width: 339px
        height: 455px
        color: #FFFFFF
        line-height: 1.5rem
        padding: 35px 35px 0

        .welcome-message
          font-weight: bold
          font-size: 24px
          text-transform: uppercase

        .login-message
          font-size: 14px

        .sign-in-input
          border: none
          border-bottom: 1px solid rgba(255, 255, 255, 0.62)
          color: white
          padding: 10px 0 10px 32px
          width: 100%
          box-sizing: border-box

          &::placeholder
            color: #FFFFFF

          &:focus
            outline: none

          &:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active
            transition: background-color 5000s ease-in-out 0s
            -webkit-text-fill-color: #fff !important

        .input-phone-number
          background: url(../../images/ic-phone-call.png) no-repeat scroll 10px center

        .input-password
          background: url(../../images/ic-password.png) no-repeat scroll 10px center
          margin-top: 15px

        .sign-in-button
          width: 100%
          margin-top: 38px

        .register-button
          width: 100%
          margin-top: 9px
          background: rgba(255, 255, 255, 0.04)

          &:hover
            background: rgba(255, 255, 255, 0.15)

        .forgot-password-link
          font-size: 14px
          line-height: 24px
          text-decoration-line: underline
          color: rgba(255, 255, 255, 0.80)
          margin-top: 20px
          margin-left: 0

  .feature-list-block
    background-color: #FFFFFF

    .feature-list
      display: flex
      flex-direction: column
      align-items: center
      margin-top: 75px
      margin-bottom: 40px

      .feature-list-row
        display: flex
        margin-bottom: 50px

      .feature-item
        width: 280px
        display: flex
        flex-direction: column
        align-items: center
        padding: 2px
        box-sizing: border-box

        & + .feature-item
          margin-left: 68px

        .feature-item-icon
          width: 4rem
          height: 4rem

          &.icon-1
            background-image: url(../../images/landing/job-interview-icon.png)

          &.icon-2
            background-image: url(../../images/landing/exam.png)

          &.icon-3
            background-image: url(../../images/landing/headhunting.png)

          &.icon-4
            background-image: url(../../images/landing/analysis.png)

          &.icon-5
            background-image: url(../../images/landing/square-root.png)

          &.icon-6
            background-image: url(../../images/landing/planing.png)

          &.icon-7
            background-image: url(../../images/landing/exam_2.png)

          &.icon-8
            background-image: url(../../images/landing/alert.png)

        .feature-item-description
          font-size: 1rem
          line-height: 21px
          text-align: center
          color: #353535
          text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
          margin-top: 27px

  .how-it-works-block
    background: linear-gradient(112.27deg, #181818 8.32%, rgba(89, 89, 89, 0) 63.16%), #222C31
    display: flex
    flex-direction: column
    align-items: center
    padding-top: 60px
    padding-bottom: 40px
    position: relative

    .square-root-background
      position: absolute
      left: 40px
      bottom: 0
      background-image: url(../../images/landing/square-root-2.png)
      width: 402px
      height: 390px

    .mathematics-background
      position: absolute
      left: 62%
      top: 0
      background-image: url(../../images/landing/mathematics_1.png)
      width: 371px
      height: 299px

    .hand-drawn-girl-work-background
      position: absolute
      left: 0
      right: 0
      margin-left: auto
      margin-right: auto
      top: 200px
      background-image: url(../../images/landing/hand-drawn-cute-girl-work.png)
      width: 375px
      height: 439px

    .axis-background
      position: absolute
      right: 0
      bottom: 0
      background-image: url(../../images/landing/axis.png)
      width: 489px
      height: 512px

    .block-inner
      display: flex
      flex-direction: column

    .container-header
      display: flex
      margin-left: 7px

      .exclamation-icon
        font-weight: bold
        font-size: 96px
        line-height: 100%
        color: #2D9CDB

      .container-header-info
        margin-left: 39px

        .container-header-title
          font-weight: 300
          font-size: 48px
          line-height: 100%
          color: #FFFFFF
          text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
          text-transform: uppercase

        .container-header-description
          font-weight: 300
          font-size: 18px
          line-height: 100%
          color: #FFFFFF
          width: 650px
          margin-top: 21px

    .container-body
      display: flex
      margin-top: 10px

      .container-body-column
        display: flex
        flex-direction: column

        & + .container-body-column
          margin-left: 360px

        .column-item
          display: flex
          margin-top: 30px

          .column-item-icon
            background: #2D9CDB
            border-radius: 50%
            width: 45px
            height: 45px
            font-weight: bold
            font-size: 36px
            line-height: 42px
            color: #FFFFFF
            margin-top: 36px
            box-sizing: border-box

            .icon-text
              margin-left: 21px
              margin-top: -4px

          .column-item-content
            margin-left: 28px
            margin-top: 6px
            font-size: 18px
            line-height: 140%

            .column-item-content-title
              font-weight: bold
              color: #2D9CDB

            .column-item-content-description
              width: 390px
              font-weight: 300
              color: #FFFFFF

    .register-button
      width: 278px
      margin-top: 2rem

    .register-button-ru
      width: 278px
      margin-top: 2rem
      background-color: red

      &:hover
        text-decoration: none
        color: #5ea4ff

  .b2b-info-block
    background: linear-gradient(112.27deg, #181818 8.32%, rgba(89, 89, 89, 0) 63.16%), #222C31
    display: flex
    align-items: center
    justify-content: center
    padding-top: 60px
    padding-bottom: 40px
    position: relative

    .square-root-background
      position: absolute
      left: 40px
      bottom: 0
      background-image: url(../../images/landing/square-root-2.png)
      width: 402px
      height: 390px

    .container-header
      display: flex
      margin-left: 7px

      .exclamation-icon
        font-weight: bold
        font-size: 55px
        line-height: 100%
        color: #2D9CDB

      .container-header-info
        margin-left: 39px

        .container-header-title
          font-weight: 300
          font-size: 40px
          line-height: 100%
          color: #FFFFFF
          text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25)
          text-transform: uppercase

    .container-body
      display: flex
      margin-top: 10px

      .container-body-column
        display: flex
        flex-direction: column

        & + .container-body-column
          margin-left: 360px

        .column-item
          display: flex
          margin-top: 30px

          .column-item-icon
            background: #2D9CDB
            border-radius: 50%
            width: 45px
            height: 45px
            font-weight: bold
            font-size: 36px
            line-height: 42px
            color: #FFFFFF
            margin-top: 10px
            box-sizing: border-box

            .icon-text
              margin-left: 21px
              margin-top: -4px

          .column-item-content
            margin-left: 28px
            margin-top: 6px
            font-size: 18px
            line-height: 140%

            .column-item-content-description
              width: 390px
              font-weight: 300
              color: #FFFFFF

    .images-with-contact-number
      display: flex
      flex-direction: column
      text-align: center

      span
        color: white

      .images-scroll-block
        position: relative
        width: 700px
        height: 390px
        background-color: white
        border-radius: 10px
        margin-left: 50px
        margin-top: auto

        .img
          border-radius: 10px

          .disable
            display: none

        .slideshow-buttons
          position: absolute
          bottom: 45%
          width: 100%
          display: flex
          justify-content: space-between

          div
            width: 50px
            height: 50px

            &:hover
              opacity: 1

          .left-button
            background-image: url(../../images/ic-chevron-left.svg)
            opacity: 0.5
            left: 0

          .right-button
            background-image: url(../../images/ic-chevron-right.svg)
            opacity: 0.5
            left: 0

  .for-somebody-block
    background: #FFFFFF
    display: flex
    flex-direction: column
    align-items: center
    padding-top: 60px
    padding-bottom: 60px

    .block-inner
      display: flex

      .column
        display: flex
        flex-direction: column

        & + .column
          margin-left: 60px

        .column-header
          display: flex

          .column-header-icon
            width: 55px
            height: 55px

            &.for-teacher-icon
              background-image: url(../../images/landing/professor.png)

            &.for-parents-icon
              background-image: url(../../images/landing/manager.png)

            &.for-pupil-icon
              background-image: url(../../images/landing/girl.png)

          .column-header-title
            font-size: 30px
            line-height: 200%
            text-transform: uppercase
            color: #303030
            margin-left: 16px

        .column-item-list
          display: flex
          flex-direction: column

          .column-item
            display: flex
            align-items: center
            height: 90px

            .big-dot
              border-radius: 50%
              background-color: #2D9CDB
              width: 24px
              height: 24px
              flex-shrink: 0

            .column-item-text
              font-size: 16px
              line-height: 25px
              color: #3A3A3A
              margin-left: 23px
              width: 310px

  .reviews-block
    background: #FFFFFF
    display: flex
    flex-direction: column
    align-items: center
    padding-top: 100px
    padding-bottom: 100px

    .block-inner
      display: flex
      flex-direction: column

      .block-title
        font-size: 36px
        text-transform: uppercase
        color: #303030

      .review-list
        display: flex
        flex-wrap: wrap
        margin-top: 64px

        .review-item
          display: flex

          & + .review-item
            margin-left: 88px

          .review-author-avatar
            width: 103px
            height: 103px
            border-radius: 50%
            background-color: #8f8d8d
            flex-shrink: 0

          .review-body
            display: flex
            flex-direction: column
            font-size: 17px
            margin-left: 17px

            .review-author-name
              font-weight: bold
              font-size: 21px
              color: #525252

            .review-author-title
              color: #949494
              margin-top: 8px

            .review-author-words
              color: #525252
              width: 415px
              margin-top: 16px

@media screen and (min-width: 1000px)
  .landing-page
    .footer-block
      background-color: #222C31

      .block-inner
        .block-body
          .column
            .column-item-title
              color: #828282
              font-weight: normal
