.diploma-pay-modal
  color: $black
  border-radius: 7px
  display: flex
  flex-direction: column
  height: auto

  .modal-header
    display: flex
    justify-content: center
    align-items: center
    background: #F7F7F7
    position: relative
    height: 40px
    flex-shrink: 0

    .header-right
      position: absolute
      right: 0
      display: flex
      align-items: center
      float: right

  .modal-body
    padding: 15px 20px 10px

  .price-text
    font-weight: bold
    font-size: 17px
    color: #3E608A
    text-align: center

  .buying-tooltip-text
    color: #3C3C3C
    font-size: 10px
    text-align: center

  .buy-button-list
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    margin-top: 1rem

    .kaspi-button
      background-color: #E24D34
      background-image: url("../../images/ic-kaspi.png")
      background-size: 32px
      background-position-x: 25px
      background-position-y: center
      background-repeat: no-repeat
      padding-left: 50px
      padding-right: 30px
      border-radius: 10px
      text-transform: none
      margin-bottom: 8px
      width: 230px

    .qr-button
      background-color: #E24D34
      background-image: url("../../images/ic-qr-code.png")
      background-size: 25px
      background-position-x: 30px
      background-position-y: center
      background-repeat: no-repeat
      padding-left: 60px
      padding-right: 30px
      border-radius: 10px
      text-transform: none
      margin-bottom: 8px
      width: 230px

    .whatsapp-share-button
      background-image: url("../../images/ic-whatsapp.png")
      background-color: #54CC61
      background-size: 25px
      background-position-x: 30px
      background-position-y: center
      background-repeat: no-repeat
      padding-left: 55px
      padding-right: 30px
      border-radius: 10px
      text-transform: none
      white-space: nowrap
      width: 230px

    .there-is-question-button
      align-self: flex-end
      background-color: white
      background-image: url("../../images/ic-headphone.png")
      background-size: 20px
      background-position-x: 10px
      background-position-y: center
      background-repeat: no-repeat
      padding: 2px 15px 2px 40px
      margin-top: 3rem
      margin-right: -24px
      border-radius: 7px
      border: 1px solid #3E608A
      font-size: 12px
      text-transform: none
      a
        color: #3E608A

@media screen and (max-width: 600px), screen and (max-height: 600px), (hover: none) and (pointer: coarse)
.kaspi-button, .whatsapp-share-button
  display: none !important
