.self-works-dashboard
  display: flex
  flex-direction: column
  align-items: center

  .tab
    display: flex
    flex-direction: column
    align-items: center
    width: 100%
    padding-top: 0

  .self-works-tab
    .accordion-header
      display: flex
      align-items: center
      justify-content: space-between
      width: 100%

    .institutions-accordion
      width: 100%

  .personal-study-tab
    .filter-container
      display: flex
      flex-direction: column
      align-items: center

    .my-table
      margin-top: 3rem

  .uk-checkbox
    background-color: #FFFFFF

  .icon
    cursor: pointer
    margin: 1rem


  .ic-cancel
    background-image: url(../../images/ic-cancel.png)
    width: 20px
    height: 20px

  .ic-restore
    background-image: url(../../images/ic-restore.svg)

  .institutions-accordion, .self-works-accordion
    .self-works-table
      border-radius: 0 0 10px 10px

      tr:last-child
        border: none

    li
      padding: 1rem 0
      border-radius: 30px
      border: 1px solid #D3D3D3
      box-sizing: border-box
      background-color: #FFFFFF

    th
      white-space: normal

    .date-text
      font-size: 13px

    .uk-accordion-title
      font-size: 14px
      text-align: center
      padding: 0 2rem

      .title-container
        display: flex
        align-items: center
        justify-content: center

        .pupil-fullname
          width: 230px
        .class-title
          width: 20px
        .uk-label
          margin-left: 8px
          border-radius: 10px
        .uk-label-warning
          width: 120px
        .uk-label-danger
          width: 140px


      .uk-label
        font-size: 12px
        text-transform: none

    .grouping-item-list
      margin: 0 8rem
      display: flex
      flex-wrap: wrap
      justify-content: center

      .grouping-item
        margin: 4px
        width: 210px

    .grouping-actions
      display: flex
      justify-content: center


