.login-form, .register-form, .temp-login-form
  overflow: hidden
  background-color: #f3f3f3

  .language-select
    width: 100px

  .uk-section
    width: 23rem
    padding-top: 2rem
    padding-bottom: 2rem

  .uk-container
    padding-left: 2rem
    padding-right: 2rem

  .quotation
    margin-bottom: 1.5rem
    font-size: 12px

  .uk-input
    border: 1px solid #c6c9cf
    max-width: 20rem
    height: 3rem
    border-radius: 5px
    text-align: center

  .input-password
    background: url(../../images/ic-password.png) no-repeat scroll 10px center
    padding-left: 2rem

  .input-phone-number
    background: url(../../images/ic-phone-call.png) no-repeat scroll 10px center
    border: none
    border-bottom: 1px solid rgba(255, 255, 255, 0.62)
    padding: 0 0 0 32px
    box-sizing: border-box

    .flag-dropdown
      color: black
      border: none
      background: inherit

      .selected-flag
        background: inherit
        border: none

      .country-list
        width: 250px

    .form-control
      width: 223px
      border: none
      background: inherit
      color: white





  .login-btn-block, .register-btn-block, .reset-password-btn-block
    display: flex
    justify-content: space-between
    margin-top: 2rem

    & > *
      flex-grow: 1
      flex-basis: 33%


  .login-btn, .register-btn, .restore-password-btn
    width: 8.5rem
    padding: 1rem 0
    border: none
    border-radius: 5px
    box-shadow: $box-shadow
    background-color: $blue
    text-align: center
    color: $white
    font-size: 10px
    font-weight: 500
    line-height: 1.4
    letter-spacing: 1px
    text-transform: uppercase
    cursor: pointer

    &:hover
      box-shadow: none

    &:focus
      box-shadow: none
      background-image: linear-gradient(250deg, #7689b8, #506aaf)

  .restore-password-btn
    flex-basis: 60% !important

  .register-link
    align-self: center
    text-align: right

  .login-link
    align-self: center
    text-align: left

  .forgot-password-link
    display: block
    margin-top: 2rem
    margin-left: auto
    text-align: right
    font-size: 14px

