.card-list
  display: flex
  flex-direction: column
  margin: 0 8px

  .card-item
    border-radius: 20px
    margin-bottom: 1rem
    border: 1px solid #D3D3D3
    padding: 1rem 2rem
    background-color: #FFFFFF

    .item-title
      font-weight: 400
      font-size: 16px
      text-align: center
      color: #6D6D6D

    .item-property
      display: flex
      margin-top: 1rem
      font-size: 14px

      .property-title
        flex-basis: 50%
        font-weight: 500
