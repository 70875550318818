.admin-dashboard
  display: flex
  width: 100%
  //margin: auto
  max-width: 1350px

  .it-support-button
    width: 36px
    height: 36px
    position: fixed
    right: 25px
    bottom: 50px
    mask-image: url(../../images/landing/support-icon.png)
    background-color: #1e87f0
    cursor: pointer
  .menu
    position: sticky
    top: 0
    display: flex
    flex-direction: column
    background-color: #046ff4
    overflow-y: auto
    overflow-x: hidden
    flex-shrink: 0
    height: 100vh
    font-size: 15px
    letter-spacing: 1px
    font-family: "Montserrat", serif  Roboto, sans-serif  Verdana, sans-serif

    .menu-item
      color: white
      cursor: pointer
      width: 20rem
      text-transform: uppercase
      padding: 10px 4px
      border-bottom: solid 1px gray

      .selected
        background-color: black
        text-decoration: none

      &:hover
        background-color: #0045BC
        text-decoration: none

  .teacher-list
    display: flex
    flex-direction: column

    .teacher-item
      display: flex

.teacher-modal, .user-password-modal, .pupil-modal, .admin-modal
  .uk-modal-footer
    display: flex
    align-items: center
    justify-content: center

    .save-button
      margin-left: 24px

  .select
    width: 100%
    border: none

  .input
    width: 100%

.add-leads-coefficients-modal
  .my-table
    overflow: auto
    td
      padding: 0

.comment-list-button
  mask-image: url("../../images/ic-note.svg")
  width: 23px
  height: 23px
  mask-size: 100%
  cursor: pointer
  background-color: rgba(38, 89, 136, 0.95)
  margin-right: 1rem
  mask-repeat: no-repeat
  flex-shrink: 0

  &:hover
    background-color: rgba(1, 112, 175, 0.8)

.comment-list-exists
  background-color: green

.dropdown-menu
  position: relative
  width: 100%
  display: inline-block

  &:hover
    .dropdown-menu-list
      display: block

  .dropdown-menu-list
    display: none
    background-color: #f9f9f9
    min-width: 160px
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2)

    .dropdown-menu-list-item
      color: black
      padding: 12px 16px
      text-decoration: none
      display: block

.vertical
  height: 100vh
  border-left: 2px solid #727070

.ic-send-message
  mask-image: url("../../images/ic-send-message.svg")
  width: 30px
  height: 30px
  mask-size: 100%
  cursor: pointer
  background-color: grey
  mask-repeat: no-repeat
  flex-shrink: 0
  margin-left: 8px
  align-self: center

  &:hover
    background-color: rgba(1, 112, 175, 1)

  &:disabled
    background-color: lightgrey
    cursor: default

.web-chat-sender
  height: 50px
  margin: 0 auto
  padding-top: 10px

.chat-list
  width: 400px
  height: 100vh
  overflow: auto

.message-list
  margin-left: 10px
  display: flex
  flex-direction: column
  padding: 10px 0
  height: 99vh
  width: 100%
  overflow-y: auto
  margin-bottom: 1rem

  .message-item
    display: table
    position: relative
    background-color: white
    border-radius: 10px
    padding: 5px
    margin: 10px auto 4px 0
    max-width: 100%
    border: 1px solid gray

    .select-message
      display: none

    &:hover
      .select-message
        display: block

    &.is-read
      border: 2px solid green

    &.is-mine
      margin-left: auto
      margin-right: 0
      max-width: 350px

    .message-sender-name
      font-size: 13px
      color: #2D9CDB
      margin-bottom: 8px
      display: block

    .is-message-read
      background: url("../../images/ic-double-tick.svg")
      min-width: 15px
      height: 12px
      align-self: flex-end
      margin-left: 8px

    .message-sent-at
      color: #8f8d8d
      font-size: 12px

    .message-status-sent
      background: url("../../images/ic-double-tick.svg")

    .message-status
      min-width: 15px
      height: 12px
      align-self: flex-end
      margin-left: 8px
      mask-repeat: no-repeat

      &.sent
        mask-image: url("../../images/ic-single-tick.svg")
        background-color: gray

      &.delivered
        mask-image: url("../../images/ic-double-tick-gray.svg")
        background-color: gray

      &.read
        mask-image: url("../../images/ic-double-tick.svg")
        background-color: #013bb2

      &.failed
        mask-image: url("../../images/ic-single-tick.svg")
        background-color: red

  .message-text
    white-space: pre-wrap
    max-width: 350px

  .message-input-box
    width: 800px
    height: 38px
    border: 1px solid black
    border-radius: 5px

  .ic-send-message
    mask-image: url("../../images/ic-send-message.svg")
    width: 30px
    height: 30px
    mask-size: 100%
    cursor: pointer
    background-color: grey
    mask-repeat: no-repeat
    flex-shrink: 0
    margin-left: 8px
    align-self: center

    &:hover
      background-color: rgba(1, 112, 175, 1)

    &:disabled
      background-color: lightgrey
      cursor: default

.chat-item
  color: white
  background-color: lightblue
  width: 350px
  //border-radius: 10px
  padding: 15px
  //border: 2px solid black
  border-bottom: 1px solid gray

  &.from-us
    color: white
    background-color: lightgreen

  &.active
    color: white
    background-color: #84bae1

  &:hover
    background-color: rgb(208, 208, 208)
    color: white
    cursor: pointer

.ic-close
  mask-image: url("../../images/ic-close.svg")
  mask-repeat: no-repeat
  mask-size: contain
  background-color: red
  width: 20px
  height: 20px

.ic-single-tick
  mask-image: url("../../images/ic-single-tick.svg")
  mask-repeat: no-repeat
  mask-size: contain
  background-color: green
  width: 20px
  height: 20px





