.without-bot-messages
  color: $white

.message-date-filter
  display: flex
  border: 1px solid lightgray
  border-radius: 10px
  padding: 5px

.realtime-dashboard
  display: flex
  flex-direction: column
  align-items: center
  position: relative
  padding: 0 10px

  .subscription-info-container
    width: 900px
    font-size: 13px
    margin-bottom: 1rem

    .buy-subscription-button
      padding: 0 30px
      text-transform: none

  .teacher-code
    display: flex
    flex-basis: 50%
    align-items: center
    text-align: center

  .action-time-filter
    display: flex
    flex-direction: column
    align-items: center
    width: 960px

    .filter-by-location
      display: flex
      margin-bottom: 8px

      .filter-select-institution, .filter-select-class
        width: 200px
        border: 1px solid #D3D3D3
        box-sizing: border-box
        border-radius: 30px
        background-color: #FFFFFF
        background-image: url(../../images/ic-select.png)
        background-position-y: 19px
        font-size: 15px

      .Dropdown-control
        border-radius: 30px !important
        border: 1px solid #D3D3D3 !important
        padding: 11px 27px !important
        color: #727272 !important

      .separator
        width: 8px

      .group-button-list
        display: flex
        flex-wrap: wrap
        justify-content: center
        align-items: center

        .group-item
          display: flex
          align-items: center
          position: relative

          .comment-list-button
            position: absolute
            right: -10px
            top: 0
            bottom: 0
            margin: auto

        .group-button
          margin: 4px 5px
          padding: 2px 12px
          width: 230px

    .filter-hours
      display: flex
      flex-wrap: wrap
      justify-content: center

      .time-filter-item
        cursor: pointer
        font-size: 15px
        line-height: 24px
        color: #727272
        padding: 0 8px

        &.underline, &:hover
          font-weight: 500
          color: #2C3B41
          border-bottom: 1px solid #2C3B41

    .filter-date-range
      margin-top: 4px

      .date-picker
        border-radius: 5px
        border: 1px solid $border-grey-3
        font-size: 12px
        color: rgb(102, 102, 102)

      .search-button
        font-size: 11px
        padding: 2px

  .pupil-action-list
    display: flex
    flex-direction: column
    margin-top: 36px
    width: 100%
    box-sizing: border-box

    .pupil-action-item
      display: flex
      padding: 20px 58px 20px 35px
      align-items: center
      background-color: #FFFFFF
      border: 1px solid #D3D3D3
      box-sizing: border-box
      border-radius: 30px
      margin-bottom: 13px

      &.non-active-payment
        border: 1px solid red

      &.importance-yellow
        border: 1px solid yellow

      &.success
        border: 2px solid green

      .pupil-info
        display: flex
        width: 550px
        flex-shrink: 0
        align-items: center
        margin-right: 20px

        .pupil-details-button
          mask-image: url("../../images/ic-view-details.svg")
          width: 63px
          height: 31.5px
          mask-size: 100%
          cursor: pointer
          background-color: rgba(38, 89, 136, 0.95)
          flex-shrink: 0

          &:hover
            background-color: rgba(1, 112, 175, 0.8)

        .pupil-details-button-return-requested
          background-color: red

        .comment-list-exists
          background-color: green

        .freeze-status
          mask-image: url("../../images/ic-freeze-status.svg")
          width: 30px
          height: 30px
          mask-size: 100%
          flex-shrink: 0
          background-color: #2893ea

        .from-telegram
          background-image: url("../../images/ic-from-telegram.svg")
          width: 30px
          height: 30px
          mask-size: 100%
          flex-shrink: 0

        .from-whatsapp
          background-image: url("../../images/ic-from-whatsapp.svg")
          width: 30px
          height: 30px
          mask-size: 100%
          flex-shrink: 0

        .from-mobile
          mask-image: url("../../images/ic-from-mobile.svg")
          width: 30px
          height: 30px
          mask-size: 100%
          flex-shrink: 0
          background-color: black

        .from-website
          mask-image: url("../../images/ic-from-website.svg")
          width: 30px
          height: 30px
          mask-size: 100%
          flex-shrink: 0
          background-color: black

        .from-relative
          mask-image: url("../../images/ic-from-relative.svg")
          width: 30px
          height: 30px
          mask-size: 100%
          flex-shrink: 0
          background-color: black

        .flag-kazakhstan
          background-image: url("../../images/ic-flag-kazakhstan.svg")
          width: 30px
          height: 30px
          mask-size: 100%
          flex-shrink: 0


        .flag-russia
          background-image: url("../../images/ic-flag-russia.svg")
          width: 30px
          height: 30px
          mask-size: 100%
          flex-shrink: 0

        .flag-uzbekistan
          background-image: url("../../images/ic-flag-uzbekistan.svg")
          width: 30px
          height: 30px
          mask-size: 100%
          flex-shrink: 0

        .unt-tester
          background-image: url("../../images/ic-unt-tester.svg")
          width: 90px
          height: 40px
          mask-repeat: no-repeat

        .from-mentor-task
          mask-image: url("../../images/ic-from-mentor-task.svg")
          width: 34px
          height: 34px
          mask-size: 100%
          flex-shrink: 0
          background-color: #1E307C

        .consecutive-error-task
          mask-image: url("../../images/ic-consecutive-error-tasks.svg")
          width: 50px
          height: 50px
          mask-size: 100%
          flex-shrink: 0
          background-color: red

        .parent-problem-status
          background-image: url("../../images/ic-parent-problem.svg")
          width: 25px
          height: 25px
          mask-size: 100%
          flex-shrink: 0

        .pupil-problem-status
          background-image: url("../../images/ic-pupil-problem.svg")
          width: 25px
          height: 25px
          mask-size: 100%
          flex-shrink: 0

        .b2b-pupil
          color: blue
          font-weight: bold

        .pupil-presence-status
          width: 1rem
          height: 1rem
          border-radius: 50%
          background: lightgrey
          flex-shrink: 0

          &.online
            background: #00C73A

          &.away
            background: #FF8A3C

          &.is-new-paid
            border: 2px solid red

        .pupil-fullname
          font-weight: 500
          font-size: 16px
          text-transform: uppercase
          color: #4A4A4A
          margin-left: 18px
          margin-right: auto

        .audio-call-button, .send-message-button
          padding: 4px 8px
          font-size: 12px
          line-height: 12px
          margin-left: 4px
          margin-top: 8px

        .send-message-button
          margin-left: auto

        .communication-block, .chat-block
          position: relative
          display: flex
          align-items: center

          .popup-opener-button
            padding: 4px 8px
            font-size: 12px
            line-height: 12px
            margin-left: 10px

          .unread-messages-quantity-label
            border-radius: 10px

          .communication-popup, .chat-popup
            position: absolute
            background-color: $border-grey-2
            width: 150px
            top: 23px
            left: 0
            box-sizing: border-box
            z-index: 100
            padding: 0.5rem
            border-radius: 5px

          .no-cursor
            cursor: auto

          .cursor
            cursor: move

          .chat-popup
            width: auto
            background: none
            padding: 0
            top: initial
            bottom: -20px
            left: 40px

            .chat
              position: initial

              .chat-header
                display: flex
                align-items: center
                color: white
                justify-content: center
                padding-top: 12px

                .close-button
                  padding-top: 0

              .message-input-box
                width: 100%

      .action-dot-list
        display: flex
        flex-wrap: wrap
        align-items: center

        .action-dot-item
          display: flex
          cursor: pointer
          padding: 1px

        .last-action-at
          font-size: 12px
          color: grey

  .user-action-popup
    position: fixed
    background-color: #ffffff
    width: 30rem
    box-sizing: border-box
    z-index: 100
    border: 1px solid $border-grey
    padding: 0.5rem

  .comment-list-button
    mask-image: url("../../images/ic-note.svg")
    width: 23px
    height: 23px
    mask-size: 100%
    cursor: pointer
    background-color: rgba(38, 89, 136, 0.95)
    margin-right: 1rem
    mask-repeat: no-repeat
    flex-shrink: 0

    &:hover
      background-color: rgba(1, 112, 175, 0.8)

  .chat-message-list-button
    mask-image: url("../../images/ic-chat-message.svg")
    width: 25px
    height: 23px
    mask-size: 100%
    cursor: pointer
    background-color: rgba(38, 89, 136, 0.95)
    mask-repeat: no-repeat
    flex-shrink: 0

    &:hover
      background-color: rgba(1, 112, 175, 0.8)

    &.parent-chat
      background-color: green

.pupil-presence-status
  width: 1rem
  height: 1rem
  border-radius: 50%
  background: lightgrey
  flex-shrink: 0

  &.online
    background: #00C73A

  &.away
    background: #FF8A3C

@media screen and (max-width: 600px)
  .realtime-dashboard
    .subscription-info-container
      width: 80%

    .filter-select-institution, .filter-select-class
      width: 150px !important

    .action-time-filter
      width: 100%

      .group-button
        width: auto !important

      .filter-hours
        margin-top: 2rem

        .time-filter-item
          margin-bottom: 8px

    .pupil-action-item
      padding: 15px 22px 36px 22px !important
      flex-direction: column

      .action-dot-list
        margin-top: 20px

    .pupil-info
      width: auto !important

      .communication-block
        display: none !important

      .chat-block
        .chat-popup
          position: fixed !important
          top: 0 !important
          left: 50% !important
          transform: translate(-50%, 0) !important


